import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_DEVICE_PACKAGE,
  DELETE_DEVICE_PACKAGE,
  GET_DEVICE_PACKAGES,
  UPDATE_DEVICE_PACKAGE,
} from "./actionTypes"
import {
  getDevicePackagesSuccess,
  getDevicePackagesFail,
  addDevicePackageFail,
  addDevicePackageSuccess,
  updateDevicePackageSuccess,
  updateDevicePackageFail,
  deleteDevicePackageSuccess,
  deleteDevicePackageFail,
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getDevicePackages,
  addNewDevicePackage,
  updateDevicePackage,
  deleteDevicePackage
} from "../../helpers/backend_api/devicePackages"

function* fetchDevicePackages() {
  yield put(getDevicePackagesFail({}))

  try {
    const response = yield call(getDevicePackages)
    if (response.code === 200) {
      yield put(getDevicePackagesSuccess(response.message))
    }
    else {
      yield put(getDevicePackagesFail(error))
    }
  } catch (error) {
    yield put(getDevicePackagesFail(error))
  }
}

function* onAddNewDevicePackage({ payload: devicePackage }) {
  try {
    const response = yield call(addNewDevicePackage, devicePackage);
    if (response.code === 201) {
      yield call(fetchDevicePackages)
      yield put(closeModalAdd())
    } else {
      yield put(addDevicePackageFail(response))
    }
  } catch (error) {
    yield put(addDevicePackageFail(error))
  }
}

function* onUpdateDevicePackage({ payload: devicePackage }) {
  try {
    const response = yield call(updateDevicePackage, devicePackage);
    if (response.code === 200) {
      yield call(fetchDevicePackages)
      yield put(closeModalEdit())
    } else {
      yield put(updateDevicePackageFail(response))
    }
  } catch (error) {
    yield put(updateDevicePackageFail(error))
  }
}

function* onDeleteDevicePackage({ payload: devicePackage }) {
  try {
    const response = yield call(deleteDevicePackage, devicePackage);
    if (response.code === 200) {
      yield call(fetchDevicePackages)
    } else {
      yield put(deleteDevicePackageFail(response))
    }
  } catch (error) {
    yield put(deleteDevicePackageFail(error))
  }
}

function* devicePackageSaga() {
  yield takeEvery(GET_DEVICE_PACKAGES, fetchDevicePackages)
  yield takeEvery(ADD_NEW_DEVICE_PACKAGE, onAddNewDevicePackage)
  yield takeEvery(UPDATE_DEVICE_PACKAGE, onUpdateDevicePackage)
  yield takeEvery(DELETE_DEVICE_PACKAGE, onDeleteDevicePackage)
}

export default devicePackageSaga
