import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Input as InputAnt, Button as ButtonAnt, InputNumber } from 'antd';

import { connect } from "react-redux"
import { get } from 'lodash';
import { servicePricing } from "../../common/data/servicePricing";

const ServicePricing = props => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [trainingType, setTrainingType] = useState([]);
    const [mcc, setMCC] = useState([]);
    const [initFee, setInitFee] = useState([]);

    const [fields, setFields] = useState([]);

    useEffect(() => {
        let training = [];
        let mcc = [];
        let initFee = [];
        servicePricing.map(ser => {
            if (ser.type === 'training') {
                training.push(ser);
            } else if (ser.type === 'mcc') {
                mcc.push(ser);
            } else if (ser.type === 'init fee') {
                initFee.push(ser);
            }
        })
        setTrainingType(training);
        setMCC(mcc);
        setInitFee(initFee);
    }, [])

    const handleChange = name => value => {

        let myFields = fields;
        if (myFields.length === 0) {
            myFields.push({
                id: name,
                pricing: value
            });
        } else {
            let elementIndex = myFields.findIndex((obj => obj.id === name));
            if (elementIndex === -1) {
                myFields.push({
                    id: name,
                    pricing: value
                });
            } else {
                myFields[elementIndex].pricing = value;
            }
        }


        setFields(myFields);
    }

    const onSubmit = data => {

    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Định giá dịch vụ</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">Định giá dịch vụ</h6>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Danh mục</li>
                                    <li className="breadcrumb-item active">Định giá dịch vụ</li>
                                </ol>
                            </Col>
                        </Row>
                    </div>
                    {/* Table here */}
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle className="h4">Cập nhật định giá dịch vụ</CardTitle>
                                            <p className="card-title-desc">
                                                Quy định giá của dịch vụ khi cung cấp dịch vụ PX1 cho khách hàng
                                            </p>
                                        </Col>
                                    </Row>
                                    <Container>
                                        <Row className='mb-3 d-flex justify-content-center'>

                                            <Col md={6}>
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    {trainingType.map(train => (
                                                        <Row key={train.id} className='mb-3'>
                                                            <Col md={4}>
                                                                <Label>{train.name}</Label>
                                                            </Col>
                                                            <Col md={8}>
                                                                <FormGroup>
                                                                    <InputNumber
                                                                        onChange={handleChange(train.id)}
                                                                        defaultValue={train.pricing}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        min={0}
                                                                        formatter={(value) => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\đ\s?|(,*)/g, '')}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    {mcc.map(device => (
                                                        <Row key={device.id} className='mb-3'>
                                                            <Col md={4}>
                                                                <Label>{device.name}</Label>
                                                            </Col>
                                                            <Col md={8}>
                                                                <FormGroup>
                                                                    <InputNumber
                                                                        onChange={handleChange(device.id)}
                                                                        defaultValue={device.pricing}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        min={0}
                                                                        formatter={(value) => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\đ\s?|(,*)/g, '')}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    ))}



                                                    <Row className='mb-3'>
                                                        <Col md={12}>
                                                            <label>Phí khởi tạo</label>
                                                            {initFee.map(init => (
                                                                <Row key={init.id} className='m-3'>
                                                                    <Col md={4}>
                                                                        <Label dangerouslySetInnerHTML={{ __html: init.name }} />
                                                                    </Col>
                                                                    <Col md={8}>
                                                                        <FormGroup>
                                                                            <InputNumber
                                                                                onChange={handleChange(init.id)}
                                                                                defaultValue={init.pricing}
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                                min={0}
                                                                                formatter={(value) => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                parser={(value) => value.replace(/\đ\s?|(,*)/g, '')}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </Col>

                                                    </Row>
                                                    <ButtonAnt htmlType="submit" className='text-white float-end' style={{ backgroundColor: "#6ECCAF" }}>
                                                        Cập nhật
                                                    </ButtonAnt>
                                                </form>
                                            </Col>



                                        </Row>

                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )
}

ServicePricing.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = ({ serviceType }) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ServicePricing))
