import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, InputNumber, Radio, DatePicker } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewDeviceService,
    getDevicePackages,
    getCustomers
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import _ from 'lodash';
import { status, billing_cycle_type, initParam } from "../../common/data/deviceService";
import moment from 'moment';


const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewDeviceService, onGetDevicePackages, devicePackages, onGetCustomers, customers } = props;
    const [fields, setFields] = useState(initParam);
    const [isShowPayment, setIsShowPayment] = useState("No");
    const [isShowTerminateDate, setIsShowTerminateDate] = useState(false);
    const [isShowActiveDate, setIsShowActiveDate] = useState(false);

    const [myError, setMyErrors] = useState({});
    const [devicePackageList, setDevicePackageList] = useState([]);
    const [customersList, setCustomersList] = useState([]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        onGetDevicePackages();
        onGetCustomers();
    }, [onGetDevicePackages, onGetCustomers])

    useEffect(() => {
        let data = [];
        devicePackages.map(type => {
            data.push({
                label: type.name,
                value: type.id
            });
        })
        setDevicePackageList(data);

        let customerData = [];
        customers.map(customer => {
            customerData.push({
                label: customer.company_name,
                value: customer.id
            });
        });
        setCustomersList(customerData);
    }, [devicePackages, customers])

    useEffect(() => {
        setIsShowPayment("No")
    }, [modalAdd])

    const handleCancel = () => {
        onCloseModalAdd();
    };

    const onSubmit = data => {
        if (validateInput()) {
            setMyErrors({});
            let message = {
                'action': 'create',
                'message': fields,
                "type_message": "map"
            }
            onAddNewDeviceService(message);
        }

    };

    const validateInput = () => {
        let isValid = true;
        let errs = myError;
        if (!fields.customer) {
            errs.customer = {
                type: 'required',
                message: 'Khách hàng không được phép để trống'
            }
            isValid = false;
        }
        if (fields.one_time_payment === "No") {
            if (!fields.billing_cycle) {
                errs.billing_cycle = {
                    type: 'required',
                    message: 'Kỳ thanh toán không được phép để trống'
                }
                isValid = false;
            }
        }
        if (!fields.service_pack) {
            errs.service_pack = {
                type: 'required',
                message: 'Gói dịch vụ không được phép để trống'
            }
            isValid = false;
        }
        if (!fields.quantity) {
            errs.quantity = {
                type: 'required',
                message: 'Số lượng không được phép để trống'
            }
            isValid = false;
        }
        if (!fields.start_date) {
            errs.start_date = {
                type: 'required',
                message: 'Ngày bắt đầu sử dụng không được phép để trống'
            }
            isValid = false;
        }

        if (!fields.status) {
            errs.status = {
                type: 'required',
                message: 'Trạng thái không được phép để trống'
            }
            isValid = false;
        } else {
            if (fields.status === 'active') {
                if (!fields.billing_date) {
                    errs.billing_date = {
                        type: 'required',
                        message: 'Ngày bắt đầu tính cước không được phép để trống'
                    }
                    isValid = false;
                }
            }

            if (fields.status === 'terminate') {
                if (!fields.terminate_date) {
                    errs.terminate_date = {
                        type: 'required',
                        message: 'Ngày kết thúc không được phép để trống'
                    }
                    isValid = false;
                }
            }

        }
        setMyErrors(errs);
        return isValid;
    }

    const onChange = name => (value) => {
        let myFields = fields;
        if (name === "rental_equipment" || name === "one_time_payment") {
            value = value.target.value;
            if (name === "one_time_payment") {
                setIsShowPayment(value)
                if (value === "Yes") {
                    myFields["billing_cycle"] = 0;
                    myFields["billing_cycle_type"] = '';
                } else {
                    myFields["billing_cycle"] = 1;
                    myFields["billing_cycle_type"] = 'month';
                }
            }
        }

        // Xử lý form và dữ liệu khi thay đổi "Trạng thái"
        if (name === "status") {
            setIsShowTerminateDate(value === "terminate" ? true : false);
            setIsShowActiveDate(value === "active" ? true : false);
            if (value === "suspend" || value === "experiment") {
                delete myFields.billing_date;
                delete myFields.terminate_date;
            } else if (value === "active") {
                delete myFields.terminate_date;
            } else if (value === "terminate") {
                delete myFields.billing_date;
            }
        }
        // Kết thúc xử lý trạng thái

        myFields[name] = value;
        setFields(myFields);
    }

    const onChangeBilling = name => value => {
        let myFields = fields;
        myFields[name] = value;
        setFields(myFields);
    }

    const onChangeDatePicker = name => (date, dateString) => {
        let myFields = fields;
        if (dateString !== '') {
            let dateFormat = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
            myFields[name] = dateFormat;
        } else {
            delete myFields[name]
        }

        setFields(myFields);
    }

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <Label>Khách hàng<span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <SelectAnt
                                            showSearch
                                            placeholder="Chọn khách hàng"
                                            optionFilterProp="children"
                                            onChange={onChange('customer')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                            options={customersList}
                                        />
                                        {myError.customer && myError.customer.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.customer.message}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <Label>Gói dịch vụ <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <SelectAnt
                                            showSearch
                                            placeholder="Chọn gói dịch vụ"
                                            optionFilterProp="children"
                                            onChange={onChange('service_pack')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                            options={devicePackageList}
                                        />
                                        {myError.service_pack && myError.service_pack.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.service_pack.message}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Số lượng <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <InputNumber
                                            style={{
                                                width: '100%',
                                            }}
                                            min={0}
                                            onChange={onChange('quantity')} defaultValue={1}
                                        />

                                        {myError.quantity && myError.quantity.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.quantity.message}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Thanh toán 1 lần</Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <Radio.Group onChange={onChange('one_time_payment')} defaultValue="No">
                                            <Radio.Button value="Yes">Có</Radio.Button>
                                            <Radio.Button value="No">Không</Radio.Button>
                                        </Radio.Group>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isShowPayment === "No" && (
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <label>Kỳ thanh toán <span className='text-danger'>*</span></label>
                                    </Col>
                                    <Col md={8}>
                                        <InputNumber
                                            style={{
                                                width: '100%',
                                            }}
                                            min={1}
                                            addonAfter={(
                                                <SelectAnt
                                                    onChange={onChangeBilling('billing_cycle_type')}
                                                    defaultValue="month">
                                                    {billing_cycle_type.map(type => (
                                                        <SelectAnt.Option key={type.value} value={type.value}>{type.label}</SelectAnt.Option>
                                                    ))}
                                                </SelectAnt>
                                            )}
                                            defaultValue={1}
                                            onChange={onChangeBilling('billing_cycle')}
                                        />
                                        {myError.billing_cycle && myError.billing_cycle.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.billing_cycle.message}</span>
                                        )}
                                    </Col>
                                </Row>
                            )}
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <Radio.Group onChange={onChange('rental_equipment')} defaultValue="No">
                                            <Radio.Button value="Yes">Cho thuê thiết bị</Radio.Button>
                                            <Radio.Button value="No">Bán</Radio.Button>
                                        </Radio.Group>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Trạng thái <span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <SelectAnt
                                        placeholder="Chọn trạng thái"
                                        onChange={onChange('status')}
                                        options={status}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                    {myError.status && myError.status.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.status.message}</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Ngày bắt đầu sử dụng  <span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <DatePicker
                                        style={{
                                            width: '100%',
                                        }}
                                        defaultValue={dayjs()}
                                        format={'DD/MM/YYYY'}
                                        onChange={onChangeDatePicker('start_date')}
                                    />
                                    {myError.start_date && myError.start_date.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.start_date.message}</span>
                                    )}
                                </Col>
                            </Row>
                            {fields.status === 'active' && (
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <label>Ngày bắt đầu tính cước <span className='text-danger'>*</span></label>
                                    </Col>
                                    <Col md={8}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                            format={'DD/MM/YYYY'}
                                            onChange={onChangeDatePicker('billing_date')}
                                        />
                                        {myError.billing_date && myError.billing_date.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.billing_date.message}</span>
                                        )}
                                    </Col>
                                </Row>
                            )}

                            {fields.status === 'terminate' && (
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <label>Ngày kết thúc <span className='text-danger'>*</span></label>
                                    </Col>
                                    <Col md={8}>
                                        <DatePicker
                                            style={{
                                                width: '100%',
                                            }}
                                            defaultValue={dayjs()}
                                            format={'DD/MM/YYYY'}
                                            onChange={onChangeDatePicker('terminate_date')}
                                        />
                                        {myError.terminate_date && myError.terminate_date.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.terminate_date.message}</span>
                                        )}
                                    </Col>
                                </Row>

                            )}


                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thêm mới
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func,
    onAddNewDeviceService: PropTypes.func,
    onGetCustomers: PropTypes.func,
    customers: PropTypes.array,
    onGetDevicePackages: PropTypes.func,
    devicePackages: PropTypes.array
}

const mapStateToProps = ({ customer, devicePackage, helper }) => ({
    modalAdd: helper.modalAdd,
    devicePackages: devicePackage.devicePackages,
    customers: customer.customers
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewDeviceService: (message) => dispatch(addNewDeviceService(message)),
    onGetDevicePackages: () => dispatch(getDevicePackages()),
    onGetCustomers: () => dispatch(getCustomers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);