import {
  GET_SYSTEM_LOGS,
  GET_SYSTEM_LOGS_FAIL,
  GET_SYSTEM_LOGS_SUCCESS,
} from "./actionTypes"

export const getSystemLogs = (dateRange) => ({
  type: GET_SYSTEM_LOGS,
  payload: dateRange
})

export const getSystemLogsSuccess = systemLogs => ({
  type: GET_SYSTEM_LOGS_SUCCESS,
  payload: systemLogs,
})

export const getSystemLogsFail = error => ({
  type: GET_SYSTEM_LOGS_FAIL,
  payload: error,
})