import { useEffect, useState } from "react"
import _ from "lodash"
import { Link } from 'react-router-dom';

const PermissionLink = ({
    myRole,
    permission,
    to,
    pathname,
    children
}) => {
    const [hasAccess, setHasAccess] = useState(false)

    useEffect(() => {
        if (!_.isEmpty(myRole)) {
            const hasPerm = myRole.filter(perm => perm.label === permission.label && perm.action === permission.action)
            // permission check
            if (hasPerm) {
                setHasAccess(true)
            }
        }
    }, [myRole, permission])

    if (hasAccess) {
        return (
            <>
                {children}
            </>
        )
    }

    return null
}

export default PermissionLink
