import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

// Custom Scrollbar

// import images

//i18n
import { withTranslation } from "react-i18next"

import { Button as ButtonAnt, Input as InputAnt } from "antd"

import Permission from "permissions/Permission"
import { connect } from "react-redux"
import { CHANGE_OPEN_MODAL_ADD } from "store/helper/actionTypes"
import CustomersContactDetail from "./Detail"
import { getContacts, getCustomers } from "../../../store/actions"
import Add from "./Add"
import ContactTable from "./ContactTable"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";

const CustomersContact = props => {
  const { onGetContacts, onGetCustomers, onOpenModalAdd, contactDetail, messageError, myRole } = props
  const [keyword, setKeyword] = useState('');


  useEffect(() => {
    onGetContacts(), onGetCustomers()
  }, [onGetContacts, onGetCustomers])

  useEffect(() => {
    if (!_.isEmpty(messageError)) {
      toast.error(messageError.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [messageError])

  const handleAdd = () => {
    let modal = {
      isOpenModal: true,
      title: "Thêm thông tin liên hệ",
    }
    onOpenModalAdd(modal)
  }

  const onSearch = (e) => {
    setKeyword(e.target.value);
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="page-content">
        <MetaTags>
          <title>Customers</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={12}>
                <h6 className="page-title">Thông tin liên hệ</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">Quản lý khách hàng</li>
                  <li className="breadcrumb-item active">Thông tin liên hệ</li>
                </ol>
              </Col>
            </Row>
          </div>
          {/* Table here */}
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  {/* {contactDetail.isOpenDetail === false && ( */}
                  <>
                    {" "}
                    <Row>
                      <Col md={12}>
                        <CardTitle className="h4">
                          Danh sách thông tin liên hệ
                        </CardTitle>
                        <p className="card-title-desc">
                          Quản lý danh sách thông tin liên hệ của khách hàng Qi
                          có sử dụng giải pháp PX1
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <div className="mb-2">
                          <Permission myRole={myRole} permission={{ label: 'customer contact', action: "create" }}>
                            <ButtonAnt
                              type="primary"
                              className="me-1"
                              onClick={() => handleAdd()}
                            >
                              + Thêm mới
                            </ButtonAnt>
                            <Add></Add>
                          </Permission>

                          {/* <ButtonAnt className="me-1">
                            <i className="fas fa-filter text-primary"></i>
                          </ButtonAnt> */}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="float-end mb-2">
                          <InputAnt
                            onChange={onSearch}
                            placeholder="search..."
                            style={{
                              width: 200,
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <ContactTable keyword={keyword}></ContactTable>
                  </>
                  {/* )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CustomersContact.propTypes = {
  t: PropTypes.any,
  contacts: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onOpenModalAdd: PropTypes.func,
  messageError: PropTypes.any,
  myRole: PropTypes.array
}

const mapStateToProps = ({ customer, Login }) => ({
  customers: customer.customers,
  contacts: customer.contacts,
  contactDetail: customer.contactDetail,
  messageError: customer.error,
  myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
  onGetContacts: () => dispatch(getContacts()),
  onGetCustomers: () => dispatch(getCustomers()),
  onOpenModalAdd: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_ADD, payload: modal }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CustomersContact))
