import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_SERVICE,
  DELETE_SERVICE,
  GET_SERVICES,
  UPDATE_SERVICE,
} from "./actionTypes"
import {
  getServicesSuccess,
  getServicesFail,
  addServiceFail,
  addServiceSuccess,
  updateServiceSuccess,
  updateServiceFail,
  deleteServiceSuccess,
  deleteServiceFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getServices,
  addNewService
} from "../../helpers/backend_api/services"

function* fetchServices() {
  try {
    const response = yield call(getServices)
    if (response.code === 200) {
      yield put(getServicesSuccess(response.message))
    }
    else {
      yield put(getServicesFail(error))
    }
  } catch (error) {
    yield put(getServicesFail(error))
  }
}

function* onAddNewService({ payload: service }) {
  try {
    const response = yield call(addNewService, service)
    yield put(addServiceSuccess(response))
  } catch (error) {
    yield put(addServiceFail(error))
  }
}

function* onUpdateEvent({ payload: event }) {
  try {
    // const response = yield call(updateEvent, event)
    // yield put(updateEventSuccess(response))
  } catch (error) {
    yield put(updateEventFail(error))
  }
}

function* onDeleteEvent({ payload: event }) {
  try {
    // const response = yield call(deleteEvent, event)
    // yield put(deleteEventSuccess(response))
  } catch (error) {
    yield put(deleteEventFail(error))
  }
}

function* serviceSaga() {
  yield takeEvery(GET_SERVICES, fetchServices)
  yield takeEvery(ADD_NEW_SERVICE, onAddNewService)
  // yield takeEvery(UPDATE_EVENT, onUpdateEvent)
  // yield takeEvery(DELETE_EVENT, onDeleteEvent)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default serviceSaga
