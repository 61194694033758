import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Table, Modal, DatePicker } from 'antd'
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CardHeader
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react";

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";

//i18n
import { withTranslation } from "react-i18next"
import SystemLogTable from './SystemLogTable';
import { Input as InputAnt, Button as ButtonAnt } from 'antd';


import {
    getSystemLogs,
} from "../../store/actions"
import { connect } from "react-redux"
import Permission from 'permissions/Permission';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";

const SystemLogs = props => {

    const { RangePicker } = DatePicker;

    const [menu, setMenu] = useState(false)
    const [filter, setFilter] = useState({ page: 1, per_page: 10 });
    const { onGetSystemLogs, onOpenModalAdd, messageError } = props
    const toggle = () => {
        setMenu(!menu)
    }

    useEffect(() => {
        onGetSystemLogs(filter);
    }, [onGetSystemLogs])

    const onchangeDatePicker = (date, dateRange) => {
        // onGetSystemLogs(dateRange)
        let filterTime = { ...filter };
        if (!_.isEmpty(dateRange) && dateRange[0] && dateRange[1]) {
            filterTime.start_date = dateRange[0];
            filterTime.end_date = dateRange[1];
        } else {
            delete filterTime.start_date;
            delete filterTime.end_date;
        }
        onGetSystemLogs(filterTime);
        setFilter(filterTime);
    }

    const onSearch = (e) => {
        let filterText = { ...filter };
        filterText.key_word = e.target.value
        setFilter(filterText);
        onGetSystemLogs(filterText);
    }

    const changePage = (page) => {
        let filterText = { ...filter };
        filterText.page = page
        setFilter(filterText);
        onGetSystemLogs(filterText);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Nhật ký hệ thống</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">Nhật ký hệ thống</h6>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Menu</li>
                                    <li className="breadcrumb-item active">Nhật ký hệ thống</li>
                                </ol>
                            </Col>
                        </Row>
                    </div>
                    {/* Table here */}
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle className="h4">Danh sách thao tác người dùng</CardTitle>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={8}>
                                            <div className='mb-2'>
                                                <RangePicker onChange={onchangeDatePicker}></RangePicker>
                                                {/* <ButtonAnt className='me-1'><i className='fas fa-filter text-primary'></i></ButtonAnt> */}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className='float-end mb-2'>
                                                <InputAnt placeholder="search..." onChange={onSearch} style={{
                                                    width: 200,
                                                }} />
                                            </div>

                                        </Col>
                                    </Row>
                                    <SystemLogTable changePage={changePage}></SystemLogTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )
}

SystemLogs.propTypes = {
    t: PropTypes.any,
    systemLogs: PropTypes.array,
    onGetSystemLogs: PropTypes.func,
}

const mapStateToProps = ({ systemLog }) => ({
    systemLogs: systemLog.systemLogs.message,

})

const mapDispatchToProps = dispatch => ({
    onGetSystemLogs: (filter) => dispatch(getSystemLogs(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SystemLogs))
