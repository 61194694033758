import {
  GET_DEVICE_PACKAGES,
  GET_DEVICE_PACKAGES_FAIL,
  GET_DEVICE_PACKAGES_SUCCESS,
  ADD_NEW_DEVICE_PACKAGE,
  ADD_DEVICE_PACKAGE_SUCCESS,
  ADD_DEVICE_PACKAGE_FAIL,
  UPDATE_DEVICE_PACKAGE,
  UPDATE_DEVICE_PACKAGE_SUCCESS,
  UPDATE_DEVICE_PACKAGE_FAIL,
  DELETE_DEVICE_PACKAGE,
  DELETE_DEVICE_PACKAGE_SUCCESS,
  DELETE_DEVICE_PACKAGE_FAIL,
} from "./actionTypes"

export const getDevicePackages = () => ({
  type: GET_DEVICE_PACKAGES,
})

export const getDevicePackagesSuccess = devicePackages => ({
  type: GET_DEVICE_PACKAGES_SUCCESS,
  payload: devicePackages,
})

export const getDevicePackagesFail = error => ({
  type: GET_DEVICE_PACKAGES_FAIL,
  payload: error,
})

export const addNewDevicePackage = devicePackage => ({
  type: ADD_NEW_DEVICE_PACKAGE,
  payload: devicePackage,
})

export const addDevicePackageSuccess = devicePackage => ({
  type: ADD_DEVICE_PACKAGE_SUCCESS,
  payload: devicePackage,
})

export const addDevicePackageFail = error => ({
  type: ADD_DEVICE_PACKAGE_FAIL,
  payload: error,
})

export const updateDevicePackage = devicePackage => ({
  type: UPDATE_DEVICE_PACKAGE,
  payload: devicePackage,
})

export const updateDevicePackageSuccess = devicePackage => ({
  type: UPDATE_DEVICE_PACKAGE_SUCCESS,
  payload: devicePackage,
})

export const updateDevicePackageFail = error => ({
  type: UPDATE_DEVICE_PACKAGE_FAIL,
  payload: error,
})

export const deleteDevicePackage = devicePackage => ({
  type: DELETE_DEVICE_PACKAGE,
  payload: devicePackage,
})

export const deleteDevicePackageSuccess = devicePackage => ({
  type: DELETE_DEVICE_PACKAGE_SUCCESS,
  payload: devicePackage,
})

export const deleteDevicePackageFail = error => ({
  type: DELETE_DEVICE_PACKAGE_FAIL,
  payload: error,
})