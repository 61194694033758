/* SERVICE_TYPES */
export const GET_SERVICE_TYPES = "GET_SERVICE_TYPES"
export const GET_SERVICE_TYPES_SUCCESS = "GET_SERVICE_TYPES_SUCCESS"
export const GET_SERVICE_TYPES_FAIL = "GET_SERVICE_TYPES_FAIL"

export const ADD_NEW_SERVICE_TYPE = "ADD_NEW_SERVICE_TYPE"
export const ADD_SERVICE_TYPE_SUCCESS = "ADD_SERVICE_TYPE_SUCCESS"
export const ADD_SERVICE_TYPE_FAIL = "ADD_SERVICE_TYPE_FAIL"

export const UPDATE_SERVICE_TYPE = "UPDATE_SERVICE_TYPE"
export const UPDATE_SERVICE_TYPE_SUCCESS = "UPDATE_SERVICE_TYPE_SUCCESS"
export const UPDATE_SERVICE_TYPE_FAIL = "UPDATE_SERVICE_TYPE_FAIL"

export const DELETE_SERVICE_TYPE = "DELETE_SERVICE_TYPE"
export const DELETE_SERVICE_TYPE_SUCCESS = "DELETE_SERVICE_TYPE_SUCCESS"
export const DELETE_SERVICE_TYPE_FAIL = "DELETE_SERVICE_TYPE_FAIL"
