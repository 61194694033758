import {
  Alert,
  Button as ButtonAnt,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Select,
} from "antd"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { addNewSoftwareService, closeModalAdd } from "../../store/actions"

import { Controller, useForm } from "react-hook-form"
import dayjs from "dayjs"

const dateFormat = "YYYY/MM/DD"
const Add = props => {
  const currentDate = new Date()
  const formattedDate = currentDate.toISOString().substr(0, 10)
  const {
    modalAdd,
    onCloseModalAdd,
    onAddNewSoftwareService,
    customers,
    softwarepackages,
    errorSubs,
    // errorResponse,
    // errorNotification,
    // successNotification,
  } = props
  const [fields, setFields] = useState({})
  const [myError, setMyErrors] = useState({})
  const [listCustomers, setListCustomers] = useState([])
  const [listSoftwarepackage, setListSoftwarepackage] = useState([])
  const [showSubmitMessage, setShowSubmitMessage] = useState(false)
  const dispatch = useDispatch()
  const [showMessage, setShowMessage] = useState({
    status: false,
    message: "",
  })

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm()
  useEffect(() => {
    const currentDate = dayjs().format("YYYY-MM-DD")
    setValue("start_date_sm", currentDate)
    setValue("billing_date_sm", currentDate)
    setValue("billing_cycle_type_sm", "month")
  }, [watch("billing_cycle_sm")])
  useEffect(() => {
    let data = []
    let dataSoftwarePackage = []
    customers?.map(customer => {
      data.push({
        value: customer.id,
        label: customer.company_name,
      })
    })
    // Software packages
    softwarepackages?.map(softwarepackage => {
      dataSoftwarePackage.push({
        value: softwarepackage.id,
        label: softwarepackage.name,
      })
    })
    const currentDate = dayjs().format("YYYY-MM-DD")
    setListCustomers(data)
    setListSoftwarepackage(dataSoftwarePackage)
    setValue("billing_cycle_type_sm", "month")
    setValue("start_date_sm", currentDate)
    setValue("billing_date_sm", currentDate)
  }, [customers, softwarepackages])

  const startDateSm = watch("start_date_sm")
  const billingDateSm = watch("billing_date_sm")

  const handleOk = () => {
    onCloseModalAdd()
  }
  const handleCancel = () => {
    onCloseModalAdd()
    reset()
  }
  const billingCycleTypeSm = watch("billing_cycle_type_sm")

  const onSubmit = data => {
    if (validateInput()) {
      let newData = {
        customer: data.customer_sm,
        domain: onPremiseSM == "No" ? data.domain : undefined,
        service_pack: data.service_pack_sm,
        employee: Number(data.employee_sm),
        web_user: Number(data.web_user_sm),
        connective: Number(data.connective_sm),
        mobile_user: Number(data.mobile_user_sm),
        on_premise: data.on_premise_sm,
        one_time_payment: data.one_time_payment_sm,
        billing_cycle:
          data.one_time_payment_sm == "Yes"
            ? undefined
            : Number(data.billing_cycle_sm),
        billing_cycle_type:
          data.one_time_payment_sm == "Yes"
            ? undefined
            : data.billing_cycle_type_sm,
        status: data.status_sm,
        start_date: data.start_date_sm,
        billing_date:
          data.status_sm == "active" ? data.billing_date_sm : undefined,
        terminate_date:
          data.status_sm == "terminate" ? data.terminate_date_sm : undefined,
      }
      let MergeData = { ...newData, ...fields }
      let message = {
        action: "create",
        message: MergeData,
        type_message: "map",
      }
      onAddNewSoftwareService(message)

      reset(
        {
          start_date_sm: dayjs().format("YYYY-MM-DD"),
          billing_date_sm: dayjs().format("YYYY-MM-DD"),
        },
        {
          keepValues: false,
          keepDirty: false,
        }
      )
    }
  }

  const closeAlert = () => {
    setShowMessage({ status: false, message: "" })
  }
  if (showMessage.status === true) {
    setTimeout(closeAlert, 5000) // đóng thông báo sau 3 giây
  }
  const validateInput = () => {
    let isValid = true
    let errs = myError
    setMyErrors(errs)
    return isValid
  }

  const handleInput = e => {
    let currentFields = fields
    currentFields[e.target.name] = e.target.value
    setFields(currentFields)
  }

  const onChange = value => {
    delete myError["default_language"]
    setMyErrors(myError)
    fields.default_language = value
    setFields(fields)
  }
  const statusSM = watch("status_sm")
  const onPremiseSM = watch("on_premise_sm")
  const oneTimepayment = watch("one_time_payment_sm")

  const extractDateOnly = dateTimeString => {
    return dateTimeString.split("T")[0]
  }
  const billingCycleType = [
    {
      value: "Quarter",
      label: "Quý",
    },
    {
      value: "month",
      label: "Tháng",
    },
    {
      value: "year",
      label: "Năm",
    },
  ]

  return (
    <>
      <Modal
        isOpen={modalAdd.isOpenModal}
        toggle={handleCancel}
        fullscreen={"lg"}
        size={"lg"}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
          <ModalBody>
            <Container>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Tên khách hàng<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Select
                      {...register("customer_sm", { required: true })}
                      onChange={value =>
                        setValue("customer_sm", value, { shouldValidate: true })
                      }
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn khách hàng..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={listCustomers}
                    />
                    {errors.customer_sm &&
                      errors.customer_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng chọn khách hàng
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              {/* {onPremiseSM === "No" && ( */}
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Tên miền <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    {/* <input
                      disabled={onPremiseSM == "No" ? false : true}
                      aria-invalid={errors.domain ? "true" : "false"}
                      {...register("domain", {
                        required: true,
                        pattern:
                          /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
                      })}
                      className="form-control"
                      placeholder="Tên miền..."
                    /> */}

                    <Input
                      disabled={onPremiseSM == "No" ? false : true}
                      type="text"
                      placeholder="Nhập tên miền..."
                      {...register("domain", {
                        required: onPremiseSM == "No" ? true : false,
                        pattern:
                          onPremiseSM == "No"
                            ? /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
                            : false,
                      })}
                      onChange={e =>
                        setValue("domain", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.domain && errors.domain.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Tên miền không được để trống
                      </span>
                    )}

                    {errors.domain && errors.domain.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Không đúng định dạng tên miền
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {/* )} */}

              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Chọn gói dịch vụ phần mềm
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Select
                      {...register("service_pack_sm", { required: true })}
                      onChange={value =>
                        setValue("service_pack_sm", value, {
                          shouldValidate: true,
                        })
                      }
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn gói dịch vụ phần mềm..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={listSoftwarepackage}
                    />
                    {errors.service_pack_sm &&
                      errors.service_pack_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng chọn gói dịch vụ phần mềm
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Trạng thái<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="status_sm"
                      rules={{ required: "Vui lòng chọn trạng thái" }}
                      defaultValue="active"
                      render={({ field }) => (
                        <Radio.Group {...field}>
                          <Radio value={"active"}>Hoạt động</Radio>
                          <Radio value={"suspend"}>Tạm ngưng</Radio>
                          <Radio value={"terminate"}>Ngưng dịch vụ</Radio>
                          <Radio value={"experiment"}>Thử nghiệm</Radio>
                        </Radio.Group>
                      )}
                    />
                  </FormGroup>
                  {/* {errors.status_sm && errors.status_sm.type === "required" && (
                    <span
                      role="alert"
                      className="text-danger"
                      style={{ fontSize: "13px" }}
                    >
                      Vui lòng chọn khách hàng
                    </span>
                  )} */}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Số lượng nhân viên <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="number"
                      {...register("employee_sm", {
                        required: true,
                        pattern: /^\d*[0-9]\d*$/,
                      })}
                      placeholder="Nhập số lượng nhân viên..."
                      onChange={e =>
                        setValue("employee_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.employee_sm &&
                      errors.employee_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập số lượng nhân viên
                        </span>
                      )}
                    {errors.employee_sm &&
                      errors.employee_sm.type === "pattern" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập vào 1 số dương
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Số lượng người dùng trên web
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="number"
                      {...register("web_user_sm", {
                        required: true,
                        pattern: /^\d*[0-9]\d*$/,
                      })}
                      onChange={e =>
                        setValue("web_user_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                      placeholder="Nhập số lượng người dùng trên web..."
                    />
                    {errors.web_user_sm &&
                      errors.web_user_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập số lượng người dùng trên web
                        </span>
                      )}
                    {errors.web_user_sm &&
                      errors.web_user_sm.type === "pattern" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập vào 1 số dương
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Số lượng người dùng trên ứng dụng di động
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="number"
                      {...register("mobile_user_sm", {
                        required: true,
                        pattern: /^\d*[0-9]\d*$/,
                      })}
                      onChange={e =>
                        setValue("mobile_user_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                      placeholder="Nhập số lượng người dùng trên ứng dụng di động..."
                    />
                    {errors.mobile_user_sm &&
                      errors.mobile_user_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập số lượng người dùng trên ứng dụng di
                          động
                        </span>
                      )}
                    {errors.mobile_user_sm &&
                      errors.mobile_user_sm.type === "pattern" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập vào 1 số dương
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Số lượng thiết bị kết nối
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="number"
                      {...register("connective_sm", {
                        required: true,
                        pattern: /^\d*[0-9]\d*$/,
                      })}
                      placeholder="Nhập số lượng thiết bị kết nối..."
                      onChange={e =>
                        setValue("connective_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.connective_sm &&
                      errors.connective_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập số lượng thiết bị kết nối
                        </span>
                      )}
                    {errors.connective_sm &&
                      errors.connective_sm.type === "pattern" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập vào 1 số dương
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Premise<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="on_premise_sm"
                      rules={{ required: "Vui lòng chọn premise..." }}
                      defaultValue="No"
                      render={({ field }) => (
                        <Radio.Group {...field}>
                          <Radio value={"Yes"}>Có</Radio>
                          <Radio value={"No"}>Không</Radio>
                        </Radio.Group>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Thanh toán 1 lần<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="one_time_payment_sm"
                      rules={{ required: "Vui lòng chọn premise" }}
                      defaultValue="No"
                      render={({ field }) => (
                        <Radio.Group {...field}>
                          <Radio value={"Yes"}>Có</Radio>
                          <Radio value={"No"}>Không</Radio>
                        </Radio.Group>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Kỳ thanh toán<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <InputNumber
                    disabled={oneTimepayment == "Yes" ? true : false}
                    style={{
                      width: "100%",
                    }}
                    min={1}
                    addonAfter={
                      <Select
                        disabled={oneTimepayment == "Yes" ? true : false}
                        {...register("billing_cycle_type_sm")}
                        onChange={value =>
                          setValue("billing_cycle_type_sm", value, {
                            shouldValidate: true,
                          })
                        }
                        defaultValue="month"
                      >
                        {billingCycleType.map(type => (
                          <Select.Option key={type.value} value={type.value}>
                            {type.label}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    {...register("billing_cycle_sm", {
                      required:
                        oneTimepayment === "No"
                          ? "Vui lòng nhập thời hạn chu kỳ thanh toán"
                          : false,
                      pattern: /^\d*[0-9]\d*$/,
                    })}
                    onChange={value =>
                      setValue("billing_cycle_sm", value, {
                        shouldValidate: true,
                      })
                    }
                    // onChange={onChangeBilling("billing_cycle")}
                  />
                  {errors.billing_cycle_type_sm &&
                    errors.billing_cycle_type_sm.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        {errors.billing_cycle_type_sm.message}
                      </span>
                    )}
                  {errors.billing_cycle_sm &&
                    errors.billing_cycle_sm.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng nhập kỳ thanh toán
                      </span>
                    )}
                  {errors.billing_cycle_sm &&
                    errors.billing_cycle_sm.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng nhập vào 1 số dương
                      </span>
                    )}
                </Col>
              </Row>

              {/* <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Chu kỳ thanh toán<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Select
                      disabled={oneTimepayment == "Yes" ? true : false}
                      {...register("billing_cycle_type_sm", {
                        required:
                          oneTimepayment === "No"
                            ? "Vui lòng chọn gói dịch vụ phần mềm"
                            : false,
                      })}
                      onChange={value =>
                        setValue("billing_cycle_type_sm", value, {
                          shouldValidate: true,
                        })
                      }
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn chu kỳ thanh toán..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "quarter",
                          label: "Quý",
                        },
                        {
                          value: "month",
                          label: "Tháng",
                        },
                        {
                          value: "year",
                          label: "Năm",
                        },
                      ]}
                    />
                    {errors.billing_cycle_type_sm &&
                      errors.billing_cycle_type_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          {errors.billing_cycle_type_sm.message}
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Thời hạn chu kỳ thanh toán
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <FormGroup>
                      <Input
                        disabled={oneTimepayment == "Yes" ? true : false}
                        placeholder="Vui lòng nhập số..."
                        type="number"
                        {...register("billing_cycle_sm", {
                          required:
                            oneTimepayment === "No"
                              ? "Vui lòng nhập thời hạn chu kỳ thanh toán"
                              : false,
                          pattern: /^\d*[0-9]\d*$/,
                        })}
                        onChange={e =>
                          setValue("billing_cycle_sm", e.target.value, {
                            shouldValidate: true,
                          })
                        }
                      />
                      {errors.billing_cycle_sm &&
                        errors.billing_cycle_sm.type === "required" && (
                          <span
                            role="alert"
                            className="text-danger"
                            style={{ fontSize: "13px" }}
                          >
                            Vui lòng nhập thời hạn chu kỳ thanh toán
                          </span>
                        )}
                      {errors.billing_cycle_sm &&
                        errors.billing_cycle_sm.type === "pattern" && (
                          <span
                            role="alert"
                            className="text-danger"
                            style={{ fontSize: "13px" }}
                          >
                            Vui lòng nhập vào 1 số dương
                          </span>
                        )}
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row> */}
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Ngày sử dụng dịch vụ<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <DatePicker
                      style={{ width: "100%" }}
                      {...register("start_date_sm", {
                        required: true,
                      })}
                      onChange={value =>
                        setValue(
                          "start_date_sm",
                          dayjs(value).format("YYYY-MM-DD"),
                          {
                            shouldValidate: true,
                          }
                        )
                      }
                      defaultValue={dayjs(startDateSm)}
                      format="YYYY-MM-DD"
                    />

                    {errors.start_date_sm &&
                      errors.start_date_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập ngày sử dụng
                        </span>
                      )}
                    {watch("start_date_sm") == "Invalid Date" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng nhập ngày sử dụng
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Ngày bắt đầu tính cước<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <DatePicker
                      disabled={statusSM == "active" ? false : true}
                      style={{ width: "100%" }}
                      {...register("billing_date_sm", {
                        required: true,
                      })}
                      onChange={value =>
                        setValue(
                          "billing_date_sm",
                          dayjs(value).format("YYYY-MM-DD"),
                          {
                            shouldValidate: true,
                          }
                        )
                      }
                      defaultValue={dayjs(billingDateSm)}
                      format="YYYY-MM-DD"
                    />
                    {/* <Input
                      disabled={statusSM == "active" ? false : true}
                      type="date"
                      {...register("billing_date_sm", {
                        required:
                          statusSM === "active"
                            ? "Vui lòng chọn ngày ngày tính cước"
                            : false,
                      })}
                      onChange={e =>
                        setValue("billing_date_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    /> */}
                    {errors.billing_date_sm &&
                      errors.billing_date_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập ngày tính cước
                        </span>
                      )}
                    {watch("billing_date_sm") == "Invalid Date" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng nhập ngày tính cước
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Ngày ngưng dịch vụ<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      disabled={statusSM == "terminate" ? false : true}
                      type="date"
                      {...register("terminate_date_sm", {
                        required:
                          statusSM === "terminate"
                            ? "Vui lòng chọn ngày ngưng dịch vụ"
                            : false,
                      })}
                      onChange={e =>
                        setValue("terminate_date_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.terminate_date_sm &&
                      errors.terminate_date_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          {errors.terminate_date_sm.message}
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <ButtonAnt htmlType="submit" type="primary">
              Thêm mới
            </ButtonAnt>{" "}
            <ButtonAnt onClick={handleCancel}>Đóng</ButtonAnt>
          </ModalFooter>
        </Form>
      </Modal>
      {showMessage.status == true && (
        <Alert
          message={showMessage.message}
          type={showMessage.color}
          onClose={closeAlert}
          style={{
            position: "fixed",
            fontWeight: "600",
            top: "100px",
            right: "20px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 50px",
          }}
        />
      )}
    </>
  )
}
Add.propTypes = {
  t: PropTypes.any,
  modalAdd: PropTypes.object,
  errorReponse: PropTypes.object,
  onCloseModalAdd: PropTypes.func,
}

const mapStateToProps = ({
  softwareservice,
  helper,
  customer,
  softwarepackage,
}) => ({
  modalAdd: helper.modalAdd,
  customers: customer.customers,
  softwarepackages: softwarepackage.softwarepackages,
  errorSubs: softwareservice.errorSubs,
  // errorResponse: softwareservice.errorSub,
  // errorNotification: softwareservice.errorNotification,
  // successNotification: softwareservice.successNotification,
})

const mapDispatchToProps = dispatch => ({
  onCloseModalAdd: () => dispatch(closeModalAdd()),
  onAddNewSoftwareService: message => dispatch(addNewSoftwareService(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Add)
