import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Customers
import CustomersContact from "../pages/CustomersOld/Contact/index"
import Customers from "../pages/CustomersOld/index"
import CustomersContactDetail from "../pages/CustomersOld/Contact/Detail"
// Users
import Users from "../pages/Users/index"

// Roles
import Roles from "../pages/Roles/index"

// ServiceTypes
import ServiceTypes from "../pages/ServiceTypes/index"

// PackageGroups
import PackageGroups from "../pages/PackageGroups/index"

// Services
import Services from "../pages/Services/index"

import ServiceCustomer from "../pages/ServiceCustomer/index"
import ServicePricing from "../pages/ServicePricing/index"
import ProductComponent from "../pages/ProductComponents/index"
import SoftwareService from "../pages/SoftwareServices/index"
import SoftwarePackage from "../pages/SoftwarePackages/index"
import DeviceTypes from "../pages/HardwareType/index"
import DevicePackages from "../pages/HardwarePackage/index"
import DeviceServices from "../pages/HardwareService/index"
import SystemLogs from "../pages/SystemLog/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

const userRoutes = [
  // Categories
  { path: "/customers", component: Customers },
  { path: "/customers/contact", component: CustomersContact },
  // { path: `/customers/contact/${id}`, component: CustomersContactDetail },
  { path: "/users", component: Users },
  { path: "/roles", component: Roles },
  { path: "/service-types", component: ServiceTypes },
  { path: "/package-groups", component: PackageGroups },
  { path: "/services", component: Services },
  { path: "/service-customer", component: ServiceCustomer },
  { path: "/service-pricing", component: ServicePricing },
  { path: "/product-component", component: ProductComponent },
  { path: "/software-service", component: SoftwareService },
  { path: "/software-package", component: SoftwarePackage },
  { path: "/hardware-type", component: DeviceTypes },
  { path: "/hardware-package", component: DevicePackages },
  { path: "/hardware-service", component: DeviceServices },
  { path: "/system-logs", component: SystemLogs },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/customers" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }
