import {
  GET_SYSTEM_LOGS_SUCCESS,
  GET_SYSTEM_LOGS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  systemLogs: {},
  error: {},
}

const systemLog = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SYSTEM_LOGS_SUCCESS:
      return {
        ...state,
        systemLogs: action.payload,
      }

    case GET_SYSTEM_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default systemLog
