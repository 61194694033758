import {
  GET_DEVICE_SERVICES,
  GET_DEVICE_SERVICES_FAIL,
  GET_DEVICE_SERVICES_SUCCESS,
  ADD_NEW_DEVICE_SERVICE,
  ADD_DEVICE_SERVICE_SUCCESS,
  ADD_DEVICE_SERVICE_FAIL,
  UPDATE_DEVICE_SERVICE,
  UPDATE_DEVICE_SERVICE_SUCCESS,
  UPDATE_DEVICE_SERVICE_FAIL,
  DELETE_DEVICE_SERVICE,
  DELETE_DEVICE_SERVICE_SUCCESS,
  DELETE_DEVICE_SERVICE_FAIL,
} from "./actionTypes"

export const getDeviceServices = () => ({
  type: GET_DEVICE_SERVICES,
})

export const getDeviceServicesSuccess = deviceServices => ({
  type: GET_DEVICE_SERVICES_SUCCESS,
  payload: deviceServices,
})

export const getDeviceServicesFail = error => ({
  type: GET_DEVICE_SERVICES_FAIL,
  payload: error,
})

export const addNewDeviceService = deviceService => ({
  type: ADD_NEW_DEVICE_SERVICE,
  payload: deviceService,
})

export const addDeviceServiceSuccess = deviceService => ({
  type: ADD_DEVICE_SERVICE_SUCCESS,
  payload: deviceService,
})

export const addDeviceServiceFail = error => ({
  type: ADD_DEVICE_SERVICE_FAIL,
  payload: error,
})

export const updateDeviceService = deviceService => ({
  type: UPDATE_DEVICE_SERVICE,
  payload: deviceService,
})

export const updateDeviceServiceSuccess = deviceService => ({
  type: UPDATE_DEVICE_SERVICE_SUCCESS,
  payload: deviceService,
})

export const updateDeviceServiceFail = error => ({
  type: UPDATE_DEVICE_SERVICE_FAIL,
  payload: error,
})

export const deleteDeviceService = deviceService => ({
  type: DELETE_DEVICE_SERVICE,
  payload: deviceService,
})

export const deleteDeviceServiceSuccess = deviceService => ({
  type: DELETE_DEVICE_SERVICE_SUCCESS,
  payload: deviceService,
})

export const deleteDeviceServiceFail = error => ({
  type: DELETE_DEVICE_SERVICE_FAIL,
  payload: error,
})