import {
  GET_SERVICE_CUSTOMER,
  GET_SERVICE_CUSTOMER_FAIL,
  GET_SERVICE_CUSTOMER_SUCCESS,
  ADD_NEW_SERVICE_CUSTOMER,
  ADD_SERVICE_CUSTOMER_SUCCESS,
  ADD_SERVICE_CUSTOMER_FAIL,
  UPDATE_SERVICE_CUSTOMER,
  UPDATE_SERVICE_CUSTOMER_SUCCESS,
  UPDATE_SERVICE_CUSTOMER_FAIL,
  DELETE_SERVICE_CUSTOMER,
  DELETE_SERVICE_CUSTOMER_SUCCESS,
  DELETE_SERVICE_CUSTOMER_FAIL,
} from "./actionTypes"

export const getServiceCustomer = () => ({
  type: GET_SERVICE_CUSTOMER,
})

export const getServiceCustomerSuccess = serviceCustomers => ({
  type: GET_SERVICE_CUSTOMER_SUCCESS,
  payload: serviceCustomers,
})

export const getServiceCustomerFail = error => ({
  type: GET_SERVICE_CUSTOMER_FAIL,
  payload: error,
})

export const addNewServiceCustomer = serviceCustomer => ({
  type: ADD_NEW_SERVICE_CUSTOMER,
  payload: serviceCustomer,
})

export const addServiceCustomerSuccess = serviceCustomer => ({
  type: ADD_SERVICE_CUSTOMER_SUCCESS,
  payload: serviceCustomer,
})

export const addServiceCustomerFail = error => ({
  type: ADD_SERVICE_CUSTOMER_FAIL,
  payload: error,
})

export const updateServiceCustomer = serviceCustomer => ({
  type: UPDATE_SERVICE_CUSTOMER,
  payload: serviceCustomer,
})

export const updateServiceCustomerSuccess = serviceCustomer => ({
  type: UPDATE_SERVICE_CUSTOMER_SUCCESS,
  payload: serviceCustomer,
})

export const updateServiceCustomerFail = error => ({
  type: UPDATE_SERVICE_CUSTOMER_FAIL,
  payload: error,
})

export const deleteServiceCustomer = serviceCustomer => ({
  type: DELETE_SERVICE_CUSTOMER,
  payload: serviceCustomer,
})

export const deleteServiceCustomerSuccess = serviceCustomer => ({
  type: DELETE_SERVICE_CUSTOMER_SUCCESS,
  payload: serviceCustomer,
})

export const deleteServiceCustomerFail = error => ({
  type: DELETE_SERVICE_CUSTOMER_FAIL,
  payload: error,
})