import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMERS,
  UPDATE_CUSTOMER,
  // Contact
  ADD_NEW_CONTACT,
  DELETE_CONTACT,
  GET_CONTACT,
  UPDATE_CONTACT,
  GET_CUSTOMER_CONTACT,
  GET_CUSTOMER_SOFT_SERVICE,
  GET_CUSTOMER_HARD_SERVICE,
} from "./actionTypes"
import {
  getCustomersSuccess,
  getCustomersFail,
  addCustomerFail,
  addCustomerSuccess,
  updateCustomerSuccess,
  updateCustomerFail,
  deleteCustomerSuccess,
  deleteCustomerFail,

  // Contact
  getContactsSuccess,
  getContactsFail,
  addContactsFail,
  addContactsSuccess,
  updateContactSuccess,
  updateContactFail,
  deleteContactSuccess,
  deleteContactFail,
  getDetailCusContactSuccess,
  getDetailCusContactFail,
  getDetailCusSoftServiceSuccess,
  getDetailCusHardServiceSuccess,
  getDetailCusSoftServiceFail,
  getDetailCusHardServiceFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCustomers,
  addNewCustomer,
  updateCustomer,
  deleteCustomer,
  // Contact
  addNewContact,
  getContacts,
  updateContact,
  deleteContact,
  getDetailCusContact,
  getDetailCusSoftService,
  getDetailCusHardService,
} from "../../helpers/backend_api/customers"

import { closeModalAdd, closeModalEdit } from "../helper/actions"
import { GET_CONTACTS } from "./actionTypes"
import { notification } from "antd"
const showErrorMessage = message => {
  notification.error({
    message: message,
    duration: 3, // Đặt thời gian tự động tắt (giây)
    style: { right: 0, zIndex: 9999 },
  })
}

const showSuccessMessage = message => {
  notification.success({
    message: message,
    duration: 3, // Đặt thời gian tự động tắt (giây)
    style: { right: 0, zIndex: 9999 },
  })
}
function* fetchCustomers() {
  yield put(getCustomersFail({}))
  try {
    const response = yield call(getCustomers)
    if (response.code === 200) {
      yield put(getCustomersSuccess(response.message))
    } else {
      yield put(getCustomersFail(response))
    }
  } catch (error) {
    yield put(getCustomersFail(error))
  }
}

function* onAddNewCustomer({ payload: customer }) {
  try {
    const response = yield call(addNewCustomer, customer)
    if (response.code === 201) {
      yield call(fetchCustomers)
      yield put(closeModalAdd())
    } else {
      yield put(addCustomerFail(response))
    }
  } catch (error) {
    yield put(addCustomerFail(error))
  }
}

function* onUpdateCustomer({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer)
    if (response.code === 200) {
      yield call(fetchCustomers)
      yield put(closeModalEdit())
    } else {
      yield put(updateCustomerFail(response))
    }
  } catch (error) {
    yield put(updateCustomerFail(error))
  }
}

function* onDeleteCustomer({ payload: customer }) {
  try {
    const response = yield call(deleteCustomer, customer)
    if (response.code === 200) {
      yield call(fetchCustomers)
    } else {
      yield put(deleteCustomerFail(response))
    }
  } catch (error) {
    yield put(deleteCustomerFail(error))
  }
}

// Contact
function* fetchContacts() {
  yield put(getContactsFail({}))
  try {
    const response = yield call(getContacts)
    if (response.code === 200) {
      yield put(getContactsSuccess(response.message))
    } else {
      yield put(getContactsFail(response))
    }
  } catch (error) {
    yield put(getContactsFail(error))
  }
}

function* onAddNewContact({ payload: contact }) {
  try {
    const response = yield call(addNewContact, contact)
    if (response.code === 201) {
      const contactRes = yield call(getContacts)
      if (contactRes.code === 200) {
        yield put(getContactsSuccess(contactRes.message))
        showSuccessMessage("Thêm thành công")
        yield put(closeModalAdd())
      } else {
        yield put(getContactsFail(error))
        showErrorMessage(
          "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
        )
      }
      yield call(fetchCustomers)
      yield put(closeModalAdd())
    } else {
      yield put(addContactsFail(response))
    }
  } catch (error) {
    yield put(addContactsFail(error))
  }
}

function* onUpdateContact({ payload: contact }) {
  try {
    const response = yield call(updateContact, contact)
    if (response.code === 200) {
      const contactsRes = yield call(getContacts)
      if (contactsRes.code === 200) {
        yield put(getContactsSuccess(contactsRes.message))
        showSuccessMessage("Cập nhật thành công")
        yield put(closeModalEdit())
      } else {
        yield put(getContactsFail(error))
        showErrorMessage(
          "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
        )
      }
      yield call(fetchCustomers)
      yield put(closeModalEdit())
    } else {
      yield put(updateContactFail(response))
    }
  } catch (error) {
    yield put(updateContactFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
    )
  }
}

function* onDeleteContact({ payload: contact }) {
  try {
    const response = yield call(deleteContact, contact)
    if (response.code === 200) {
      const contactsRes = yield call(getContacts)
      if (contactsRes.code === 200) {
        yield put(getContactsSuccess(contactsRes.message))
        showSuccessMessage("Xóa thành công")
      } else {
        yield put(getContactsFail(error))
        showErrorMessage(
          "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
        )
      }
      yield call(fetchCustomers)
    } else {
      yield put(deleteContactFail(response))
    }
  } catch (error) {
    yield put(deleteContactFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
    )
  }
}

// Detail
function* fetchDetailCusContact(action) {
  try {
    const id = action.payload
    const response = yield call(getDetailCusContact, id)
    if (response.code === 200) {
      yield put(getDetailCusContactSuccess(response.message))
    } else {
      yield put(getDetailCusContactFail(response))
    }
  } catch (error) {
    yield put(getDetailCusContactFail(error))
  }
}

function* fetchDetailCusSoft(action) {
  try {
    const id = action.payload
    const response = yield call(getDetailCusSoftService, id)
    if (response.code === 200) {
      yield put(getDetailCusSoftServiceSuccess(response.message))
    } else {
      yield put(getDetailCusSoftServiceFail(response))
    }
  } catch (error) {
    yield put(getDetailCusSoftServiceFail(error))
  }
}

function* fetchDetailCusHard(action) {
  try {
    const id = action.payload
    const response = yield call(getDetailCusHardService, id)
    if (response.code === 200) {
      yield put(getDetailCusHardServiceSuccess(response.message))
    } else {
      yield put(getDetailCusHardServiceFail(response))
    }
  } catch (error) {
    yield put(getDetailCusHardServiceFail(error))
  }
}

function* customerSaga() {
  yield takeEvery(GET_CUSTOMERS, fetchCustomers)
  yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer)
  yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer)
  yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer)
  // Contact
  yield takeEvery(GET_CONTACTS, fetchContacts)
  yield takeEvery(ADD_NEW_CONTACT, onAddNewContact)
  yield takeEvery(UPDATE_CONTACT, onUpdateContact)
  yield takeEvery(DELETE_CONTACT, onDeleteContact)

  // Detail
  yield takeEvery(GET_CUSTOMER_CONTACT, fetchDetailCusContact)
  yield takeEvery(GET_CUSTOMER_SOFT_SERVICE, fetchDetailCusSoft)
  yield takeEvery(GET_CUSTOMER_HARD_SERVICE, fetchDetailCusHard)
}

export default customerSaga
