import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CardHeader
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react";

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";

//i18n
import { withTranslation } from "react-i18next"
import RoleTable from './RoleTable';
import { Input as InputAnt, Button as ButtonAnt } from 'antd';


import {
    getRoles,
    getConstraints
} from "../../store/actions"
import { connect } from "react-redux"
import { get } from 'lodash';
import { CHANGE_OPEN_MODAL_ADD } from 'store/helper/actionTypes';
import Add from "./Add"
import Permission from 'permissions/Permission';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";

const Roles = props => {
    const { onGetRoles, onOpenModalAdd, onGetConstraint, messageError, myRole } = props

    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        onGetRoles();
        onGetConstraint();
    }, [onGetRoles, onGetConstraint])

    useEffect(() => {
        if (!_.isEmpty(messageError)) {
            toast.error(messageError.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [messageError])

    const handleAdd = () => {
        let modal = {
            isOpenModal: true,
            title: "Thêm mới vai trò"
        }
        onOpenModalAdd(modal)
    }

    const onSearch = (e) => {
        setKeyword(e.target.value);
    }

    return (
        <React.Fragment>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div className="page-content">
                <MetaTags>
                    <title>Vai trò</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">Vai trò</h6>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Danh mục</li>
                                    <li className="breadcrumb-item active">Vai trò</li>
                                </ol>
                            </Col>
                        </Row>
                    </div>
                    {/* Table here */}
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle className="h4">Danh sách vai trò</CardTitle>
                                            <p className="card-title-desc">
                                                Quản lý tập vai trò của Qi
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={8}>
                                            <div className='mb-2'>
                                                <Permission myRole={myRole} permission={{ label: 'user role', action: "create" }}>
                                                    <ButtonAnt type="primary" className='me-1' onClick={() => handleAdd()}>+ Thêm mới</ButtonAnt>
                                                    <Add></Add>
                                                </Permission>
                                                {/* <ButtonAnt className='me-1'><i className='fas fa-filter text-primary'></i></ButtonAnt> */}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className='float-end mb-2'>
                                                <InputAnt
                                                    onChange={onSearch}
                                                    placeholder="search..." style={{
                                                        width: 200,
                                                    }} />
                                            </div>

                                        </Col>
                                    </Row>
                                    <RoleTable keyword={keyword}></RoleTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )
}

Roles.propTypes = {
    t: PropTypes.any,
    roles: PropTypes.array,
    onGetRoles: PropTypes.func,
    onOpenModalAdd: PropTypes.func,
    onGetConstraint: PropTypes.func,
    messageError: PropTypes.any,
    myRole: PropTypes.array
}

const mapStateToProps = ({ role, Login }) => ({
    roles: role.roles,
    messageError: role.error,
    myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
    onGetRoles: () => dispatch(getRoles()),
    onOpenModalAdd: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_ADD, payload: modal }),
    onGetConstraint: () => dispatch(getConstraints())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Roles))
