import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, InputNumber } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewDevicePackage,
    getDeviceTypes
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';

const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewDevicePackage, onGetDeviceTypes, deviceTypes } = props;
    const [fields, setFields] = useState({ price_cost: 1, rent_cost: 1 });
    const [myError, setMyErrors] = useState({});
    const [deviceTypeList, setDeviceTypeList] = useState([]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        onGetDeviceTypes();
    }, [onGetDeviceTypes])

    useEffect(() => {
        let data = [];
        deviceTypes.map(type => {
            data.push({
                label: type.name,
                value: type.id
            });
        })
        setDeviceTypeList(data);
    }, [deviceTypes])

    const handleCancel = () => {
        onCloseModalAdd();
    };

    const onSubmit = data => {
        if (validateInput()) {
            let merge = { ...data, ...fields }
            let message = {
                'action': 'create',
                'message': merge,
                "type_message": "map"
            }
            onAddNewDevicePackage(message);
        }

    };

    const validateInput = () => {
        let isValid = true;
        let errs = myError;
        if (!fields.hardware_type) {
            errs.hardware_type = {
                type: 'required',
                message: 'Loại thiết bị không được phép để trống'
            }
            isValid = false;
        }
        setMyErrors(errs);
        return isValid;
    }

    const onChange = name => (value) => {
        let myFields = fields;
        myFields[name] = value;
        if (name === 'price_cost' || name === 'rent_cost') {
            if (value === null) {
                myFields[name] = 1;
            }
        }
        setFields(myFields);
    }

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Loại thiết bị <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <SelectAnt
                                            showSearch
                                            placeholder="Chọn loại thiết bị"
                                            optionFilterProp="children"
                                            onChange={onChange('hardware_type')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                            options={deviceTypeList}
                                        />
                                        {myError.hardware_type && myError.hardware_type.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Loại thiết bị không được để trống</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Tên thiết bị <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            aria-invalid={errors.name ? "true" : "false"}
                                            {...register('name', {
                                                required: true,
                                            })}
                                            className="form-control"
                                            placeholder='Tên thiết bị...'
                                        />
                                        {errors.name && errors.name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên thiết bị không được để trống</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Model <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            aria-invalid={errors.hardware_model ? "true" : "false"}
                                            {...register('hardware_model', {
                                                required: true,
                                            })}
                                            className="form-control"
                                            placeholder='Model thiết bị...'
                                        />
                                        {errors.hardware_model && errors.hardware_model.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Model không được để trống</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Giá bán</label>
                                </Col>
                                <Col md={8}>
                                    <InputNumber
                                        defaultValue={1}
                                        onChange={onChange('price_cost')}
                                        style={{
                                            width: '100%',
                                        }}
                                        min={1}
                                        formatter={(value) => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/[đ\s.,]/gi, "")}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Giá thuê</label>
                                </Col>
                                <Col md={8}>
                                    <InputNumber
                                        defaultValue={1}
                                        onChange={onChange('rent_cost')}
                                        style={{
                                            width: '100%',
                                        }}
                                        min={1}
                                        formatter={(value) => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/[đ\s.,]/gi, "")}
                                    />
                                </Col>
                            </Row>
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thêm mới
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func,
    onAddNewDevicePackage: PropTypes.func
}

const mapStateToProps = ({ deviceType, helper }) => ({
    modalAdd: helper.modalAdd,
    deviceTypes: deviceType.deviceTypes,
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewDevicePackage: (message) => dispatch(addNewDevicePackage(message)),
    onGetDeviceTypes: () => dispatch(getDeviceTypes()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);