const serviceCustomers = [
    {
        id: 1,
        customer_name: 'Qi Technologies',
        service: "Enterprise",
        try_date: "",
        sign_date: "1/12/2022",
        termindate_date: "",
        status: "Đang sử dụng",
        description: 'sản phẩm quản lý nhân sự trên nền tầng cloud',
    },
    {
        id: 2,
        customer_name: 'VietCredit',
        service: "On Premise",
        try_date: "",
        sign_date: "1/12/2022",
        termindate_date: "",
        status: "Đang sử dụng",
        description: 'sản phẩm quản lý nhân sự trên nền tầng cloud',
    },
    {
        id: 3,
        customer_name: 'PMS',
        service: "Standard",
        try_date: "",
        sign_date: "1/1/2023",
        termindate_date: "",
        status: "Dùng thử",
        description: 'sản phẩm quản lý nhân sự trên nền tầng cloud',
        description: 'Cung cấp dịch vụ đỉnh nhất từ trước đến giờ',
    },
];

export { serviceCustomers }