/* DEVICE_PACKAGES */
export const GET_DEVICE_PACKAGES = "GET_DEVICE_PACKAGES"
export const GET_DEVICE_PACKAGES_SUCCESS = "GET_DEVICE_PACKAGES_SUCCESS"
export const GET_DEVICE_PACKAGES_FAIL = "GET_DEVICE_PACKAGES_FAIL"

export const ADD_NEW_DEVICE_PACKAGE = "ADD_NEW_DEVICE_PACKAGE"
export const ADD_DEVICE_PACKAGE_SUCCESS = "ADD_DEVICE_PACKAGE_SUCCESS"
export const ADD_DEVICE_PACKAGE_FAIL = "ADD_DEVICE_PACKAGE_FAIL"

export const UPDATE_DEVICE_PACKAGE = "UPDATE_DEVICE_PACKAGE"
export const UPDATE_DEVICE_PACKAGE_SUCCESS = "UPDATE_DEVICE_PACKAGE_SUCCESS"
export const UPDATE_DEVICE_PACKAGE_FAIL = "UPDATE_DEVICE_PACKAGE_FAIL"

export const DELETE_DEVICE_PACKAGE = "DELETE_DEVICE_PACKAGE"
export const DELETE_DEVICE_PACKAGE_SUCCESS = "DELETE_DEVICE_PACKAGE_SUCCESS"
export const DELETE_DEVICE_PACKAGE_FAIL = "DELETE_DEVICE_PACKAGE_FAIL"
