import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem("user")
    if (user) return JSON.parse(user)
    return null
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}

// Login Method
const postLogin = data => post(url.POST_LOGIN, data)

const getMyRole = () => get(url.GET_MY_ROLE)


export {
    getLoggedInUser,
    isUserAuthenticated,
    postLogin,
    getMyRole
}
