import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  ADD_NEW_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  ROW_CUSTOMER,
  // Contact
  ADD_NEW_CONTACT,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAIL,
  GET_CONTACTS,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAIL,
  UPDATE_CONTACT,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAIL,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  OPEN_DETAIL,
  CLOSE_DETAIL,

  // Detail
  GET_CUSTOMER_CONTACT,
  GET_CUSTOMER_CONTACT_SUCCESS,
  GET_CUSTOMER_CONTACT_FAIL,
  GET_CUSTOMER_SOFT_SERVICE,
  GET_CUSTOMER_SOFT_SERVICE_SUCCESS,
  GET_CUSTOMER_SOFT_SERVICE_FAIL,
  GET_CUSTOMER_HARD_SERVICE,
  GET_CUSTOMER_HARD_SERVICE_SUCCESS,
  GET_CUSTOMER_HARD_SERVICE_FAIL,
} from "./actionTypes"

export const getCustomers = () => ({
  type: GET_CUSTOMERS,
})

export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
})

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
})

export const addNewCustomer = customer => ({
  type: ADD_NEW_CUSTOMER,
  payload: customer,
})

export const addCustomerSuccess = customer => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: customer,
})

export const addCustomerFail = error => ({
  type: ADD_CUSTOMER_FAIL,
  payload: error,
})

export const updateCustomer = customer => ({
  type: UPDATE_CUSTOMER,
  payload: customer,
})

export const updateCustomerSuccess = customer => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: customer,
})

export const updateCustomerFail = error => ({
  type: UPDATE_CUSTOMER_FAIL,
  payload: error,
})

export const deleteCustomer = Customer => ({
  type: DELETE_CUSTOMER,
  payload: Customer,
})

export const deleteCustomerSuccess = customer => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: customer,
})

export const deleteCustomerFail = error => ({
  type: DELETE_CUSTOMER_FAIL,
  payload: error,
})

// Contact

export const getContacts = () => ({
  type: GET_CONTACTS,
})

export const getContactsSuccess = contacts => ({
  type: GET_CONTACTS_SUCCESS,
  payload: contacts,
})

export const getContactsFail = error => ({
  type: GET_CONTACTS_FAIL,
  payload: error,
})

export const addNewContact = contact => ({
  type: ADD_NEW_CONTACT,
  payload: contact,
})

export const addContactsSuccess = contact => ({
  type: ADD_CONTACT_SUCCESS,
  payload: contact,
})

export const addContactsFail = error => ({
  type: ADD_CONTACT_FAIL,
  payload: error,
})

export const updateContact = contact => ({
  type: UPDATE_CONTACT,
  payload: contact,
})

export const updateContactSuccess = contact => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: contact,
})

export const updateContactFail = error => ({
  type: UPDATE_CONTACT_FAIL,
  payload: error,
})

export const deleteContact = contact => ({
  type: DELETE_CONTACT,
  payload: contact,
})

export const deleteContactSuccess = contact => ({
  type: DELETE_CONTACT_SUCCESS,
  payload: contact,
})

export const deleteContactFail = error => ({
  type: DELETE_CONTACT_FAIL,
  payload: error,
})

export const openDetail = modal => ({
  type: OPEN_DETAIL,
  payload: modal,
})

export const closeDetail = () => ({
  type: CLOSE_DETAIL,
})

// Detail

export const getDetailCusContacts = id => ({
  type: GET_CUSTOMER_CONTACT,
  payload: id,
})

export const getDetailCusContactSuccess = detailCusContacts => ({
  type: GET_CUSTOMER_CONTACT_SUCCESS,
  payload: detailCusContacts,
})

export const getDetailCusContactFail = error => ({
  type: GET_CUSTOMER_CONTACT_FAIL,
  payload: error,
})

export const getDetailCusSoftServices = id => ({
  type: GET_CUSTOMER_SOFT_SERVICE,
  payload: id,
})

export const getDetailCusSoftServiceSuccess = detailCusSofts => ({
  type: GET_CUSTOMER_SOFT_SERVICE_SUCCESS,
  payload: detailCusSofts,
})

export const getDetailCusSoftServiceFail = error => ({
  type: GET_CUSTOMER_SOFT_SERVICE_FAIL,
  payload: error,
})

export const getDetailCusHardServices = id => ({
  type: GET_CUSTOMER_HARD_SERVICE,
  payload: id,
})

export const getDetailCusHardServiceSuccess = detailHards => ({
  type: GET_CUSTOMER_HARD_SERVICE_SUCCESS,
  payload: detailHards,
})

export const getDetailCusHardServiceFail = error => ({
  type: GET_CUSTOMER_HARD_SERVICE_FAIL,
  payload: error,
})
