import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getUsers = () => get(url.GET_USERS)
const addNewUser = (data) => post(url.GET_USERS, data)
const updateUser = (data) => post(url.GET_USERS, data)
const deleteUser = (data) => post(url.GET_USERS, data)
const updateUserPassword = (data) => post(url.CHANGE_USER_PASSWORD, data)


export {
    getUsers,
    addNewUser,
    updateUser,
    deleteUser,
    updateUserPassword
}
