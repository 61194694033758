/* DEVICE_TYPES */
export const GET_DEVICE_TYPES = "GET_DEVICE_TYPES"
export const GET_DEVICE_TYPES_SUCCESS = "GET_DEVICE_TYPES_SUCCESS"
export const GET_DEVICE_TYPES_FAIL = "GET_DEVICE_TYPES_FAIL"

export const ADD_NEW_DEVICE_TYPE = "ADD_NEW_DEVICE_TYPE"
export const ADD_DEVICE_TYPE_SUCCESS = "ADD_DEVICE_TYPE_SUCCESS"
export const ADD_DEVICE_TYPE_FAIL = "ADD_DEVICE_TYPE_FAIL"

export const UPDATE_DEVICE_TYPE = "UPDATE_DEVICE_TYPE"
export const UPDATE_DEVICE_TYPE_SUCCESS = "UPDATE_DEVICE_TYPE_SUCCESS"
export const UPDATE_DEVICE_TYPE_FAIL = "UPDATE_DEVICE_TYPE_FAIL"

export const DELETE_DEVICE_TYPE = "DELETE_DEVICE_TYPE"
export const DELETE_DEVICE_TYPE_SUCCESS = "DELETE_DEVICE_TYPE_SUCCESS"
export const DELETE_DEVICE_TYPE_FAIL = "DELETE_DEVICE_TYPE_FAIL"
