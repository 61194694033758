import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  GET_USER_ASSIGN_FORM,
  ADD_NEW_USER_ROLE,
  ADD_USER_ASSIGN,
  DELETE_USER_ASSIGN,

  // OLD
  DELETE_USER_ROLE,
  GET_USER_ROLE,
  UPDATE_USER_ROLE,
} from "./actionTypes"
import {
  getUserRoleSuccess,
  getUserRoleFail,
  getUserAssignFormSuccess,
  getUserAssignFormFail,

  addUserAssignSuccess,
  addUserAssignFail,

  deleteUserAssignSuccess,
  deleteUserAssignFail,

  // OLD
  addUserRoleFail,
  addUserRoleSuccess,
  updateUserRoleSuccess,
  updateUserRoleFail,
  deleteUserRoleSuccess,
  deleteUserRoleFail,
  addUserAssign,
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getUserAssignForm,
  getUserAssignRole,
  addUserAssignRole,
  deleteUserAssignRole
} from "../../helpers/backend_api/user_roles"

function* fetchUserAssignForm({ payload: role_id }) {
  try {
    const response = yield call(getUserAssignForm, role_id)

    if (response.code === 200) {
      yield put(getUserAssignFormSuccess(response.message))
    }
    else {
      yield put(getUserAssignFormFail(response))
    }
  } catch (error) {
    yield put(getUserAssignFormFail(error))
  }
}

function* fetchUserRole({ payload: role_id }) {
  try {
    const response = yield call(getUserAssignRole, role_id)
    if (response.code === 200) {
      yield put(getUserRoleSuccess(response.message))
    }
    else {
      yield put(getUserRoleFail(response))
    }
  } catch (error) {
    yield put(getUserRoleFail(error))
  }
}

function* onAddUserAssign({ payload: user }) {
  try {
    const response = yield call(addUserAssignRole, user)

    if (response.code === 201) {
      yield call(fetchUserRole, { payload: user.message.user_role })
      yield put(closeModalAdd())
    } else {
      yield put(addUserRoleFail(response))
    }
  } catch (error) {
    yield put(addUserRoleFail(error))
  }
}

function* onDeleteUserAssign({ payload: user }) {
  try {
    const response = yield call(deleteUserAssignRole, user)
    if (response.code === 200) {
      yield put(deleteUserAssignSuccess(response.message))
    }
    else {
      yield put(deleteUserAssignFail(response))
    }
    yield put(deleteUserAssignFail(response))
  } catch (error) {
    yield put(deleteUserAssignFail(error))
  }
}

function* onDeleteUserRole({ payload: user_role }) {
  try {
    const response = yield call(deleteUserAssignRole, user_role)
    if (response.code === 200) {
      yield call(fetchUserRole, user_role)
    } else {
      yield put(deleteUserRoleFail(response))
    }
  } catch (error) {
    yield put(deleteUserRoleFail(error))
  }
}

function* userRoleSaga() {
  yield takeEvery(GET_USER_ASSIGN_FORM, fetchUserAssignForm)
  yield takeEvery(GET_USER_ROLE, fetchUserRole)

  yield takeEvery(ADD_USER_ASSIGN, onAddUserAssign)
  yield takeEvery(DELETE_USER_ASSIGN, onDeleteUserAssign)

  // OLD
  // yield takeEvery(UPDATE_EVENT, onUpdateEvent)
  yield takeEvery(DELETE_USER_ROLE, onDeleteUserRole)
}

export default userRoleSaga
