import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_PACKAGE_GROUP,
  DELETE_PACKAGE_GROUP,
  GET_PACKAGE_GROUPS,
  UPDATE_PACKAGE_GROUP,
} from "./actionTypes"
import {
  getPackageGroupsSuccess,
  getPackageGroupsFail,
  addPackageGroupFail,
  addPackageGroupSuccess,
  updatePackageGroupSuccess,
  updatePackageGroupFail,
  deletePackageGroupSuccess,
  deletePackageGroupFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPackageGroups,
  addNewPackageGroup
} from "../../helpers/backend_api/packageGroups"

function* fetchPackageGroups() {
  try {
    const response = yield call(getPackageGroups)
    if (response.code === 200) {
      yield put(getPackageGroupsSuccess(response.message))
    }
    else {
      yield put(getPackageGroupsFail(error))
    }
  } catch (error) {
    yield put(getPackageGroupsFail(error))
  }
}

function* onAddNewPackageGroup({ payload: packageGroup }) {
  try {
    const response = yield call(addNewPackageGroup, packageGroup)
    yield put(addPackageGroupSuccess(response))
  } catch (error) {
    yield put(addPackageGroupFail(error))
  }
}

function* onUpdateEvent({ payload: event }) {
  try {
    // const response = yield call(updateEvent, event)
    // yield put(updateEventSuccess(response))
  } catch (error) {
    yield put(updateEventFail(error))
  }
}

function* onDeleteEvent({ payload: event }) {
  try {
    // const response = yield call(deleteEvent, event)
    // yield put(deleteEventSuccess(response))
  } catch (error) {
    yield put(deleteEventFail(error))
  }
}

function* packageGroupSaga() {
  yield takeEvery(GET_PACKAGE_GROUPS, fetchPackageGroups)
  yield takeEvery(ADD_NEW_PACKAGE_GROUP, onAddNewPackageGroup)
  // yield takeEvery(UPDATE_EVENT, onUpdateEvent)
  // yield takeEvery(DELETE_EVENT, onDeleteEvent)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default packageGroupSaga
