import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getCustomers = () => get(url.GET_CUSTOMERS)
const addNewCustomer = data => post(url.GET_CUSTOMERS, data)
const updateCustomer = data => post(url.GET_CUSTOMERS, data)
const deleteCustomer = data => post(url.GET_CUSTOMERS, data)

// Contact
const getContacts = () => get(url.GET_CONTACTS)
const addNewContact = data => post(url.GET_CONTACTS, data)
const updateContact = data => post(url.GET_CONTACTS, data)
const deleteContact = data => post(url.GET_CONTACTS, data)

const getDetailCusContact = id => get(`${url.GET_CUSTOMER_CONTACT}/${id}`)
const getDetailCusSoftService = id =>
  get(`${url.GET_CUSTOMER_SOFT_SERVICE}/${id}`)
const getDetailCusHardService = id =>
  get(`${url.GET_CUSTOMER_HARD_SERVICE}/${id}`)
export {
  getCustomers,
  addNewCustomer,
  updateCustomer,
  deleteCustomer,
  //   Contact
  addNewContact,
  getContacts,
  updateContact,
  deleteContact,
  getDetailCusContact,
  getDetailCusSoftService,
  getDetailCusHardService,
}
