import {
  GET_SOFTWAREPACKAGES,
  GET_SOFTWAREPACKAGES_FAIL,
  GET_SOFTWAREPACKAGES_SUCCESS,
  ADD_NEW_SOFTWAREPACKAGE,
  ADD_SOFTWAREPACKAGE_SUCCESS,
  ADD_SOFTWAREPACKAGE_FAIL,
  UPDATE_SOFTWAREPACKAGE,
  UPDATE_SOFTWAREPACKAGE_SUCCESS,
  UPDATE_SOFTWAREPACKAGE_FAIL,
  DELETE_SOFTWAREPACKAGE,
  DELETE_SOFTWAREPACKAGE_SUCCESS,
  DELETE_SOFTWAREPACKAGE_FAIL,
} from "./actionTypes"

export const getSoftwarePackages = () => ({
  type: GET_SOFTWAREPACKAGES,
})

export const getSoftwarePackagesSuccess = softwarepackages => ({
  type: GET_SOFTWAREPACKAGES_SUCCESS,
  payload: softwarepackages,
})

export const getSoftwarePackagesFail = error => ({
  type: GET_SOFTWAREPACKAGES_FAIL,
  payload: error,
})

export const addNewSoftwarePackage = softwarepackage => ({
  type: ADD_NEW_SOFTWAREPACKAGE,
  payload: softwarepackage,
})

export const addSoftwarePackageSuccess = softwarepackage => ({
  type: ADD_SOFTWAREPACKAGE_SUCCESS,
  payload: softwarepackage,
})

export const addSoftwarePackageFail = error => ({
  type: ADD_SOFTWAREPACKAGE_FAIL,
  payload: error,
})

export const onUpdateSoftwarePackage = softwarepackage => ({
  type: UPDATE_SOFTWAREPACKAGE,
  payload: softwarepackage,
})

export const updateSoftwarePackageSuccess = softwarepackage => ({
  type: UPDATE_SOFTWAREPACKAGE_SUCCESS,
  payload: softwarepackage,
})

export const updateSoftwarePackageFail = error => ({
  type: UPDATE_SOFTWAREPACKAGE_FAIL,
  payload: error,
})

export const deleteSoftwarePackage = softwarepackage => ({
  type: DELETE_SOFTWAREPACKAGE,
  payload: softwarepackage,
})

export const deleteSoftwarePackageSuccess = softwarepackage => ({
  type: DELETE_SOFTWAREPACKAGE_SUCCESS,
  payload: softwarepackage,
})

export const deleteSoftwarePackageFail = error => ({
  type: DELETE_SOFTWAREPACKAGE_FAIL,
  payload: error,
})