import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Checkbox, Divider } from "antd";
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
import {
    closeModalDelete,
    deleteRole
} from "../../store/actions"
import _ from "lodash";

const CheckboxGroup = Checkbox.Group;

const RoleType = (props) => {

    const { permissions, callBackPermission, permissionAssign } = props;
    const [permissionFlat, setPermissionFlat] = useState([]);
    const [permissionCheck, setPermissionCheck] = useState([]);

    useEffect(() => {
        let groups = _.groupBy(permissions, 'group');
        let newPermissions = [];
        Object.keys(groups).map((key) => {
            let labels = _.groupBy(groups[key], 'label');
            newPermissions.push({
                'group': key,
                'labels': labels
            });
        })
        setPermissionFlat(newPermissions);
        if (!_.isEmpty(permissionAssign)) {
            setPermissionCheck(permissionAssign);
        }
    }, [setPermissionFlat, permissionAssign]);

    const onChangeAction = (e) => {
        if (e.target.checked) {
            let checked = permissions.filter(permission => permission.id === e.target.value);
            let mergeChecked = checked.concat(permissionCheck);

            var non_duplidated_data = _.uniqBy(mergeChecked, 'id');

            setPermissionCheck(non_duplidated_data);
            callBackPermission(non_duplidated_data);
        } else {
            let unChecked = permissionCheck.filter(permission => permission.id !== e.target.value);
            setPermissionCheck(unChecked);
            callBackPermission(unChecked);
        }
    }

    const onCheckLabel = e => {
        if (e.target.checked) {
            let checked = permissions.filter(permission => permission.label === e.target.value);
            let mergeChecked = checked.concat(permissionCheck);

            var non_duplidated_data = _.uniqBy(mergeChecked, 'id');

            setPermissionCheck(non_duplidated_data);
            callBackPermission(non_duplidated_data);

        } else {
            let unChecked = permissionCheck.filter(permission => permission.label !== e.target.value);
            setPermissionCheck(unChecked);
            callBackPermission(unChecked);

        }
    }

    const onCheckAllChange = (e) => {
        if (e.target.checked) {
            let checked = permissions.filter(permission => permission.group === e.target.value);
            let mergeChecked = checked.concat(permissionCheck);

            var non_duplidated_data = _.uniqBy(mergeChecked, 'id');
            setPermissionCheck(non_duplidated_data);
            callBackPermission(non_duplidated_data);

        } else {
            let unChecked = permissionCheck.filter(permission => permission.group !== e.target.value);
            setPermissionCheck(unChecked);
            callBackPermission(unChecked);
        }
    }

    const objectHasGroup = (group, type) => {
        let total_permissions = permissions.filter(permission => permission.group === group);
        total_permissions = total_permissions.length;
        let total_check = permissionCheck.filter(permission => permission.group === group);
        total_check = total_check.length;
        if (type === 'indeterminate') {
            if (total_check === 0) {
                return false;
            }
            else if (total_check < total_permissions) {
                return true;
            }
        } else if (type === 'check') {
            if (total_check === total_permissions) {
                return true;
            } else if (total_check < total_permissions) {
                return false;
            }
        }

    }

    const objectHasLabel = (label, type) => {
        let total_permissions = permissions.filter(permission => permission.label === label);
        total_permissions = total_permissions.length;
        let total_check = permissionCheck.filter(permission => permission.label === label);
        total_check = total_check.length;

        if (type === 'indeterminate') {
            if (total_check === 0) {
                return false;
            }
            else if (total_check < total_permissions) {
                return true;
            }
        } else if (type === 'check') {
            if (total_check === total_permissions) {
                return true;
            } else if (total_check < total_permissions) {
                return false;
            }
        }
    }

    return (
        <>
            {permissionFlat && permissionFlat.map((permission, idx) => (
                <div key={idx}>
                    <div className="bg-light p-2">
                        <Checkbox
                            indeterminate={
                                objectHasGroup(permission.group, 'indeterminate')
                            }
                            value={permission.group}
                            onChange={onCheckAllChange}
                            checked={
                                objectHasGroup(permission.group, 'check')
                            }
                        >
                            {permission.group}
                        </Checkbox>
                    </div>

                    <div className="p-2">
                        {Object.keys(permission.labels).map((label, index_1) => (
                            <div className="row" key={index_1}>
                                <div className="col-xl-3 p-2">
                                    <Checkbox
                                        indeterminate={
                                            objectHasLabel(label, 'indeterminate')
                                        }
                                        value={label}
                                        onChange={onCheckLabel}
                                        checked={
                                            objectHasLabel(label, 'check')
                                        }
                                    >
                                        {label}
                                    </Checkbox>
                                </div>
                                <div className="col-xl-9 p-2">
                                    {permission.labels[label].map((action, index_2) => (
                                        <Checkbox
                                            key={index_2}
                                            value={action.id}
                                            onChange={onChangeAction}
                                            checked={
                                                permissionCheck.find(perm => {
                                                    return action.id === perm.id
                                                })
                                            }
                                        >
                                            {action.action}
                                        </Checkbox>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )

            )}
        </>
    )
}

RoleType.propTypes = {
    t: PropTypes.any,
    roles: PropTypes.array,
    onOpenModalEdit: PropTypes.func,
    callBackPermission: PropTypes.func,
    permissionAssign: PropTypes.array
}

const mapStateToProps = ({ role }) => ({
    roles: role.roles,
    permissions: role.constraints
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onDeleteRole: (message) => dispatch(deleteRole(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleType);

