import {
  GET_SERVICE_TYPES,
  GET_SERVICE_TYPES_FAIL,
  GET_SERVICE_TYPES_SUCCESS,
  ADD_NEW_SERVICE_TYPE,
  ADD_SERVICE_TYPE_SUCCESS,
  ADD_SERVICE_TYPE_FAIL,
  UPDATE_SERVICE_TYPE,
  UPDATE_SERVICE_TYPE_SUCCESS,
  UPDATE_SERVICE_TYPE_FAIL,
  DELETE_SERVICE_TYPE,
  DELETE_SERVICE_TYPE_SUCCESS,
  DELETE_SERVICE_TYPE_FAIL,
} from "./actionTypes"

export const getServiceTypes = () => ({
  type: GET_SERVICE_TYPES,
})

export const getServiceTypesSuccess = serviceTypes => ({
  type: GET_SERVICE_TYPES_SUCCESS,
  payload: serviceTypes,
})

export const getServiceTypesFail = error => ({
  type: GET_SERVICE_TYPES_FAIL,
  payload: error,
})

export const addNewServiceType = serviceType => ({
  type: ADD_NEW_SERVICE_TYPE,
  payload: serviceType,
})

export const addServiceTypeSuccess = serviceType => ({
  type: ADD_SERVICE_TYPE_SUCCESS,
  payload: serviceType,
})

export const addServiceTypeFail = error => ({
  type: ADD_SERVICE_TYPE_FAIL,
  payload: error,
})

export const updateServiceType = serviceType => ({
  type: UPDATE_SERVICE_TYPE,
  payload: serviceType,
})

export const updateServiceTypeSuccess = serviceType => ({
  type: UPDATE_SERVICE_TYPE_SUCCESS,
  payload: serviceType,
})

export const updateServiceTypeFail = error => ({
  type: UPDATE_SERVICE_TYPE_FAIL,
  payload: error,
})

export const deleteServiceType = serviceType => ({
  type: DELETE_SERVICE_TYPE,
  payload: serviceType,
})

export const deleteServiceTypeSuccess = serviceType => ({
  type: DELETE_SERVICE_TYPE_SUCCESS,
  payload: serviceType,
})

export const deleteServiceTypeFail = error => ({
  type: DELETE_SERVICE_TYPE_FAIL,
  payload: error,
})