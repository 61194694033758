import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getSoftwarePackages = () => get(url.GET_SOFTWAREPACKAGES)
const addNewSoftwarePackage = (data) => post(url.GET_SOFTWAREPACKAGES, data)
const updateSoftwarePackage = (data) => post(url.GET_SOFTWAREPACKAGES, data)
const deleteSoftwarePackage = (data) => post(url.GET_SOFTWAREPACKAGES, data)

export {
    getSoftwarePackages,
    addNewSoftwarePackage,
	updateSoftwarePackage,
	deleteSoftwarePackage
}
