import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_DETAIL, CHANGE_OPEN_MODAL_EDIT, CHANGE_OPEN_MODAL_TRANSFER } from 'store/helper/actionTypes';
import {
    deleteRole
} from "../../store/actions"
import ShowPermission from './ShowPermission';
import AssignToUser from './AssignRole/AssignToUser';
import Permission from 'permissions/Permission';
const RoleTable = (props) => {

    const columns = [
        {
            title: 'Tên vai trò',
            width: 100,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Được chỉ định',
            dataIndex: 'is_assignable',
            key: 'is_assignable',
            width: 100,
        },
        {
            title: 'Được định nghĩa trước',
            dataIndex: 'is_predefined',
            key: 'is_predefined',
            width: 100,
        },
        {
            title: 'Quyền',
            dataIndex: 'roles',
            key: 'roles',
            width: 100,
            render: (text, record, index) => <>
                <button type="button" onClick={() => handleShow(record.roles)} className="btn btn-outline-info btn-sm waves-effect waves-light me-1"><i className="fas fa-eye"></i></button>
            </>,
        },
        {
            title: 'Phân bổ vai trò',
            dataIndex: 'transfer',
            key: 'transfer',
            width: 100,
            render: (text, record, index) => <>
                <Permission myRole={myRole} permission={{ label: 'user role', action: "update" }}>
                    <button type="button" onClick={() => handleTransfer(record)} className="btn btn-outline-info btn-sm waves-effect waves-light me-1"><i className="fas fa-exchange-alt"></i></button>
                </Permission>
            </>,
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
            width: 150,
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 50,
            render: (text, record, index) => <>
                <Permission myRole={myRole} permission={{ label: 'user role', action: "update" }}>
                    <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                </Permission>

                <Permission myRole={myRole} permission={{ label: 'user role', action: "delete" }}>
                    <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
                </Permission>
            </>,
        },
    ]

    const { roles, onOpenModalEdit, onOpenModalTransfer, onOpenModalShowPerm, onDeleteRole, keyword, myRole } = props;
    const [dataRow, setDataRow] = useState({});
    const [permissionChecked, setPermissionChecked] = useState([]);
    const [roleList, setRoles] = useState([]);
    const [roleId, setRoleId] = useState('');

    const handleShow = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Xem chi tiết quyền"
        }
        onOpenModalShowPerm(modal)
        setPermissionChecked(row)
    }

    const handleTransfer = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Phân bổ vai trò"
        }
        onOpenModalTransfer(modal)
        setRoleId(row.key)
    }

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật vai trò"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        if (keyword) {
            const filteredData = roleList.filter(entry =>
                (entry?.name).toLowerCase().includes(keyword.toLowerCase())
            );
            setRoles(filteredData);
        } else {
            let data = [];
            roles?.map(role => {
                data.push({
                    key: role.id,
                    name: role.name,
                    description: role.description,
                    is_assignable: role.is_assignable,
                    is_predefined: role.is_predefined,
                    roles: role.roles
                })
            });
            setRoles(data)
        }

    }, [roles, keyword])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá vai trò "${row.name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                let newData = {
                    id: row.key,
                };
                let message = {
                    'action': 'delete',
                    'message': newData,
                    "type_message": "map"
                }
                onDeleteRole(message);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <Edit dataRow={dataRow}></Edit>
            <ShowPermission permissionChecked={permissionChecked}></ShowPermission>
            <AssignToUser role_id={roleId}></AssignToUser>

            <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                columns={columns}
                size={'small'}
                dataSource={roleList}
                scroll={{
                    x: 1500,
                }}
            />
        </>

    );

};

RoleTable.propTypes = {
    t: PropTypes.any,
    roles: PropTypes.array,
    onOpenModalEdit: PropTypes.func,
    onOpenModalShowPerm: PropTypes.func,
    onOpenModalTransfer: PropTypes.func,
    keyword: PropTypes.string,
    myRole: PropTypes.array
}

const mapStateToProps = ({ role, Login }) => ({
    roles: role.roles,
    myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onOpenModalTransfer: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_TRANSFER, payload: modal }),
    onOpenModalShowPerm: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_DETAIL, payload: modal }),
    onDeleteRole: (message) => dispatch(deleteRole(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleTable);