/* PACKAGE_GROUPS */
export const GET_PACKAGE_GROUPS = "GET_PACKAGE_GROUPS"
export const GET_PACKAGE_GROUPS_SUCCESS = "GET_PACKAGE_GROUPS_SUCCESS"
export const GET_PACKAGE_GROUPS_FAIL = "GET_PACKAGE_GROUPS_FAIL"

export const ADD_NEW_PACKAGE_GROUP = "ADD_NEW_PACKAGE_GROUP"
export const ADD_PACKAGE_GROUP_SUCCESS = "ADD_PACKAGE_GROUP_SUCCESS"
export const ADD_PACKAGE_GROUP_FAIL = "ADD_PACKAGE_GROUP_FAIL"

export const UPDATE_PACKAGE_GROUP = "UPDATE_PACKAGE_GROUP"
export const UPDATE_PACKAGE_GROUP_SUCCESS = "UPDATE_PACKAGE_GROUP_SUCCESS"
export const UPDATE_PACKAGE_GROUP_FAIL = "UPDATE_PACKAGE_GROUP_FAIL"

export const DELETE_PACKAGE_GROUP = "DELETE_PACKAGE_GROUP"
export const DELETE_PACKAGE_GROUP_SUCCESS = "DELETE_PACKAGE_GROUP_SUCCESS"
export const DELETE_PACKAGE_GROUP_FAIL = "DELETE_PACKAGE_GROUP_FAIL"
