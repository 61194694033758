import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardHeader,
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"

//i18n
import { withTranslation } from "react-i18next"
import SoftwareServiceTable from "./SoftwareServiceTable"
import { Input as InputAnt, Button as ButtonAnt } from "antd"

import {
  getCustomers,
  getSoftwarePackages,
  getSoftwareServices,
} from "../../store/actions"
import { connect } from "react-redux"
import { get } from "lodash"
import { CHANGE_OPEN_MODAL_ADD } from "store/helper/actionTypes"
import Add from "./Add"
import Permission from "permissions/Permission"

const SoftwareServices = props => {
  const {
    onGetCustomers,
    onGetSoftwareServices,
    onOpenModalAdd,
    onGetSoftwarePackages,
    myRole
  } = props

  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    onGetSoftwareServices()
    onGetCustomers()
    onGetSoftwarePackages()
  }, [onGetSoftwareServices])

  const handleAdd = () => {
    let modal = {
      isOpenModal: true,
      title: "Thêm mới dịch vụ phần mềm",
    }
    onOpenModalAdd(modal)
  }

  const onSearch = (e) => {
    setKeyword(e.target.value);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dịch vụ phần mềm</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={12}>
                <h6 className="page-title">Dịch vụ phần mềm</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">Dịch vụ khách hàng</li>
                  <li className="breadcrumb-item active">Dịch vụ phần mềm</li>
                </ol>
              </Col>
            </Row>
          </div>
          {/* Table here */}
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="h4">
                        Danh sách dịch vụ phần mềm
                      </CardTitle>
                      <p className="card-title-desc"></p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <div className="mb-2">
                        <Permission myRole={myRole} permission={{ label: 'software service', action: "create" }}>
                          <ButtonAnt
                            type="primary"
                            className="me-1"
                            onClick={() => handleAdd()}
                          >
                            + Thêm mới
                          </ButtonAnt>
                          <Add></Add>
                        </Permission>

                        {/* <ButtonAnt className="me-1">
                          <i className="fas fa-filter text-primary"></i>
                        </ButtonAnt> */}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="float-end mb-2">
                        <InputAnt
                          placeholder="search..."
                          onChange={onSearch}
                          style={{
                            width: 200,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <SoftwareServiceTable keyword={keyword}></SoftwareServiceTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SoftwareServices.propTypes = {
  t: PropTypes.any,
  softwareservices: PropTypes.array,
  onGetSoftwareServices: PropTypes.func,
  onOpenModalAdd: PropTypes.func,
}

const mapStateToProps = ({ softwareservice, Login }) => ({
  softwareservices: softwareservice.softwareservices,
  myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
  onGetSoftwareServices: () => dispatch(getSoftwareServices()),
  onGetSoftwarePackages: () => dispatch(getSoftwarePackages()),
  onGetCustomers: () => dispatch(getCustomers()),
  onOpenModalAdd: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_ADD, payload: modal }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SoftwareServices))
