/* HELPER  */
export const OPEN_MODAL_ADD = "OPEN_MODAL_ADD"
export const CLOSE_MODAL_ADD = "CLOSE_MODAL_ADD"
export const CHANGE_OPEN_MODAL_ADD = "CHANGE_OPEN_MODAL_ADD"
export const CHANGE_CLOSE_MODAL_ADD = "CHANGE_CLOSE_MODAL_ADD"

export const OPEN_MODAL_EDIT = "OPEN_MODAL_EDIT"
export const CLOSE_MODAL_EDIT = "CLOSE_MODAL_EDIT"
export const CHANGE_OPEN_MODAL_EDIT = "CHANGE_OPEN_MODAL_EDIT"
export const CHANGE_CLOSE_MODAL_EDIT = "CHANGE_CLOSE_MODAL_EDIT"

export const OPEN_MODAL_DETAIL = "OPEN_MODAL_DETAIL"
export const CLOSE_MODAL_DETAIL = "CLOSE_MODAL_DETAIL"
export const CHANGE_OPEN_MODAL_DETAIL = "CHANGE_OPEN_MODAL_DETAIL"
export const CHANGE_CLOSE_MODAL_DETAIL = "CHANGE_CLOSE_MODAL_DETAIL"

export const OPEN_MODAL_TRANSFER = "OPEN_MODAL_TRANSFER"
export const CLOSE_MODAL_TRANSFER = "CLOSE_MODAL_TRANSFER"
export const CHANGE_OPEN_MODAL_TRANSFER = "CHANGE_OPEN_MODAL_TRANSFER"
export const CHANGE_CLOSE_MODAL_TRANSFER = "CHANGE_CLOSE_MODAL_TRANSFER"
