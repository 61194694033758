import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getUserAssignForm = () => get(`${url.GET_USER_ASSIGN_FORM}`)

const getUserAssignRole = (role_id) => get(`${url.GET_USER_ASSIGN}/${role_id}`)

const addUserAssignRole = (data) => post(url.GET_USER_ASSIGN_ROLE, data)
const deleteUserAssignRole = (data) => post(url.GET_USER_ASSIGN_ROLE, data)

export {
    getUserAssignForm,
    getUserAssignRole,
    addUserAssignRole,
    deleteUserAssignRole,
}
