import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getDeviceTypes = () => get(url.GET_DEVICE_TYPES)
const addNewDeviceType = (data) => post(url.GET_DEVICE_TYPES, data)
const updateDeviceType = (data) => post(url.GET_DEVICE_TYPES, data)
const deleteDeviceType = (data) => post(url.GET_DEVICE_TYPES, data)

export {
    getDeviceTypes,
    addNewDeviceType,
    updateDeviceType,
    deleteDeviceType
}
