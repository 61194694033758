import {
  GET_PRODUCTCOMPONENTS_SUCCESS,
  GET_PRODUCTCOMPONENTS_FAIL,
  ADD_PRODUCTCOMPONENT_SUCCESS,
  ADD_PRODUCTCOMPONENT_FAIL,
  UPDATE_PRODUCTCOMPONENT_SUCCESS,
  UPDATE_PRODUCTCOMPONENT_FAIL,
  DELETE_PRODUCTCOMPONENT_SUCCESS,
  DELETE_PRODUCTCOMPONENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  productcomponents: [],
  productcomponent: {},
  error: {},
}

const ProductComponent = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTCOMPONENTS_SUCCESS:
      return {
        ...state,
        productcomponents: action.payload,
      }

    case GET_PRODUCTCOMPONENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PRODUCTCOMPONENT_SUCCESS:
      return {
        ...state,
        productcomponents: [...state.productcomponents, action.payload],
      }

    case ADD_PRODUCTCOMPONENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PRODUCTCOMPONENT_SUCCESS:
      return {
        ...state,
        productcomponents: state.productcomponents.map(productcomponent =>
          productcomponent.id.toString() === action.payload.id.toString()
            ? { productcomponent, ...action.payload }
            : productcomponent
        ),
      }

    case UPDATE_PRODUCTCOMPONENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PRODUCTCOMPONENT_SUCCESS:
      return {
        ...state,
        productcomponents: state.productcomponents.filter(
          productcomponent => productcomponent.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PRODUCTCOMPONENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default ProductComponent
