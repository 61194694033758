import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalDetail,
} from "../../store/actions"

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table
} from "reactstrap"
import { useForm } from 'react-hook-form';

const ShowPackage = (props) => {
    const { modalDetail, onCloseModalDetail, onEditUser, packages } = props;
    const handleCancel = () => {
        onCloseModalDetail();
    };


    return (
        <>
            <Modal isOpen={modalDetail.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <ModalHeader toggle={handleCancel}>{modalDetail.title}</ModalHeader>
                <ModalBody>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Tên nhóm dịch vụ</th>
                                <th>Chi tiết nhóm dịch vụ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packages.length > 0 && packages.map((pack, i) =>
                                <tr key={i}>
                                    <td>{pack.name}</td>
                                    <td>
                                        {pack.packages.length > 0 && pack.packages.map((sub, y) =>
                                            <div key={y}>
                                                - {sub.package_name}
                                                <br />
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            )}


                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <ButtonAnt onClick={handleCancel}>
                        Đóng
                    </ButtonAnt>
                </ModalFooter>
            </Modal>
        </>
    );
};
ShowPackage.propTypes = {
    t: PropTypes.any,
    modalDetail: PropTypes.object,
    packages: PropTypes.array,
}

const mapStateToProps = ({ user, helper }) => ({
    modalDetail: helper.modalDetail
})

const mapDispatchToProps = dispatch => ({
    onCloseModalDetail: () => dispatch(closeModalDetail()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowPackage);