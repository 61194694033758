import {
  GET_SOFTWAREPACKAGES_SUCCESS,
  GET_SOFTWAREPACKAGES_FAIL,
  ADD_SOFTWAREPACKAGE_SUCCESS,
  ADD_SOFTWAREPACKAGE_FAIL,
  UPDATE_SOFTWAREPACKAGE_SUCCESS,
  UPDATE_SOFTWAREPACKAGE_FAIL,
  DELETE_SOFTWAREPACKAGE_SUCCESS,
  DELETE_SOFTWAREPACKAGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  softwarepackages: [],
  softwarepackage: {},
  error: {},
}

const SoftwarePackage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SOFTWAREPACKAGES_SUCCESS:
      return {
        ...state,
        softwarepackages: action.payload,
      }

    case GET_SOFTWAREPACKAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_SOFTWAREPACKAGE_SUCCESS:
      return {
        ...state,
        softwarepackages: [...state.softwarepackages, action.payload],
      }

    case ADD_SOFTWAREPACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_SOFTWAREPACKAGE_SUCCESS:
      return {
        ...state,
        softwarepackages: state.softwarepackages.map(softwarepackage =>
          softwarepackage.id.toString() === action.payload.id.toString()
            ? { softwarepackage, ...action.payload }
            : softwarepackage
        ),
      }

    case UPDATE_SOFTWAREPACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SOFTWAREPACKAGE_SUCCESS:
      return {
        ...state,
        softwarepackages: state.softwarepackages.filter(
          softwarepackage => softwarepackage.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_SOFTWAREPACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default SoftwarePackage
