import moment from "moment";
const status = [
    {
        value: 'experiment',
        label: 'Thử nghiệm',
        color: 'primary'
    },
    {
        value: 'suspend',
        label: 'Tạm ngưng',
        color: 'warning'
    },
    {
        value: 'terminate',
        label: 'Chấm dứt',
        color: 'danger'
    },
    {
        value: 'active',
        label: 'Hoạt động',
        color: 'success'
    }
];

const billing_cycle_type = [
    {
        value: 'Quarter',
        label: 'Quý'
    },
    {
        value: 'month',
        label: 'Tháng'
    },
    {
        value: 'year',
        label: 'Năm'
    },
];

const initParam = {
    billing_cycle_type: "month",
    one_time_payment: "No",
    rental_equipment: "No",
    quantity: 1,
    billing_cycle: 1,
    start_date: moment().format('YYYY-MM-DD')
}

export { initParam, status, billing_cycle_type }