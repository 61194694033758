import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getServiceTypes = () => get(url.GET_SERVICE_TYPES)
const addNewServiceType = (data) => post(url.GET_SERVICE_TYPES, data)
const updateServiceType = (data) => post(url.GET_SERVICE_TYPES, data)
const deleteServiceType = (data) => post(url.GET_SERVICE_TYPES, data)

export {
    getServiceTypes,
    addNewServiceType,
    updateServiceType,
    deleteServiceType
}
