import React, { useState, useEffect } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Switch, Checkbox } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalDetail,
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
const ShowPermission = (props) => {
    const { modalDetail, onCloseModalDetail, permissions, permissionChecked } = props;
    const [permissionFlat, setPermissionFlat] = useState([]);
    const [permissionCheck, setPermissionCheck] = useState([]);

    useEffect(() => {
        let groups = _.groupBy(permissions, 'group');
        let newPermissions = [];
        Object.keys(groups).map((key) => {
            let labels = _.groupBy(groups[key], 'label');
            newPermissions.push({
                'group': key,
                'labels': labels
            });
        })
        setPermissionFlat(newPermissions);
        setPermissionCheck(permissionChecked);
    }, [permissionChecked]);

    const objectHasGroup = (group, type) => {
        let total_permissions = permissions.filter(permission => permission.group === group);
        total_permissions = total_permissions.length;
        let total_check = permissionCheck.filter(permission => permission.group === group);
        total_check = total_check.length;
        if (type === 'indeterminate') {
            if (total_check === 0) {
                return false;
            }
            else if (total_check < total_permissions) {
                return true;
            }
        } else if (type === 'check') {
            if (total_check === total_permissions) {
                return true;
            } else if (total_check < total_permissions) {
                return false;
            }
        }

    }

    const objectHasLabel = (label, type) => {
        let total_permissions = permissions.filter(permission => permission.label === label);
        total_permissions = total_permissions.length;
        let total_check = permissionCheck.filter(permission => permission.label === label);
        total_check = total_check.length;

        if (type === 'indeterminate') {
            if (total_check === 0) {
                return false;
            }
            else if (total_check < total_permissions) {
                return true;
            }
        } else if (type === 'check') {
            if (total_check === total_permissions) {
                return true;
            } else if (total_check < total_permissions) {
                return false;
            }
        }
    }

    const handleCancel = () => {
        onCloseModalDetail();
    };


    return (
        <>
            {/* {console.log(permissionChecked)} */}
            <Modal isOpen={modalDetail.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <ModalHeader toggle={handleCancel}>{modalDetail.title}</ModalHeader>
                <ModalBody>

                    <Container>
                        {permissionFlat && permissionFlat.map((permission, idx) => (
                            <div key={idx}>
                                <div className="bg-light p-2">
                                    <Checkbox

                                        indeterminate={
                                            objectHasGroup(permission.group, 'indeterminate')
                                        }
                                        value={permission.group}
                                        checked={
                                            objectHasGroup(permission.group, 'check')
                                        }
                                    >
                                        {permission.group}
                                    </Checkbox>
                                </div>

                                <div className="p-2">
                                    {Object.keys(permission.labels).map((label, index_1) => (
                                        <div className="row" key={index_1}>
                                            <div className="col-xl-3 p-2">
                                                <Checkbox

                                                    indeterminate={
                                                        objectHasLabel(label, 'indeterminate')
                                                    }
                                                    value={label}
                                                    checked={
                                                        objectHasLabel(label, 'check')
                                                    }
                                                >
                                                    {label}
                                                </Checkbox>
                                            </div>
                                            <div className="col-xl-9 p-2">
                                                {permission.labels[label].map((action, index_2) => (
                                                    <Checkbox
                                                        key={index_2}
                                                        value={action.id}
                                                        checked={
                                                            permissionCheck.find(perm => {
                                                                return action.id === perm.id
                                                            })
                                                        }
                                                    >
                                                        {action.action}
                                                    </Checkbox>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )

                        )}
                    </Container>


                </ModalBody>
                <ModalFooter>
                    <ButtonAnt onClick={handleCancel}>
                        Đóng
                    </ButtonAnt>
                </ModalFooter>
            </Modal>
        </>
    );
};
ShowPermission.propTypes = {
    t: PropTypes.any,
    modalDetail: PropTypes.object,
    onCloseModalDetail: PropTypes.func,
    permissions: PropTypes.array,
    permissionChecked: PropTypes.array
}

const mapStateToProps = ({ role, helper }) => ({
    modalDetail: helper.modalDetail,
    permissions: role.constraints
})

const mapDispatchToProps = dispatch => ({
    onCloseModalDetail: () => dispatch(closeModalDetail()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowPermission);