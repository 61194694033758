import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, InputNumber, Checkbox } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    getServiceTypes,
    addNewServiceType
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Table
} from "reactstrap"
import { useForm } from 'react-hook-form';
import { packageGroups } from "../../common/data/packageGroups"
import { Field } from 'rc-field-form';

const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewServiceType, onGetServiceTypes, serviceTypes } = props;
    const [optionServiceType, setOptionServiceType] = useState([]);
    const [selectedServiceType, setSelectedServiceType] = useState([]);

    const [fields, setFields] = useState({});
    const [myError, setMyErrors] = useState({});
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedSingle, setCheckedSingle] = useState([]);


    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleCancel = () => {
        onCloseModalAdd();
    };

    const arrayRemove = (arr, value) => {
        return arr.filter(function (ele) {
            return ele != value;
        });
    };

    const onChangeAllPackage = (e) => {
        if (e.target.checked) {
            setCheckedAll(true);
            let packChecked = []
            packageGroups.map(pack => {
                packChecked.push(pack.id);
            });
            setCheckedSingle(packChecked);
        } else {
            setCheckedSingle([]);
            setCheckedAll(false);
        }
    }

    const onChangeSinglePackage = (e) => {
        let checkedList = checkedSingle;
        if (e.target.checked) {
            checkedList.push(e.target.value);
            let uniq = [...new Set(checkedList)];
            setCheckedSingle(uniq);
        }
        else {
            checkedList = arrayRemove(checkedList, e.target.value);
            setCheckedSingle(checkedList);
        }
        if (packageGroups.length === checkedList.length) {
            setCheckedAll(true);
        } else {
            setCheckedAll(false);
        }
    }

    useEffect(() => {
        onGetServiceTypes();
    }, [onGetServiceTypes])

    useEffect(() => {
        if (serviceTypes.length > 0) {
            let myServiceTypes = [];
            serviceTypes.map(serviceType => {
                myServiceTypes.push({
                    value: serviceType.id,
                    label: serviceType.name
                })
            })
            setOptionServiceType(myServiceTypes);
        }
    }, [serviceTypes])

    const onSubmit = data => {
        if (validateInput()) {
            fields.package_groups = checkedSingle;

            let message = {
                'action': 'create',
                'message': fields,
                "type_message": "map"
            }
            // onAddNewServiceType(message);
        }

    };

    const handleChange = (value) => {
        let currentFields = fields;
        currentFields["service_type"] = value;
        setFields(currentFields);
        setSelectedServiceType(value);
    }

    const handleInput = (e) => {
        let currentFields = fields;
        currentFields[e.target.name] = e.target.value;
        setFields(currentFields);
    }

    const handleNumber = (name, value) => {
        let currentFields = fields;
        currentFields[name] = value;
        setFields(currentFields);
    }

    const validateInput = () => {
        let currentFields = fields;

        let isValid = true;
        let errs = {};
        if (!currentFields.name) {
            errs.name = {
                type: 'required',
                message: 'Tên dịch vụ không được phép để trống'
            }
            isValid = false;
        }
        if (!currentFields.service_type) {
            errs.service_type = {
                type: 'required',
                message: 'Vui lòng chọn loại dịch vụ'
            }
            isValid = false;
        }
        if (!currentFields.training_online) {
            errs.training_online = {
                type: 'required',
                message: 'Vui lòng nhập số giờ đào tạo từ xa'
            }
            isValid = false;
        }
        if (!currentFields.training_onsite) {
            errs.training_onsite = {
                type: 'required',
                message: 'Vui lòng nhập số giờ đào tạo trực tiếp'
            }
            isValid = false;
        }
        if (!currentFields.inital_fees) {
            errs.inital_fees = {
                type: 'required',
                message: 'Vui lòng nhập phí khởi tạo'
            }
            isValid = false;
        }
        if (!currentFields.number_of_users) {
            errs.number_of_users = {
                type: 'required',
                message: 'Vui lòng nhập số người dùng'
            }
            isValid = false;
        }
        if (!currentFields.storage_per_user) {
            errs.storage_per_user = {
                type: 'required',
                message: 'Vui lòng nhập số người dùng'
            }
            isValid = false;
        }
        setMyErrors(errs);
        return isValid;
    }

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Tên dịch vụ <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            onChange={handleInput}
                                            name='name'
                                            className="form-control"
                                            placeholder='Tên dịch vụ...'
                                        />
                                        {myError.name && myError.name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.name.message}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Loại dịch vụ <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <SelectAnt
                                            showSearch
                                            placeholder="Chọn loại dịch vụ..."
                                            optionFilterProp="children"
                                            onChange={handleChange}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                            options={optionServiceType}
                                        />
                                        {myError.service_type && myError.service_type.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.service_type.message}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Nhóm gói dịch vụ</Label>
                                </Col>
                                <Col md={8}>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th><Checkbox checked={checkedAll} value="all" onChange={onChangeAllPackage}>Tất cả</Checkbox></th>
                                                <th>Chi tiết gói</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {packageGroups.map((pack, i) => (
                                                <tr key={i}>
                                                    <td><Checkbox
                                                        checked={checkedSingle.includes(pack.id) ? true : false}
                                                        value={pack.id}
                                                        onChange={(e) => onChangeSinglePackage(e)}
                                                    >{pack.name}</Checkbox></td>
                                                    <td>
                                                        {pack.packages.map((sub, i) =>
                                                            <div key={i}>
                                                                - {sub.package_name}
                                                                <br />
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thêm mới
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func,
    serviceTypes: PropTypes.array,
    onGetServiceTypes: PropTypes.func
}

const mapStateToProps = ({ serviceType, helper }) => ({
    modalAdd: helper.modalAdd,
    serviceTypes: serviceType.serviceTypes,
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onGetServiceTypes: () => dispatch(getServiceTypes()),
    onAddNewServiceType: (message) => dispatch(addNewServiceType(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);