/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL"

export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"

// CONTACT
export const GET_CONTACTS = "GET_CONTACTS"
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS"
export const GET_CONTACTS_FAIL = "GET_CONTACTS_FAIL"

export const ADD_NEW_CONTACT = "ADD_NEW_CONTACT"
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS"
export const ADD_CONTACT_FAIL = "ADD_CONTACT_FAIL"

export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS"
export const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL"

export const DELETE_CONTACT = "DELETE_CONTACT"
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS"
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL"

export const OPEN_DETAIL = "OPEN_DETAIL"
export const CLOSE_DETAIL = "CLOSE_DETAIL"

// Get deatail
export const GET_CUSTOMER_CONTACT = "GET_CUSTOMER_CONTACT"
export const GET_CUSTOMER_CONTACT_SUCCESS = "GET_CUSTOMER_CONTACT_SUCCESS"
export const GET_CUSTOMER_CONTACT_FAIL = "GET_CUSTOMER_CONTACT_FAIL"

export const GET_CUSTOMER_SOFT_SERVICE = "GET_CUSTOMER_CONTACT"
export const GET_CUSTOMER_SOFT_SERVICE_SUCCESS =
  "GET_CUSTOMER_SOFT_SERVICE_SUCCESS"
export const GET_CUSTOMER_SOFT_SERVICE_FAIL = "GET_CUSTOMER_SOFT_SERVICE_FAIL"

export const GET_CUSTOMER_HARD_SERVICE = "GET_CUSTOMER_HARD_SERVICE"
export const GET_CUSTOMER_HARD_SERVICE_SUCCESS =
  "GET_CUSTOMER_HARD_SERVICE_SUCCESS"
export const GET_CUSTOMER_HARD_SERVICE_FAIL = "GET_CUSTOMER_HARD_SERVICE_FAIL"
