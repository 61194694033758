import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getSoftwareServices = () => get(url.GET_SOFTWARESERVICES)
const addNewSoftwareService = data => post(url.GET_SOFTWARESERVICES, data)
const updateSoftwareService = data => post(url.GET_SOFTWARESERVICES, data)
const deleteSoftwareService = data => post(url.GET_SOFTWARESERVICES, data)
const deploySoftwareService = data =>
  get(`${url.GET_DEPLOY_SOFTWARESERVICES}/${data}`)

const resetPasswordSoftwareService = data =>
  get(`${url.GET_RESET_PASSWORD_SOFTWARESERVICES}/${data}`)
export {
  getSoftwareServices,
  addNewSoftwareService,
  updateSoftwareService,
  deleteSoftwareService,
  deploySoftwareService,
  resetPasswordSoftwareService,
}
