import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Permission } from "permissions/Permission"
import PermissionLink from "../../permissions/PermissionLink"

import _ from "lodash"

const SidebarContent = props => {
  const { myRole } = props
  const locationPath = props.location.pathname
  const [permissions, setPermissions] = useState([])

  useEffect(() => {
    if (!_.isEmpty(myRole)) {
      setPermissions(myRole)
    }
  }, [myRole])

  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Main")} </li>
            {/* <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-home"></i>
                <span className="badge rounded-pill bg-primary float-end">2</span>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li> */}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-user"></i>
                <span>Quản lý khách hàng</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to={'/customers'} className={`waves-effect`}>
                    <PermissionLink
                      myRole={props.myRole}
                      permission={{ label: "customer", action: "view" }}
                    >
                      <span>Khách hàng</span>
                    </PermissionLink>
                  </Link>
                </li>
                <li>
                  <Link to="/customers/contact" className={`waves-effect`}>
                    <PermissionLink
                      myRole={props.myRole}
                      permission={{ label: "customer", action: "view" }}
                    >
                      <span>Thông tin liên hệ</span>
                    </PermissionLink>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-folder"></i>
                <span>Danh mục thiết lập</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/hardware-type" className={`waves-effect`}>
                    <PermissionLink
                      myRole={props.myRole}
                      permission={{ label: "hardware type", action: "view" }}
                    >
                      <span>Loại thiết bị</span>
                    </PermissionLink>
                  </Link>
                </li>

                <li>
                  <Link to="/product-component" className={`waves-effect`}>
                    <PermissionLink
                      myRole={props.myRole}
                      permission={{ label: "product component", action: "view" }}
                    >
                      <span>Tính năng phần mềm</span>
                    </PermissionLink>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-package"></i>
                <span>Gói dịch vụ</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">

                <li>
                  <Link to="/software-package" className={`waves-effect`}>
                    <PermissionLink
                      myRole={props.myRole}
                      permission={{ label: "software service pack", action: "view" }}
                    >
                      <span>Phần mềm</span>
                    </PermissionLink>
                  </Link>
                </li>

                <li>
                  <Link to="/hardware-package" className={`waves-effect`}>
                    <PermissionLink
                      myRole={props.myRole}
                      permission={{ label: "hardware service pack", action: "view" }}
                    >
                      <span>Thiết bị</span>
                    </PermissionLink>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-shopping-cart"></i>
                <span>Dịch vụ khách hàng</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/software-service" className={`waves-effect`}>
                    <PermissionLink
                      myRole={props.myRole}
                      permission={{ label: "software service", action: "view" }}
                    >
                      <span>Dịch vụ phần mềm</span>
                    </PermissionLink>
                  </Link>
                </li>
                <li>
                  <Link to="/hardware-service" className={`waves-effect`}>
                    <PermissionLink
                      myRole={props.myRole}
                      permission={{ label: "hardware service", action: "view" }}
                    >
                      <span>Dịch vụ thiết bị</span>
                    </PermissionLink>
                  </Link>
                </li>

              </ul>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-folder"></i>
                <span>Quản lý dịch vụ</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/service-pricing">Định giá dịch vụ</Link>
                </li>
                <li>
                  <Link to="/service-types">Loại dịch vụ</Link>
                </li>
                <li>
                  <Link to="/package-groups">Nhóm dịch vụ</Link>
                </li>
                <li>
                  <Link to="/services">Gói dịch vụ</Link>
                </li>
              </ul>
            </li> */}
            <li>
              <Link to="/roles" className={`waves-effect`}>
                <PermissionLink
                  myRole={props.myRole}
                  permission={{ label: "user role", action: "view" }}
                >
                  <i className="ti-shield"></i>
                  <span>Vai trò người dùng</span>
                </PermissionLink>
              </Link>
            </li>

            <li>
              <Link to="/users" className={`waves-effect`}>
                <PermissionLink
                  myRole={props.myRole}
                  permission={{ label: "user", action: "view" }}
                >
                  <i className="ti-id-badge"></i>
                  <span>Người dùng</span>
                </PermissionLink>
              </Link>
            </li>


            <li>
              <Link to="/system-logs" className={`waves-effect`}>
                <PermissionLink
                  myRole={props.myRole}
                  permission={{ label: "system log", action: "view" }}
                >
                  <i className="ti-time"></i>
                  <span>Nhật ký hệ thống</span>
                </PermissionLink>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  t: PropTypes.any,
  myRole: PropTypes.array,
}

const mapStateToProps = ({ Login }) => ({
  myRole: Login.myRole,
})

const mapDispatchToProps = dispatch => ({
  onOpenModalEdit: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SidebarContent)))
