import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

// Customer 
import customer from "./customer/reducer"

// User 
import user from "./user/reducer"
// User Role 
import user_role from "./user_role/reducer"
// ProductComponent
import productcomponent from "./productcomponent/reducer"
// Sotfware Service
import softwareservice from "./softwareservice/reducer"
// Sotfware Package
import softwarepackage from "./softwarepackage/reducer"
// Role 
import role from "./role/reducer"
// helper
import helper from "./helper/reducer";

// Service Type
import serviceType from "./serviceType/reducer"

// Package group
import packageGroup from "./packageGroup/reducer"

// Service Type
import service from "./service/reducer"

// Service Customer
import serviceCustomer from "./service/reducer"

// Device Type
import deviceType from "./hardwareType/reducer"

// Device Package
import devicePackage from "./hardwarePackage/reducer"

// Device Service
import deviceService from "./hardwareService/reducer"

// System Log
import systemLog from "./systemLog/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  customer,
  user,
  user_role,
  productcomponent,
  softwareservice,
  softwarepackage,
  role,
  helper,
  serviceType,
  packageGroup,
  service,
  serviceCustomer,
  deviceType,
  devicePackage,
  deviceService,
  systemLog
})

export default rootReducer
