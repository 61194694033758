import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_SOFTWARESERVICE,
  DELETE_SOFTWARESERVICE,
  DEPLOY_SOFTWARESERVICE,
  GET_SOFTWARESERVICES,
  RESET_PASSWORD_SOFTWARESERVICE,
  UPDATE_SOFTWARESERVICE,
} from "./actionTypes"
import {
  getSoftwareServicesSuccess,
  getSoftwareServicesFail,
  addSoftwareServiceFail,
  addSoftwareServiceSuccess,
  updateSoftwareServiceSuccess,
  updateSoftwareServiceFail,
  deleteSoftwareServiceSuccess,
  deleteSoftwareServiceFail,
  showErrorNotification,
  showSuccessNotification,
} from "./actions"
import { message } from "antd"
import { closeModalAdd, closeModalEdit } from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getSoftwareServices,
  addNewSoftwareService,
  updateSoftwareService,
  deleteSoftwareService,
  deploySoftwareService,
  resetPasswordSoftwareService,
} from "../../helpers/backend_api/softwareservices"
import { notification } from "antd"

const showErrorMessage = message => {
  notification.error({
    message: message,
    duration: 3, // Đặt thời gian tự động tắt (giây)
    style: { right: 0, zIndex: 9999 },
  })
}

const showSuccessMessage = message => {
  notification.success({
    message: message,
    duration: 3, // Đặt thời gian tự động tắt (giây)
    style: { right: 0, zIndex: 9999 },
  })
}

function* fetchSoftwareServices() {
  try {
    const response = yield call(getSoftwareServices)
    if (response.code === 200) {
      yield put(getSoftwareServicesSuccess(response.message))
    } else {
      yield put(getSoftwareServicesFail(error))
    }
  } catch (error) {
    yield put(getSoftwareServicesFail(error))
  }
}

function* onAddNewSoftwareService({ payload: softwareservice }) {
  try {
    const response = yield call(addNewSoftwareService, softwareservice)
    if (response.code === 201) {
      const response = yield call(getSoftwareServices)
      if (response.code === 200) {
        yield put(getSoftwareServicesSuccess(response.message))
        yield put(closeModalAdd())
        showSuccessMessage("Thêm thành công")
      } else {
        yield put(getSoftwareServicesFail(error))
        yield put(closeModalAdd())
        showErrorMessage(response.message)
      }
    } else {
      yield put(getSoftwareServicesFail(response))
      yield put(closeModalAdd())
      showErrorMessage(
        response.message
      )
    }
  } catch (error) {
    yield put(addSoftwareServiceFail(error))
    yield put(closeModalAdd())
    showErrorMessage("Có lỗi xảy ra . Vui lòng thử lại")
  }
}

function* onUpdateSoftwareService({ payload: softwareservice }) {
  try {
    const response = yield call(updateSoftwareService, softwareservice)
    if (response.code === 200) {
      const response = yield call(getSoftwareServices)
      if (response.code === 200) {
        yield put(getSoftwareServicesSuccess(response.message))

        yield put(closeModalEdit())
        showSuccessMessage("Cập nhật thành công")
      } else {
        yield put(getSoftwareServicesFail(response))
        yield put(showErrorNotification(false))
        yield put(closeModalEdit())
        showErrorMessage(response.message)
      }
    } else {
      yield put(getSoftwareServicesFail(response))
      yield put(closeModalEdit())
      showErrorMessage(
        "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên hoặc đọc chính sách về phần mềm"
      )
    }
  } catch (error) {
    yield put(updateSoftwareServiceFail(error))
    yield put(showErrorNotification(false))
    showErrorMessage("Có lỗi xảy ra. Vui lòng thử lại")
  }
}

function* onDeleteSoftwareService({ payload: softwareservice }) {
  try {
    const response = yield call(deleteSoftwareService, softwareservice)
    if (response.code === 200) {
      const response = yield call(getSoftwareServices)
      if (response.code === 200) {
        yield put(getSoftwareServicesSuccess(response.message))
        // yield put(deleteSoftwareServiceSuccess(response))
        showSuccessMessage("Xóa thành công")
      } else {
        yield put(getSoftwareServicesFail(error))
        showErrorMessage(response.message)
      }
    } else {
      yield put(deleteSoftwareServiceFail(response))
      showErrorMessage(response.message)
    }
  } catch (error) {
    yield put(deleteSoftwareServiceFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
    )
  }
}

function* onDeploySoftwareService({ payload: softwareservice }) {
  try {
    const response1 = yield call(deploySoftwareService, softwareservice)
    if (response1.code === 200) {
      const response = yield call(getSoftwareServices)
      if (response.code === 200) {
        yield put(getSoftwareServicesSuccess(response.message))
        showSuccessMessage(response1.message)
      } else {
        yield put(getSoftwareServicesFail(response))
        showErrorMessage(
          "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
        )
      }
    } else {
      yield put(getSoftwareServicesFail(response1))
      showErrorMessage(response1.message)
    }
  } catch (error) {
    yield put(updateSoftwareServiceFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
    )
  }
}

function* onResetSoftwareService({ payload: softwareservice }) {
  try {
    const response1 = yield call(resetPasswordSoftwareService, softwareservice)
    if (response1.code === 200) {
      const response = yield call(getSoftwareServices)
      if (response.code === 200) {
        yield put(getSoftwareServicesSuccess(response.message))
        showSuccessMessage(response1.message)
      } else {
        yield put(getSoftwareServicesFail(response))
        showErrorMessage(
          "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
        )
      }
    } else {
      yield put(getSoftwareServicesFail(response1))
      showErrorMessage(response1.message)
    }
  } catch (error) {
    yield put(updateSoftwareServiceFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
    )
  }
}

function* softwareserviceSaga() {
  yield takeEvery(GET_SOFTWARESERVICES, fetchSoftwareServices)
  yield takeEvery(ADD_NEW_SOFTWARESERVICE, onAddNewSoftwareService)
  yield takeEvery(UPDATE_SOFTWARESERVICE, onUpdateSoftwareService)
  yield takeEvery(DELETE_SOFTWARESERVICE, onDeleteSoftwareService)
  yield takeEvery(DEPLOY_SOFTWARESERVICE, onDeploySoftwareService)
  yield takeEvery(RESET_PASSWORD_SOFTWARESERVICE, onResetSoftwareService)
}

export default softwareserviceSaga
