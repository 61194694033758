import React, { useEffect, useState } from "react"
import { Table, Modal, Alert, Tooltip } from "antd"
import { queries } from "@testing-library/react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from "react-color"
import Edit from "./Edit"
import Detail from "./Detail"
import {
  CHANGE_OPEN_MODAL_EDIT,
  CHANGE_OPEN_MODAL_DETAIL,
} from "store/helper/actionTypes"
import { closeModalDelete, deleteSoftwarePackage } from "../../store/actions"
import Permission from "permissions/Permission"
import _ from "lodash"

const SoftwarePackageTable = props => {
  const [showMessage, setShowMessage] = useState(false)
  const columns = [
    {
      title: "Tên phần mềm",
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Mô tả về dịch vụ",
      dataIndex: "specifications",
      key: "specifications",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Thao tác",
      key: "operation",
      fixed: "right",
      width: 50,
      render: (text, record, index) => (
        <>
          <button
            type="button"
            onClick={() => handleShow(record)}
            className="btn btn-outline-info btn-sm waves-effect waves-light me-1"
          >
            <i className="fas fa-eye"></i>
          </button>
          <Permission myRole={myRole} permission={{ label: "software service pack", action: "update" }}>
            <button
              type="button"
              onClick={() => handleEdit(record)}
              className="btn btn-outline-success btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-edit"></i>
            </button>
          </Permission>

          <Permission myRole={myRole} permission={{ label: "software service pack", action: "delete" }}>
            <button
              type="button"
              onClick={() => handleDelete(record)}
              className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-trash"></i>
            </button>
          </Permission>
        </>
      ),
    },
  ]

  const {
    softwarepackages,
    onOpenModalEdit,
    onOpenModalDetail,
    onDeleteSoftwarePackage,
    keyword,
    myRole
  } = props
  const [dataRow, setDataRow] = useState({})
  const [softwarepackagesList, setSoftwarePackages] = useState([])

  const handleShow = row => {
    let modal = {
      isOpenModal: true,
      title: "Thông tin về gói dịch vụ",
    }
    setDataRow(row)
    onOpenModalDetail(modal)
  }

  const handleEdit = row => {
    let modal = {
      isOpenModal: true,
      title: "Cập nhật gói dịch vụ phần mềm",
    }

    setDataRow(row)
    onOpenModalEdit(modal)
  }

  const handleDelete = row => {
    confirm(row)
  }

  useEffect(() => {
    if (keyword) {
      const filteredData = softwarepackagesList.filter(entry =>
        (entry?.name).toLowerCase().includes(keyword.toLowerCase())
      );
      setSoftwarePackages(filteredData);
    } else {
      let data = []
      softwarepackages?.map(softwarepackage => {
        let groups = []

        if (softwarepackage.component.length > 0) {
          groups = _.groupBy(softwarepackage.component, "group")
        }

        let newGroup = []
        Object.keys(groups).map(key => {
          newGroup.push({
            name: key,
            packages: groups[key],
          })
        })
        data.push({
          key: softwarepackage.id,
          name: softwarepackage.name,
          specifications: softwarepackage.specifications,
          on_premise: softwarepackage.on_premise,
          training_cource: softwarepackage.training_cource,
          training_type: softwarepackage.training_type,
          training_fee: softwarepackage.training_fee,
          training_time: softwarepackage.training_time,
          training_time_unit: softwarepackage.training_time_unit,
          guidance_document: softwarepackage.guidance_document,
          initialization_fee: softwarepackage.initialization_fee,
          initialization_price: softwarepackage.initialization_price,
          employee: softwarepackage.employee,
          web_user: softwarepackage.web_user,
          mobile_user: softwarepackage.mobile_user,
          connective: softwarepackage.connective,
          on_premise: softwarepackage.on_premise,
          unit_price: softwarepackage.unit_price,
          component: newGroup,
        })
      })
      setSoftwarePackages(data)
    }

  }, [softwarepackages, keyword])

  const confirm = row => {
    Modal.confirm({
      title: "Bạn có chắc",
      icon: "",
      content: `Bạn có muốn xoá người dùng "${row.name}"`,
      okText: "Đồng ý",
      cancelText: "Đóng",
      onOk: () => {
        let newData = {
          id: row.key,
        }
        let message = {
          action: "delete",
          message: newData,
          type_message: "map",
        }
        onDeleteSoftwarePackage(message)
      },
      okButtonProps: {
        style: {
          backgroundColor: "#ff4d4f",
        },
      },
      centered: true,
    })
  }
  const closeAlert = () => {
    setShowMessage(false)
  }
  if (showMessage) {
    setTimeout(closeAlert, 3000) // đóng thông báo sau 3 giây
  }
  return (
    <>
      <Edit dataRow={dataRow}></Edit>
      <Detail dataRow={dataRow}></Detail>
      <Table
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
        columns={columns}
        size={'small'}
        dataSource={softwarepackagesList} />
      {showMessage && (
        <Alert
          message="Xóa thành công"
          type="success"
          onClose={closeAlert}
          style={{
            position: "fixed",
            fontWeight: "600",
            top: "100px",
            right: "20px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 50px",
          }}
        />
      )}
    </>
  )
}

SoftwarePackageTable.propTypes = {
  t: PropTypes.any,
  softwarepackages: PropTypes.array,
  onGetSoftwarePackages: PropTypes.func,
  onOpenModalEdit: PropTypes.func,
  onOpenModalDetail: PropTypes.func,
  keyword: PropTypes.string,
  myRole: PropTypes.array
}

const mapStateToProps = ({ softwarepackage, Login }) => ({
  softwarepackages: softwarepackage.softwarepackages,
  myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
  onOpenModalEdit: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
  onOpenModalDetail: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_DETAIL, payload: modal }),
  onDeleteSoftwarePackage: message => dispatch(deleteSoftwarePackage(message)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SoftwarePackageTable)
