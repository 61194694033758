import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

// Custom Scrollbar

// import images

//i18n
import { withTranslation } from "react-i18next"

import {
  Avatar,
  Button,
  Button as ButtonAnt,
  Input as InputAnt,
  Menu,
  Table,
  Space,
  Collapse,
} from "antd"
import { MailOutlined, SettingOutlined } from "@ant-design/icons"
import { Permission } from "permissions/Permission"
import { connect } from "react-redux"
import { CHANGE_OPEN_MODAL_ADD } from "store/helper/actionTypes"
import { getContacts, getCustomers } from "../../../store/actions"
import Add from "./Add"
import ContactTable from "./ContactTable"
import SubMenu from "antd/es/menu/SubMenu"
import { OPEN_DETAIL } from "store/customer/actionTypes"
import { UserOutlined } from "@ant-design/icons"
const { Panel } = Collapse
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}
const CustomersContactDetail = props => {
  const [menu, setMenu] = useState(false)

  const {
    onGetContacts,
    onGetCustomers,
    onOpenModalAdd,
    onOpenDetail,
    detailHards,
    detailCusSofts,
    detailCusContacts,
    customers,
    contactDetail,
  } = props
  const toggle = () => {
    setMenu(!menu)
  }
  const [listDetailContacts, setListDetailContacts] = useState([])
  const [listDetailSofts, setListDetailSofts] = useState([])
  const [listDetailHards, setListDetailHards] = useState([])
  useEffect(() => {
    const dataContact = []
    detailCusContacts?.map((contact, index) => {
      dataContact.push({
        stt: index + 1,
        key: contact.id,
        id: contact.id,
        job_title: contact.job_title,
        phone_contact: contact.phone_contact,
        name: contact.name,
        email_contact: contact.email_contact,
        notes: contact.notes,
      })
    })
    setListDetailContacts(dataContact)

    const dataSoft = []
    detailCusSofts?.map((soft, index) => {
      dataSoft.push({
        stt: index + 1,
        key: soft.id,
        id: soft.id,
        service_pack: soft.service_pack.name,
        status: soft.status,
        start_date: soft.start_date,
        billing_date: soft.billing_date,
        terminate_date: soft.terminate_date,
      })
    })
    setListDetailSofts(dataSoft)

    const dataHard = []
    detailHards?.map((deviceService, index) => {
      dataHard.push({
        stt: index + 1,
        key: deviceService.id,
        id: deviceService.id,
        billing_period: `${deviceService.billing_cycle} ${deviceService.billing_cycle_type}`,
        billing_cycle: deviceService.billing_cycle,
        billing_cycle_type: deviceService.billing_cycle_type,
        rental_equipment: deviceService.rental_equipment,
        customer_name: deviceService.customer.company_name,
        customer: deviceService.customer.id,
        one_time_payment: deviceService.one_time_payment,
        quantity: deviceService.quantity,
        service_pack_name: deviceService.service_pack.name,
        service_pack: deviceService.service_pack.id,
        start_date: deviceService.start_date,
        status: deviceService.status,
        terminate_date: deviceService.terminate_date,
      })
    })
    setListDetailHards(dataHard)
  }, [detailHards, detailCusSofts, detailCusContacts])

  const columnsSoftwareService = [
    {
      title: "STT",
      titleCol: 4,
      dataIndex: "stt",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Gói dịch vụ",
      titleCol: 4,
      dataIndex: "service_pack",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Trạng thái",
      titleCol: 4,
      dataIndex: "status",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Ngày bắt đầu sử dụng",
      titleCol: 4,
      dataIndex: "start_date",
      dataCol: 8,
      unitType: "show-package",
    },
    {
      title: "Ngày bắt đầu tính cước",
      titleCol: 4,
      dataIndex: "billing_date",
      dataCol: 8,
      unitType: "show-package",
    },
    {
      title: "Ngày ngưng dịch vụ",
      titleCol: 4,
      dataIndex: "terminate_date",
      dataCol: 8,
      unitType: "show-package",
    },
  ]

  const columnsHardService = [
    {
      title: "STT",
      width: 50,
      dataIndex: "stt",
      key: "stt",
      fixed: "left",
    },
    {
      title: "Gói dịch vụ",
      width: 150,
      dataIndex: "service_pack_name",
      key: "service_pack_name",
      fixed: "left",
    },
    {
      title: "Số lượng",
      width: 200,
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Thanh toán 1 lần",
      width: 200,
      dataIndex: "one_time_payment",
      key: "one_time_payment",
    },
    {
      title: "Chu kỳ thanh toán",
      width: 200,
      dataIndex: "billing_period",
      key: "billing_period",
    },
    {
      title: "Thiết bị cho thuê",
      width: 200,
      dataIndex: "rental_equipment",
      key: "rental_equipment",
    },
    {
      title: "Ngày bắt đầu tính cước",
      width: 200,
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Ngày kết thúc",
      width: 200,
      dataIndex: "terminate_date",
      key: "terminate_date",
    },
    {
      title: "Trạng thái",
      width: 200,
      dataIndex: "status",
      key: "status",
      render: text => (
        <span
          className={` text-${text == "active" ? "success" : "warning"} btn-sm`}
          style={{ fontWeight: "bold", textTransform: "capitalize" }}
        >
          {text}
        </span>
      ),
    },
  ]

  const columnsContact = [
    {
      title: "STT",
      titleCol: 4,
      dataIndex: "stt",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Tên người liên hệ",
      titleCol: 4,
      dataIndex: "name",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Chức danh",
      titleCol: 4,
      dataIndex: "job_title",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Email liên hệ",
      titleCol: 4,
      dataIndex: "email_contact",
      dataCol: 8,
      unitType: "show-package",
    },
    {
      title: "Số điện thoại",
      titleCol: 4,
      dataIndex: "phone_contact",
      dataCol: 8,
      unitType: "show-package",
    },
    {
      title: "Ghi chú",
      titleCol: 4,
      dataIndex: "notes",
      dataCol: 8,
      unitType: "show-package",
    },
  ]
  const handleShow = row => {
    onOpenDetail({
      isOpenDetail: false,
      idContact: "",
    })
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ paddingTop: "0px" }}>
        <MetaTags>
          <title>Customers</title>
        </MetaTags>

        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={12}>
                <h6 className="page-title">Thông tin khách hàng</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">Quản lý khách hàng</li>
                  <li className="breadcrumb-item active">Khách hàng</li>
                </ol>
              </Col>
            </Row>
          </div>
          {/* Table here */}
          <Button onClick={handleShow}>Quay lại</Button>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row className="justify-content-center">
                    <Row>
                      <Col md={3}>
                        <div
                          className="circle text-center"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            margin: "0px auto",
                          }}
                        >
                          <img
                            src={"https://bom.so/ZFVKr0"}
                            alt="Circle Image"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>

                        <h4 className="text-center">
                          {contactDetail.idContact.company_name}
                        </h4>
                        <div className="text-center">
                          <p style={{ margin: "0" }}>
                            {contactDetail.idContact.reminiscent_name}
                          </p>
                          <p style={{ margin: "0" }}>
                            {contactDetail.idContact.email_contact}
                          </p>
                        </div>
                      </Col>

                      <Col md={8}>
                        <Row className="mt-4 ">
                          <Col md={2}>
                            <Label>Ngày thành lập:</Label>
                          </Col>
                          <Col md={8}>
                            {" "}
                            {contactDetail.idContact.fouding_date}
                          </Col>
                        </Row>
                        <Row className="mt-4 ">
                          <Col md={2}>
                            <Label>Địa chỉ:</Label>
                          </Col>
                          <Col md={8}> {contactDetail.idContact.address}</Col>
                        </Row>
                        <Row className="mt-4 ">
                          <Col md={2}>
                            <Label>Mã số thế: </Label>
                          </Col>
                          <Col md={8}> {contactDetail.idContact.tax_id}</Col>
                        </Row>

                        <Row className="mt-4 ">
                          <Col md={2}>
                            <Label>Địa chỉ xuất hóa đơn:</Label>
                          </Col>
                          <Col md={8}>
                            {" "}
                            {contactDetail.idContact.tax_address}
                          </Col>
                        </Row>
                        <Row className="mt-4 ">
                          <Col md={2}>
                            <Label>Ngày cập nhật:</Label>
                          </Col>
                          <Col md={8}>
                            {" "}
                            {contactDetail.idContact.updated_at}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Row>
                  <Row className="mt-5">
                    <Collapse defaultActiveKey={["1"]}>
                      <Panel
                        header={
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "0.9rem",
                              color: "#626ed4",
                            }}
                          >
                            Thông tin liên hệ
                          </span>
                        }
                        key="1"
                      >
                        <Table
                          dataSource={listDetailContacts}
                          columns={columnsContact}
                        />
                      </Panel>
                      <Panel
                        header=<span
                          style={{
                            fontWeight: "600",
                            fontSize: "0.9rem",
                            color: "#626ed4",
                          }}
                        >
                          Danh sách thiết bị
                        </span>
                        key="3"
                      >
                        <Table
                          dataSource={listDetailHards}
                          columns={columnsHardService}
                        />
                      </Panel>
                      <Panel
                        header={
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "0.9rem",
                              color: "#626ed4",
                            }}
                          >
                            Danh sách dịch vụ phần mềm
                          </span>
                        }
                        key="2"
                      >
                        <Table
                          dataSource={listDetailSofts}
                          columns={columnsSoftwareService}
                        />
                      </Panel>
                    </Collapse>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CustomersContactDetail.propTypes = {
  t: PropTypes.any,
  contacts: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onOpenModalAdd: PropTypes.func,
}

const mapStateToProps = ({ customer }) => ({
  customers: customer.customers,
  contacts: customer.contacts,
  contactDetail: customer.contactDetail,
  detailCusContacts: customer.detailCusContacts,
  detailCusSofts: customer.detailCusSofts,
  detailHards: customer.detailHards,
})

const mapDispatchToProps = dispatch => ({
  onGetContacts: () => dispatch(getContacts()),
  onGetCustomers: () => dispatch(getCustomers()),
  onOpenDetail: detail => dispatch({ type: OPEN_DETAIL, payload: detail }),
  onOpenModalAdd: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_ADD, payload: modal }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CustomersContactDetail))
