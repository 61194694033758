import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Steps, Select as SelectAnt, InputNumber, DatePicker } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewCustomer
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';


const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewCustomer } = props;
    const [errors, setErrors] = useState([]);
    const [fields, setFields] = useState({});
    const { register, handleSubmit } = useForm();

    const handleCancel = () => {
        onCloseModalAdd();
    };

    const onSubmit = data => {
        if (handleValidate()) {
            let message = {
                'action': 'create',
                'message': fields,
                "type_message": "map"
            }
            // console.log(message)
            onAddNewCustomer(message);
        }
    };

    // ======= VALIDATE ALL STEP ==========================
    const handleValidate = () => {

        let currentFields = fields;
        let isValid = true;
        let errs = {};
        if (!currentFields.reminiscent_name) {
            errs.reminiscent_name = {
                type: 'required',
                message: 'Tên công ty không được phép để trống'
            }
            isValid = false;
        }

        if (!currentFields.company_name) {
            errs.company_name = {
                type: 'required',
                message: 'Tên công ty không được phép để trống'
            }
            isValid = false;
        }

        if (!currentFields.tax_id) {
            errs.tax_id = {
                type: 'required',
                message: 'Mã số thuế không được phép để trống'
            }
            isValid = false;
        }

        if (!currentFields.tax_address) {
            errs.tax_address = {
                type: 'required',
                message: 'Địa chỉ xuất hoá đơn không được phép để trống'
            }
            isValid = false;
        }

        if (!currentFields.email_contact) {
            errs.email_contact = {
                type: 'required',
                message: 'Email công ty không được phép để trống'
            }
            isValid = false;
        } else {
            let expression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            let regex = new RegExp(expression);
            if (!currentFields.email_contact.match(regex)) {
                errs.email_contact = {
                    type: 'pattern',
                    message: 'Email không đúng định dạng'
                }
                isValid = false;
            }
        }

        // if (!currentFields.phone1) {
        //     errs.phone1 = {
        //         type: 'required',
        //         message: 'Số điện thoại không được phép để trống'
        //     }
        //     isValid = false;
        // } else {
        //     let expression = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
        //     let regex = new RegExp(expression);
        //     if (!currentFields.phone1.match(regex)) {
        //         errs.phone1 = {
        //             type: 'pattern',
        //             message: 'Số điện thoại không đúng định dạng'
        //         }
        //         isValid = false;
        //     }
        // }

        // if (currentFields.phone2) {
        //     let expression = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
        //     let regex = new RegExp(expression);
        //     if (!currentFields.phone2.match(regex)) {
        //         errs.phone2 = {
        //             type: 'pattern',
        //             message: 'Số điện thoại không đúng định dạng'
        //         }
        //         isValid = false;
        //     }
        // }

        if (!currentFields.address) {
            errs.address = {
                type: 'required',
                message: 'Địa chỉ công ty không được phép để trống'
            }
            isValid = false;
        }

        setErrors(errs);
        return isValid;
    }
    // =============== END OF VALIDATE =====================

    // ============ END OF PROCESS =======================

    // ============ GET ALL VALUE FROM INPUT NORMAL ======
    const handleChangeInput = (e) => {
        let myFields = fields;
        myFields[e.target.name] = e.target.value;
        setFields(myFields);
    }
    // ==== END OF FUNCTION =========================

    const onChangeDatePicker = name => (date, dateString) => {
        let myFields = fields;
        myFields[name] = dateString;
        setFields(myFields);
    }

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Tên viết tắt <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            name='reminiscent_name'
                                            onChange={handleChangeInput}
                                            className="form-control"
                                            placeholder='Tên viết tắt...'
                                        />
                                        {errors.reminiscent_name && errors.reminiscent_name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên viết tắt không được để trống</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Tên khách hàng <span className='text-danger'>*</span> </label>
                                </Col>
                                <Col md={8}>
                                    <input
                                        name='company_name'
                                        onChange={handleChangeInput}
                                        className="form-control"
                                        placeholder='Tên khách hàng...'
                                    />
                                    {errors.company_name && errors.company_name.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên khách hàng không được để trống</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Email <span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <input
                                        name='email_contact'
                                        onChange={handleChangeInput}
                                        className="form-control"
                                        placeholder='Email khách hàng...' />
                                    {errors.email_contact && errors.email_contact.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Email không được để trống</span>
                                    )}
                                    {errors.email_contact && errors.email_contact.type === "pattern" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Email không hợp lệ</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Địa chỉ <span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <textarea
                                        name='address'
                                        onChange={handleChangeInput}
                                        className="form-control"
                                        placeholder='Địa chỉ...'
                                    ></textarea>
                                    {errors.address && errors.address.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Địa chỉ không được để trống</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Ngày thành lập </label>
                                </Col>
                                <Col md={8}>
                                    <DatePicker
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={onChangeDatePicker('fouding_date')}
                                    />
                                    {errors.fouding_date && errors.fouding_date.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Địa chỉ không được để trống</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Mã số thuế <span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <input
                                        name='tax_id'
                                        onChange={handleChangeInput}
                                        className="form-control"
                                        placeholder='Mã số thuế...'
                                    />
                                    {errors.tax_id && errors.tax_id.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Mã số thuế không được để trống</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Địa chỉ xuất hoá đơn <span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <input
                                        name='tax_address'
                                        onChange={handleChangeInput}
                                        className="form-control"
                                        placeholder='Địa chỉ xuất hoá đơn...'
                                    />
                                    {errors.tax_address && errors.tax_address.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Địa chỉ xuất hoá đơn không được để trống</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Ghi chú </label>
                                </Col>
                                <Col md={8}>
                                    <textarea
                                        name='notes'
                                        onChange={handleChangeInput}
                                        className="form-control"
                                        placeholder='Ghi chú...'
                                    ></textarea>
                                </Col>
                            </Row>
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thêm mới
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func
}

const mapStateToProps = ({ customer, helper }) => ({
    modalAdd: helper.modalAdd
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewCustomer: (message) => dispatch(addNewCustomer(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);