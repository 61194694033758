/* DEVICE_SERVICES */
export const GET_DEVICE_SERVICES = "GET_DEVICE_SERVICES"
export const GET_DEVICE_SERVICES_SUCCESS = "GET_DEVICE_SERVICES_SUCCESS"
export const GET_DEVICE_SERVICES_FAIL = "GET_DEVICE_SERVICES_FAIL"

export const ADD_NEW_DEVICE_SERVICE = "ADD_NEW_DEVICE_SERVICE"
export const ADD_DEVICE_SERVICE_SUCCESS = "ADD_DEVICE_SERVICE_SUCCESS"
export const ADD_DEVICE_SERVICE_FAIL = "ADD_DEVICE_SERVICE_FAIL"

export const UPDATE_DEVICE_SERVICE = "UPDATE_DEVICE_SERVICE"
export const UPDATE_DEVICE_SERVICE_SUCCESS = "UPDATE_DEVICE_SERVICE_SUCCESS"
export const UPDATE_DEVICE_SERVICE_FAIL = "UPDATE_DEVICE_SERVICE_FAIL"

export const DELETE_DEVICE_SERVICE = "DELETE_DEVICE_SERVICE"
export const DELETE_DEVICE_SERVICE_SUCCESS = "DELETE_DEVICE_SERVICE_SUCCESS"
export const DELETE_DEVICE_SERVICE_FAIL = "DELETE_DEVICE_SERVICE_FAIL"
