import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_USER,
  CHANGE_USER_PASSWORD,
  DELETE_USER,
  GET_USERS,
  UPDATE_USER,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"

import {
  closeModalAdd,
  closeModalDetail,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
  updateUserPassword
} from "../../helpers/backend_api/users"

function* fetchUsers() {
  yield put(getUsersFail({}))
  try {
    const response = yield call(getUsers)
    if (response.code === 200) {
      yield put(getUsersSuccess(response.message))
    }
    else {
      yield put(getUsersFail(response))
    }
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(addNewUser, user)
    if (response.code === 201) {
      yield call(fetchUsers)
      yield put(closeModalAdd())
    } else {
      yield put(addUserFail(response))
    }
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    if (response.code === 200) {
      yield call(fetchUsers)
      yield put(closeModalEdit())
    } else {
      yield put(updateUserFail(response))
    }
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    if (response.code === 200) {
      yield call(fetchUsers)
    } else {
      yield put(deleteUserFail(response))
    }

  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onChangeUserPassword({ payload: user }) {
  try {
    const response = yield call(updateUserPassword, user)
    if (response.code === 200) {
      yield call(fetchUsers)
      yield put(closeModalDetail())
    } else {
      yield put(updateUserFail(response))
    }
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(CHANGE_USER_PASSWORD, onChangeUserPassword)

  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default userSaga
