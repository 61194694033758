import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Switch } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewUser
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';

const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewUser, roles } = props;
    const [roleOptions, setRoleOpetion] = useState([]);
    const [fields, setFields] = useState({ disable_notify: false });
    const [myError, setMyErrors] = useState({});

    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        let data = [];
        roles?.map(role => {
            data.push({
                value: role.id,
                label: role.name,
            })
        });
        setRoleOpetion(data);
    }, [roles])

    const selectProps = {
        mode: 'multiple',
        style: {
            width: '100%',
        },
        options: roleOptions,
        onChange: (newValue) => {
            fields["roles"] = newValue;
            setFields(fields);
        },
        maxTagCount: 'responsive',
    };

    const handleCancel = () => {
        onCloseModalAdd();
    };

    const onSubmit = data => {
        if (validateInput()) {
            let merge = {
                ...data,
                default_language: fields.default_language,
                disable_notify: fields.disable_notify === true ? "Yes" : "No"
            }
            let message = {
                'action': 'create',
                'message': merge,
                "type_message": "map"
            }
            // console.log(message)
            onAddNewUser(message);
        }

    };

    const validateInput = () => {
        let isValid = true;
        let errs = myError;
        if (!fields.default_language) {
            errs.default_language = {
                type: 'required',
                message: 'Ngôn ngữ không được phép để trống'
            }
            isValid = false;
        }
        setMyErrors(errs);
        return isValid;
    }

    const onChange = name => (value) => {
        delete myError[name];
        setMyErrors(myError);
        fields[name] = value;
        setFields(fields);
    };

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Tên tài khoản <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            aria-invalid={errors.user_name ? "true" : "false"}
                                            {...register('user_name', {
                                                required: true,
                                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            })}
                                            className="form-control"
                                            placeholder='Tên tài khoản...'
                                        />
                                        {errors.user_name && errors.user_name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên tài khoản không được để trống</span>
                                        )}
                                        {errors.user_name && errors.user_name.type === "pattern" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên tài khoản phải là email</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Ngôn ngữ<span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <SelectAnt
                                        showSearch
                                        placeholder="Vui lòng chọn ngôn ngữ"
                                        optionFilterProp="children"
                                        onChange={onChange('default_language')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{
                                            width: '100%',
                                        }}
                                        options={[
                                            {
                                                value: 'Vietnamese',
                                                label: 'Vietnamese',
                                            },
                                            {
                                                value: 'English',
                                                label: 'English',
                                            },
                                        ]}
                                    />
                                    {myError.default_language && myError.default_language.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Ngôn ngữ mặc định không được để trống</span>
                                    )}
                                </Col>
                            </Row>
                            {/* <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Vai trò <span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <SelectAnt
                                        showSearch
                                        placeholder="Vui lòng chọn vai trò..."
                                        optionFilterProp="children"
                                        onChange={onChange('default_language')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        {...selectProps}
                                    />
                                    {myError.default_language && myError.default_language.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Ngôn ngữ mặc định không được để trống</span>
                                    )}
                                </Col>
                            </Row> */}
                            {/* <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Tắt thông báo</label>
                                </Col>
                                <Col md={8}>
                                    <Switch onChange={onChange('disable_notify')} />
                                </Col>
                            </Row> */}
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thêm mới
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func,
    roles: PropTypes.array
}

const mapStateToProps = ({ user, helper, role }) => ({
    modalAdd: helper.modalAdd,
    roles: role.roles,
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewUser: (message) => dispatch(addNewUser(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);