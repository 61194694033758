/* SOFTWARESERVICES */
export const GET_SOFTWARESERVICES = "GET_SOFTWARESERVICES"
export const GET_SOFTWARESERVICES_SUCCESS = "GET_SOFTWARESERVICES_SUCCESS"
export const GET_SOFTWARESERVICES_FAIL = "GET_SOFTWARESERVICES_FAIL"

export const ADD_NEW_SOFTWARESERVICE = "ADD_NEW_SOFTWARESERVICE"
export const ADD_SOFTWARESERVICE_SUCCESS = "ADD_SOFTWARESERVICE_SUCCESS"
export const ADD_SOFTWARESERVICE_FAIL = "ADD_SOFTWARESERVICE_FAIL"

export const UPDATE_SOFTWARESERVICE = "UPDATE_SOFTWARESERVICE"
export const UPDATE_SOFTWARESERVICE_SUCCESS = "UPDATE_SOFTWARESERVICE_SUCCESS"
export const UPDATE_SOFTWARESERVICE_FAIL = "UPDATE_SOFTWARESERVICE_FAIL"

export const DELETE_SOFTWARESERVICE = "DELETE_SOFTWARESERVICE"
export const DELETE_SOFTWARESERVICE_SUCCESS = "DELETE_SOFTWARESERVICE_SUCCESS"
export const DELETE_SOFTWARESERVICE_FAIL = "DELETE_SOFTWARESERVICE_FAIL"

export const SHOW_MESSAGE_SUCCESS_NOTIFICATION =
  "SHOW_MESSAGE_SUCCESS_NOTIFICATION"
export const SHOW_MESSAGE_ERROR_NOTIFICATION = "SHOW_MESSAGE_ERROR_NOTIFICATION"

export const DEPLOY_SOFTWARESERVICE = "DEPLOY_SOFTWARESERVICE"
export const DEPLOY_SOFTWARESERVICE_SUCCESS = "DEPLOY_SOFTWARESERVICE_SUCCESS"
export const DEPLOY_SOFTWARESERVICE_FAIL = "DEPLOY_SOFTWARESERVICE_FAIL"

export const RESET_PASSWORD_SOFTWARESERVICE = "RESET_PASSWORD_SOFTWARESERVICE"
export const RESET_PASSWORD_SOFTWARESERVICE_SUCCESS =
  "RESET_PASSWORD_SOFTWARESERVICE_SUCCESS"
export const RESET_PASSWORD_SOFTWARESERVICE_FAIL =
  "RESET_PASSWORD_SOFTWARESERVICE_FAIL"
