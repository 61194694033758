import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CardHeader
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react";

//i18n
import { withTranslation } from "react-i18next"
import DevicePackageTable from './DevicePackageTable';
import { Input as InputAnt, Button as ButtonAnt } from 'antd';


import {
    getDevicePackages,
} from "../../store/actions"
import { connect } from "react-redux"
import { get } from 'lodash';
import { CHANGE_OPEN_MODAL_ADD } from 'store/helper/actionTypes';
import Add from "./Add"
import Permission from 'permissions/Permission';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";

const DevicePackages = props => {
    const { onGetDevicePackages, onOpenModalAdd, messageError, myRole } = props
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        onGetDevicePackages();
    }, [onGetDevicePackages])

    useEffect(() => {
        if (!_.isEmpty(messageError)) {
            toast.error(messageError.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [messageError])

    const handleAdd = () => {
        let modal = {
            isOpenModal: true,
            title: "Thêm mới gói thiết bị"
        }
        onOpenModalAdd(modal)
    }

    const onSearch = (e) => {
        setKeyword(e.target.value);
    }

    return (
        <React.Fragment>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div className="page-content">
                <MetaTags>
                    <title>Thiết bị</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">Thiết bị</h6>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Gói dịch vụ</li>
                                    <li className="breadcrumb-item active">Thiết bị</li>
                                </ol>
                            </Col>
                        </Row>
                    </div>
                    {/* Table here */}
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle className="h4">Danh sách gói thiết bị</CardTitle>
                                            <p className="card-title-desc">
                                                Quản lý danh sách gói thiết bị
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={8}>
                                            <div className='mb-2'>
                                                <Permission myRole={myRole} permission={{ label: 'hardware service pack', action: "create" }}>
                                                    <ButtonAnt type="primary" className='me-1' onClick={() => handleAdd()}>+ Thêm mới</ButtonAnt>
                                                    <Add></Add>
                                                </Permission>

                                                {/* <ButtonAnt className='me-1'><i className='fas fa-filter text-primary'></i></ButtonAnt> */}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className='float-end mb-2'>
                                                <InputAnt
                                                    onChange={onSearch}
                                                    placeholder="search..." style={{
                                                        width: 200,
                                                    }} />
                                            </div>

                                        </Col>
                                    </Row>
                                    <DevicePackageTable keyword={keyword}></DevicePackageTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )
}

DevicePackages.propTypes = {
    t: PropTypes.any,
    devicePackages: PropTypes.array,
    onGetDevicePackages: PropTypes.func,
    onOpenModalAdd: PropTypes.func,
    messageError: PropTypes.any,
    myRole: PropTypes.array
}

const mapStateToProps = ({ devicePackage, Login }) => ({
    devicePackages: devicePackage.devicePackages,
    messageError: devicePackage.error,
    myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
    onGetDevicePackages: () => dispatch(getDevicePackages()),
    onOpenModalAdd: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_ADD, payload: modal }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DevicePackages))
