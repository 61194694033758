import {
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAIL,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  ROW_CUSTOMER,
  // CONTACT
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAIL,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAIL,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAIL,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  OPEN_DETAIL,
  CLOSE_DETAIL,

  // DETAIL
  GET_CUSTOMER_CONTACT_SUCCESS,
  GET_CUSTOMER_CONTACT_FAIL,
  GET_CUSTOMER_SOFT_SERVICE_SUCCESS,
  GET_CUSTOMER_SOFT_SERVICE_FAIL,
  GET_CUSTOMER_HARD_SERVICE_SUCCESS,
  GET_CUSTOMER_HARD_SERVICE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  customers: [],
  customer: {},
  error: {},

  // Contact
  contacts: [],
  contact: {},
  contactDetail: {
    isOpenDetail: false,
    idContact: "",
  },

  // Detail
  detailCusContacts: [],
  detailCusSofts: [],
  detailHards: [],
}

const Customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: [...state.customers, action.payload],
      }

    case ADD_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.map(customer =>
          customer.id.toString() === action.payload.id.toString()
            ? { customer, ...action.payload }
            : customer
        ),
      }

    case UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.filter(
          customer => customer.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // CONTACT
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
      }

    case GET_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: [...state.contacts, action.payload],
      }

    case ADD_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map(contact =>
          contact.id.toString() === action.payload.id.toString()
            ? { contact, ...action.payload }
            : contact
        ),
      }

    case UPDATE_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.filter(
          contact => contact.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case OPEN_DETAIL:
      return {
        ...state,
        contactDetail: action.payload,
      }
    case CLOSE_DETAIL:
      return {
        ...state,
        contactDetail: {
          isOpenDetail: false,
          idContact: "",
        },
      }

    // DETAIL

    case GET_CUSTOMER_CONTACT_SUCCESS:
      return {
        ...state,
        detailCusContacts: action.payload,
      }

    case GET_CUSTOMER_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMER_SOFT_SERVICE_SUCCESS:
      return {
        ...state,
        detailCusSofts: action.payload,
      }

    case GET_CUSTOMER_SOFT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMER_HARD_SERVICE_SUCCESS:
      return {
        ...state,
        detailHards: action.payload,
      }

    case GET_CUSTOMER_HARD_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Customer
