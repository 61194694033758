const servicePricing = [
    {
        id: 1,
        name: "Giá đào tạo từ xa",
        type: "training",
        pricing: 100000,
    },
    {
        id: 2,
        name: "Giá đào tạo trực tiếp tại địa chỉ khách hàng",
        type: "training",
        pricing: 100000,
    },
    {
        id: 3,
        name: "Giá thuê máy chấm công",
        type: "mcc",
        pricing: 100000,
    },
    {
        id: 4,
        name: "Giá mua máy chấm công",
        type: "mcc",
        pricing: 100000,
    },
    {
        id: 5,
        name: "&le; 50",
        type: "init fee",
        pricing: 100000,
    },
    {
        id: 6,
        name: "&le; 100",
        type: "init fee",
        pricing: 100000,
    },
    {
        id: 7,
        name: "&le; 300",
        type: "init fee",
        pricing: 100000,
    },
    {
        id: 8,
        name: "&le; 500",
        type: "init fee",
        pricing: 100000,
    },
    {
        id: 9,
        name: "> 500",
        type: "init fee",
        pricing: 100000,
    }
];

export { servicePricing }