import {
  GET_SERVICE_TYPES_SUCCESS,
  GET_SERVICE_TYPES_FAIL,
  ADD_SERVICE_TYPE_SUCCESS,
  ADD_SERVICE_TYPE_FAIL,
  UPDATE_SERVICE_TYPE_SUCCESS,
  UPDATE_SERVICE_TYPE_FAIL,
  DELETE_SERVICE_TYPE_SUCCESS,
  DELETE_SERVICE_TYPE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  serviceTypes: [],
  error: {},
}

const serviceType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_TYPES_SUCCESS:
      return {
        ...state,
        serviceTypes: action.payload,
      }

    case GET_SERVICE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_SERVICE_TYPE_SUCCESS:
      return {
        ...state,
        serviceTypes: [...state.serviceTypes, action.payload],
      }

    case ADD_SERVICE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_SERVICE_TYPE_SUCCESS:
      return {
        ...state,
        serviceTypes: state.serviceTypes.map(serviceType =>
          serviceType.id.toString() === action.payload.id.toString()
            ? { serviceType, ...action.payload }
            : serviceType
        ),
      }

    case UPDATE_SERVICE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SERVICE_TYPE_SUCCESS:
      return {
        ...state,
        serviceTypes: state.serviceTypes.filter(
          serviceType => serviceType.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_SERVICE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default serviceType
