import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
import {
    deleteServiceType
} from "../../store/actions"

// import { serviceTypes } from "../../common/data/serviceTypes"

const ServiceTypeTable = (props) => {

    const columns = [
        {
            title: 'Tên loại',
            width: 30,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Mô tả',
            width: 100,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 20,
            render: (text, record, index) => <>
                <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
            </>,
        },
    ]

    const { serviceTypes, onOpenModalEdit, onDeleteServiceType } = props;
    const [dataRow, setDataRow] = useState({});
    const [serviceTypeList, setServiceTypes] = useState([]);

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật loại dịch vụ"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        let data = [];
        serviceTypes?.map(serviceType => {
            data.push({
                key: serviceType.id,
                id: serviceType.id,
                name: serviceType.name,
                description: serviceType.description,
            })
        });
        setServiceTypes(data)
    }, [serviceTypes])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá loại dịch vụ "${row.name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                let data = { ...row };
                delete data["key"]
                let message = {
                    'action': 'delete',
                    'message': data,
                    "type_message": "map"
                }
                // console.log(message);
                // onDeleteServiceType(message);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <Edit dataRow={dataRow}></Edit>
            <Table
                columns={columns}
                dataSource={serviceTypeList}
                scroll={{
                    x: 1500,
                }}
            />
        </>

    );

};

ServiceTypeTable.propTypes = {
    t: PropTypes.any,
    serviceTypes: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onOpenModalEdit: PropTypes.func,
    onDeleteServiceType: PropTypes.func,
}

const mapStateToProps = ({ serviceType }) => ({
    serviceTypes: serviceType.serviceTypes,
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onDeleteServiceType: (message) => dispatch(deleteServiceType(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTypeTable);