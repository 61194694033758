import { Space, Switch, Table, Tag, Transfer, InputNumber, Radio } from 'antd';
import difference from 'lodash/difference';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import {
    getUserAssignForm,
    getUserRole,
    addUserAssign,
    deleteUserAssign
} from "../../../store/actions"
import { connect } from "react-redux"
import moment from 'moment';
// import { getUserAssignForm } from 'helpers/backend_api/user_roles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns;
            const rowSelection = {
                getCheckboxProps: (item) => ({
                    disabled: listDisabled || item.disabled,
                }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .filter((item) => !item.disabled)
                        .map(({ key }) => key);
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },
                onSelect({ key }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };
            return (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems}
                    size="small"
                    style={{
                        pointerEvents: listDisabled ? 'none' : undefined,
                    }}
                    onRow={({ key, disabled: itemDisabled }) => ({
                        onClick: () => {
                            if (itemDisabled || listDisabled) return;
                            onItemSelect(key, !listSelectedKeys.includes(key));
                        },
                    })}
                />
            );
        }}
    </Transfer>
);

// dataSource lấy ra danh sách tất cả user
// TargetKeys ['1','2',...] chính là id của các record đang được chọn cho bảng bên phải và không nằm bên bảng phía bên trái

const leftTableColumns = [
    {
        dataIndex: 'user_name',
        title: 'Tên đăng nhập',
    },
];
const rightTableColumns = [
    {
        dataIndex: 'user_name',
        title: 'Tên đăng nhập',
    },
    {
        dataIndex: 'never_expire',
        title: 'Không hết hạn',
    },
    {
        dataIndex: 'expire_time',
        title: 'Thời gian hết hạn',
    },
];
const TransferUser = (props) => {

    const { onGetUserForm, users_unassign, users_role, onGetUserAssignRole, role_id, onAddUserAssign, onDeleteUserAssign, messageError } = props
    const [usersList, setUsers] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [isShowTime, setIsShowTime] = useState(false);
    const [fields, setFields] = useState({ expire_time: 0, never_expire: "Yes" });
    useEffect(() => {
        onGetUserForm();
        onGetUserAssignRole(role_id);
    }, [onGetUserForm, onGetUserAssignRole])

    useEffect(() => {
        if (!_.isEmpty(messageError)) {
            toast.error(messageError.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [messageError])

    useEffect(() => {
        let data = [];
        users_unassign?.map(user => {
            if (users_role?.some(code => code.user.id === user.user.id)) {
                let filter = users_role.filter(code => code.user.id === user.user.id)
                let dateString = 0
                if (filter[0].expire_time > 0) {
                    dateString = moment.unix(filter[0].expire_time).format("DD/MM/yyyy H:mm:ss");
                }
                data.push({
                    id: filter[0].id,
                    key: filter[0].user.id,
                    user_name: filter[0].user.user_name,
                    expire_time: dateString,
                    never_expire: filter[0].never_expire
                })
            } else {
                data.push({
                    id: user.id,
                    key: user.user.id,
                    user_name: user.user.user_name,
                    expire_time: user.expire_time,
                    never_expire: user.never_expire
                })
            }
        });
        setUsers(data)

        let user_has_assign = [];
        users_role?.map(user => {
            user_has_assign.push(user.user.id);
        })
        setTargetKeys(user_has_assign)

    }, [users_unassign, users_role, setTargetKeys])

    const submitTransfer = (direction, moveKeys) => {
        if (direction === "right") {
            moveKeys.map(key => {
                let message = {
                    action: "create",
                    message: {
                        user: key,
                        user_role: role_id,
                        expire_time: fields.expire_time,
                        never_expire: fields.never_expire
                    },
                    type_message: "map"
                }
                // console.log(message)
                onAddUserAssign(message);
            })
        } else if (direction === "left") {
            moveKeys.map(key => {
                let filter = users_role.filter(item => key === item.user.id)
                let message = {
                    action: "delete",
                    message: {
                        id: filter[0].id,
                    },
                    type_message: "map"
                }
                onDeleteUserAssign(message);
            })
        }
    }

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
        submitTransfer(direction, moveKeys);
    };

    const onChangeTime = (e) => {
        let myField = fields;
        if (e.target.value === 'No') {
            setIsShowTime(true)
        } else {
            setIsShowTime(false)
            myField["expire_time"] = 0
        }
        myField["never_expire"] = e.target.value
        setFields(myField)
    };

    const onChangeNumber = val => {
        let myField = fields;
        let next_time = moment().add(val, 'minutes');
        myField["expire_time"] = next_time.unix()
        setFields(myField)
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div className="row mb-3">
                <label htmlFor="input-name" className="col-sm-3 col-form-label">
                    Hết hạn thời gian
                </label>
                <div className="col-sm-9">
                    <Radio.Group onChange={onChangeTime} defaultValue="Yes">
                        <Radio.Button value="No">Có thời hạn</Radio.Button>
                        <Radio.Button value="Yes">Không bao giờ</Radio.Button>
                    </Radio.Group>
                    <br />
                    <Space
                        style={{
                            marginTop: 16,
                        }}
                    >
                        {isShowTime && (
                            <InputNumber min={0} addonAfter="phút" onChange={onChangeNumber} />
                        )}
                    </Space>
                    <br />


                </div>
            </div >
            <TableTransfer
                dataSource={usersList}
                targetKeys={targetKeys}
                showSearch={true}
                onChange={onChange}
                filterOption={(inputValue, item) =>
                    item.user_name.indexOf(inputValue) !== -1
                }
                leftColumns={leftTableColumns}
                rightColumns={rightTableColumns}
            />
            <Space
                style={{
                    marginTop: 16,
                }}
            >
            </Space>
        </>
    );
};

TransferUser.propTypes = {
    t: PropTypes.any,
    users_unassign: PropTypes.array,
    onGetUserAssignRole: PropTypes.func,
    role_id: PropTypes.string,
    users_role: PropTypes.array,
    onAddUserAssign: PropTypes.func,
    onDeleteUserAssign: PropTypes.func,
    messageError: PropTypes.any
}

const mapStateToProps = ({ user_role }) => ({
    users_unassign: user_role.users_unassign,
    users_role: user_role.users_role,
    messageError: user_role.error
})

const mapDispatchToProps = dispatch => ({
    onGetUserForm: () => dispatch(getUserAssignForm()),
    onGetUserAssignRole: (role_id) => dispatch(getUserRole(role_id)),
    onAddUserAssign: (user) => dispatch(addUserAssign(user)),
    onDeleteUserAssign: (user) => dispatch(deleteUserAssign(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransferUser);