import {
  OPEN_MODAL_ADD,
  CLOSE_MODAL_ADD,
  CHANGE_OPEN_MODAL_ADD,
  CHANGE_CLOSE_MODAL_ADD,

  OPEN_MODAL_EDIT,
  CLOSE_MODAL_EDIT,
  CHANGE_OPEN_MODAL_EDIT,
  CHANGE_CLOSE_MODAL_EDIT,

  OPEN_MODAL_DETAIL,
  CLOSE_MODAL_DETAIL,
  CHANGE_OPEN_MODAL_DETAIL,
  CHANGE_CLOSE_MODAL_DETAIL,

  OPEN_MODAL_TRANSFER,
  CLOSE_MODAL_TRANSFER,
} from "./actionTypes"

export const openModalAdd = (modal) => ({
  type: OPEN_MODAL_ADD,
  payload: modal
})

export const closeModalAdd = () => ({
  type: CLOSE_MODAL_ADD
})

export const openModalEdit = (modal) => ({
  type: OPEN_MODAL_EDIT,
  payload: modal
})

export const closeModalEdit = () => ({
  type: CLOSE_MODAL_EDIT
})

export const openModalDetail = (modal) => ({
  type: OPEN_MODAL_DETAIL,
  payload: modal
})

export const closeModalDetail = () => ({
  type: CLOSE_MODAL_DETAIL
})

export const openModalTransfer = (modal) => ({
  type: OPEN_MODAL_TRANSFER,
  payload: modal
})

export const closeModalTransfer = () => ({
  type: CLOSE_MODAL_TRANSFER
})
