import {
  GET_SOFTWARESERVICES_SUCCESS,
  GET_SOFTWARESERVICES_FAIL,
  ADD_SOFTWARESERVICE_SUCCESS,
  ADD_SOFTWARESERVICE_FAIL,
  UPDATE_SOFTWARESERVICE_SUCCESS,
  UPDATE_SOFTWARESERVICE_FAIL,
  DELETE_SOFTWARESERVICE_SUCCESS,
  DELETE_SOFTWARESERVICE_FAIL,
  SHOW_MESSAGE_SUCCESS_NOTIFICATION,
  SHOW_MESSAGE_ERROR_NOTIFICATION,
  DEPLOY_SOFTWARESERVICE_SUCCESS,
  DEPLOY_SOFTWARESERVICE_FAIL,
  RESET_PASSWORD_SOFTWARESERVICE_SUCCESS,
  RESET_PASSWORD_SOFTWARESERVICE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  softwareservices: [],
  softwareservice: {},
  error: {},
  errorSub: null,
  successNotification: null,
  errorNotification: null,
}

const SoftwareService = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SOFTWARESERVICES_SUCCESS:
      return {
        ...state,
        softwareservices: action.payload,
      }

    case GET_SOFTWARESERVICES_FAIL:
      return {
        ...state,
        errorSub: action.payload,
      }

    case ADD_SOFTWARESERVICE_SUCCESS:
      return {
        ...state,
        softwareservices: [...state.softwareservices, action.payload],
      }

    case ADD_SOFTWARESERVICE_FAIL:
      return {
        ...state,
        errorSub: action.payload,
      }

    case UPDATE_SOFTWARESERVICE_SUCCESS:
      return {
        ...state,
        softwareservices: state.softwareservices.map(softwareservice =>
          softwareservice.id.toString() === action.payload.id.toString()
            ? { softwareservice, ...action.payload }
            : softwareservice
        ),
      }

    case UPDATE_SOFTWARESERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SOFTWARESERVICE_SUCCESS:
      return {
        ...state,
        softwareservices: state.softwareservices.filter(
          softwareservice =>
            softwareservice.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_SOFTWARESERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // Message
    case SHOW_MESSAGE_SUCCESS_NOTIFICATION:
      return {
        ...state,
        successNotification: action.payload,
      }
    case SHOW_MESSAGE_ERROR_NOTIFICATION:
      return {
        ...state,
        errorNotification: action.payload,
      }

    // Deploy
    case DEPLOY_SOFTWARESERVICE_SUCCESS:
      return {
        ...state,
        softwareservices: state.softwareservices.map(softwareservice =>
          softwareservice.id.toString() === action.payload.id.toString()
            ? { softwareservice, ...action.payload }
            : softwareservice
        ),
      }

    case DEPLOY_SOFTWARESERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // RESET
    case RESET_PASSWORD_SOFTWARESERVICE_SUCCESS:
      return {
        ...state,
        softwareservices: state.softwareservices.map(softwareservice =>
          softwareservice.id.toString() === action.payload.id.toString()
            ? { softwareservice, ...action.payload }
            : softwareservice
        ),
      }

    case RESET_PASSWORD_SOFTWARESERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default SoftwareService
