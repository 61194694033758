import { useEffect, useState } from "react"
import _ from "lodash"

const Permission = ({
    myRole,
    permission,
    children
}) => {
    const [hasAccess, setHasAccess] = useState(false)

    useEffect(() => {
        if (!_.isEmpty(myRole)) {
            const hasPerm = myRole.filter(perm => perm.label === permission.label && perm.action === permission.action)
            // permission check
            if (hasPerm) {
                setHasAccess(true)
            }
        }
    }, [myRole, permission])

    if (hasAccess) {
        return (
            children
        )
    }

    return null
}

export default Permission
