import React, { useEffect, useState } from "react"
import { Alert, Button as ButtonAnt, Select as SelectAnt, Checkbox } from "antd"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { closeModalEdit, onUpdateSoftwarePackage } from "../../store/actions"
import Select from "react-select"

import {
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"
import { transform } from "lodash"

const Edit = props => {
  const CheckboxGroup = Checkbox.Group
  const {
    modalEdit,
    onCloseModalEdit,
    onEditSoftwarePackage,
    dataRow,
    productcomponents,
  } = props
  const [showMessage, setShowMessage] = useState(false)
  const [listProductComponent, setListProductComponent] = useState([])
  const [listDt, setListDt] = useState([])
  let [resultArr, setResultArr] = useState([])
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm()
  const [fields, setFields] = useState({})
  const [myError, setMyErrors] = useState({})
  const [groupSoft, setGroupSoft] = useState([])
  const [checkedLists, setCheckedLists] = useState(groupSoft.map(group => []))
  const [checkAlls, setCheckAlls] = useState(groupSoft.map(() => false))

  useEffect(() => {
    let data = []
    // Gom nhom
    let groups = []
    if (productcomponents.length > 0) {
      groups = _.groupBy(productcomponents, "group")
    }
    let newGroup = []
    Object.keys(groups).map(key => {
      newGroup.push({
        name: key,
        packages: groups[key],
      })
    })
    data.push({
      component: newGroup,
    })
    setGroupSoft(newGroup)

    //  Cũ

    let arrComponent = []
    // set checkedLists and checkAlls based on dataRow
    if (dataRow.component) {
      const formattedData = dataRow.component.map(innerList =>
        innerList.packages.map(item => item.id)
      )

      const checkList001 = (newGroup, formattedData) => {
        let result = []
        newGroup.length > 0 &&
          newGroup.map((group, groupIndex) => {
            let tempArr = []
            let resultCheck = []
            formattedData.forEach((checked, checkedIndex) => {
              let checkTrue = false // Khởi tạo giá trị ban đầu là false
              group.packages.forEach((checked1, checkedIndex1) => {
                checked.forEach((checked2, checkedIndex2) => {
                  if (checked1.id === checked2) {
                    // Sử dụng toán tử so sánh === để so sánh giá trị
                    checkTrue = true // Nếu tìm thấy giá trị tương ứng thì gán true cho biến checkTrue
                    tempArr.push(checked1.id) // Thêm giá trị checked1.id vào mảng tempArr
                  }
                })
              })
              checkTrue && resultCheck.push(tempArr)
              tempArr = []
            })
            result.push(resultCheck.length !== 0 ? resultCheck.flat() : null)
          })
        setResultArr(result)
        setCheckedLists(result)
        return result
      }
      const art = checkList001(newGroup, formattedData)

      const formattedData1 = art.map((innerList, i) => {
        if (
          innerList !== null &&
          innerList.length === newGroup[i].packages.length
        ) {
          return innerList.map(item => item)
        }
      })

      // // setCheckAlls(
      const check01 = []
      formattedData1.map((innerList, i) => {
        if (innerList !== undefined) {
          check01.push(true)
        } else {
          check01.push(false)
        }
      })

      // )

      setCheckAlls(check01)
    } else {
      setCheckedLists(groupSoft.map(() => []))
      setCheckAlls(groupSoft.map(() => false))
    }

    reset({
      id: dataRow.key,
      name: dataRow.name,
      component: arrComponent,
      specifications: dataRow.specifications,
    })
    let dataProductComponent = []
    productcomponents?.map(product => {
      dataProductComponent.push({
        value: product.id,
        label: product.name,
      })
    })
    setListProductComponent(dataProductComponent)
  }, [reset, dataRow, productcomponents])
  const component = watch("component")

  const handleOk = () => {
    onCloseModalEdit()
  }
  const handleCancel = () => {
    onCloseModalEdit()
  }

  const onSubmit = data => {
    let dataProductComponent = []
    if (validateInput()) {
      const flattenedArray = checkedLists.flat().filter(Boolean)
      let newData = {
        id: data.id,
        name: data.name,
        specifications: data.specifications,
        // group: data.group,
        component: flattenedArray,
      }
      let MergeData = { ...newData, ...fields }
      let message = {
        action: "update",
        message: MergeData,
        type_message: "map",
      }
      onEditSoftwarePackage(message)
      // setShowMessage(true)
      reset()
      setCheckedLists(groupSoft.map(group => []))
      setCheckAlls(groupSoft.map(() => false))
    }
  }
  const closeAlert = () => {
    setShowMessage(false)
  }

  if (showMessage) {
    setTimeout(closeAlert, 3000) // đóng thông báo sau 5 giây
  }
  const validateInput = () => {
    let isValid = true
    let errs = myError
    setMyErrors(errs)
    return isValid
  }

  const onChange = value => {
    setMyErrors(myError)
    fields.r_name = value
    setFields(fields)
  }
  // Check 1

  const [allChecked, setAllChecked] = useState(false)
  const handleChange1 = (checkedValues, groupIndex, val) => {
    const newCheckedList = [...checkedLists]
    newCheckedList[groupIndex] = checkedValues
    setCheckedLists(newCheckedList)
    if (checkedValues.length === groupSoft[groupIndex].packages.length) {
      const newCheckAlls = [...checkAlls]
      newCheckAlls[groupIndex] = true
      setCheckAlls(newCheckAlls)
    }
    if (checkedValues.length < groupSoft[groupIndex].packages.length) {
      const newCheckAlls = [...checkAlls]
      newCheckAlls[groupIndex] = false
      setCheckAlls(newCheckAlls)
    }
  }

  return (
    <>
      <Modal
        isOpen={modalEdit.isOpenModal}
        toggle={handleCancel}
        fullscreen={"lg"}
        size={"lg"}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={handleCancel}>{modalEdit.title}</ModalHeader>
          <ModalBody>
            <Container>
              <Row className="mb-3">
                <Col md={3}>
                  <Label>
                    Tên gói dịch vụ phần mềm
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <input
                      aria-invalid={errors.name ? "true" : "false"}
                      {...register("name", {
                        required: true,
                        // pattern: /^[a-zA-Z0-9 ]*$/,
                      })}
                      className="form-control"
                      placeholder="Nhập tên gói dịch vụ phần mềm..."
                    />
                    {errors.name && errors.name.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Tên gói dịch vụ phần mềm không được để trống
                      </span>
                    )}
                    {/* {errors.name && errors.name.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Tên tính năng không có ký tự đặc biệt
                      </span>
                    )} */}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={3}>
                  <Label>
                    Tính năng gói dịch vụ phần mềm
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <div style={{ maxHeight: "400px", overflow: "auto" }}>
                      <div className="row">
                        <div className="col col-md-6">
                          <h5>Nhóm tính năng</h5>
                        </div>
                        <div className="col col-md-6">
                          <h5>Tên tính năng</h5>
                        </div>
                      </div>
                      {groupSoft.length > 0 &&
                        groupSoft.map((group, groupIndex) => {
                          return (
                            <div
                              key={groupIndex}
                              className="row mt-3"
                              style={{ borderTop: "1px solid" }}
                            >
                              <div className="col col-md-6">
                                <Checkbox
                                  indeterminate={
                                    checkedLists[groupIndex] !== undefined &&
                                    checkedLists[groupIndex] !== null &&
                                    checkedLists[groupIndex].length > 0 &&
                                    checkedLists[groupIndex].length <
                                      groupSoft[groupIndex].packages.length
                                  }
                                  {...register("component")}
                                  onChange={(e, value) => {
                                    handleChange1(
                                      e.target.checked
                                        ? group.packages.map(pkg => pkg.id)
                                        : [],
                                      groupIndex,
                                      e.target.checked
                                    )

                                    setCheckAlls(prevCheckAlls => {
                                      const newCheckAlls = [...prevCheckAlls]
                                      newCheckAlls[groupIndex] =
                                        e.target.checked
                                      return newCheckAlls
                                    })
                                    setValue("component", checkedLists, {
                                      shouldValidate: true,
                                    })
                                  }}
                                  checked={checkAlls[groupIndex]}
                                  defaultValue={checkAlls[groupIndex]} // Add defaultValue
                                >
                                  {group.name}
                                </Checkbox>
                              </div>
                              <div className="col col-md-6">
                                {
                                  <style>
                                    {`
                              .ant-checkbox-group label{
                                  display: flex;
                                  margin: 0 !important;
                     
                              }
                              `}
                                  </style>
                                }

                                <CheckboxGroup
                                  style={{ display: "block" }}
                                  options={group.packages.map(pkg => ({
                                    value: pkg.id,
                                    label: pkg.name,
                                  }))}
                                  value={checkedLists[groupIndex]}
                                  onChange={checkedValues =>
                                    handleChange1(checkedValues, groupIndex)
                                  }
                                />
                              </div>
                            </div>
                          )
                        })}
                    </div>

                    {errors.customer_sm &&
                      errors.customer_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng chọn khách hàng
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <Label>
                    Mô tả gói dịch vụ phần mềm
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <textarea
                      {...register("specifications", {
                        required: true,
                      })}
                      className="form-control"
                      rows={4}
                      placeholder="Nhập vào mô tả gói dịch vụ phần mềm..."
                    />
                    {errors.specifications &&
                      errors.specifications.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Mô tả gói dịch vụ phần mềm không được để trống
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <ButtonAnt
              htmlType="submit"
              className="text-white"
              style={{ backgroundColor: "#6ECCAF" }}
            >
              Cập nhật
            </ButtonAnt>{" "}
            <ButtonAnt onClick={handleCancel}>Đóng</ButtonAnt>
          </ModalFooter>
        </Form>
      </Modal>
      {showMessage && (
        <Alert
          message="Cập nhật thành công"
          type="success"
          onClose={closeAlert}
          style={{
            position: "fixed",
            fontWeight: "600",
            top: "100px",
            right: "20px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 50px",
          }}
        />
      )}
    </>
  )
}
Edit.propTypes = {
  t: PropTypes.any,
  modalEdit: PropTypes.object,
  onCloseModalEdit: PropTypes.func,
}

const mapStateToProps = ({ softwarepackage, helper, productcomponent }) => ({
  modalEdit: helper.modalEdit,
  productcomponents: productcomponent.productcomponents,
})

const mapDispatchToProps = dispatch => ({
  onCloseModalEdit: () => dispatch(closeModalEdit()),
  onEditSoftwarePackage: message => dispatch(onUpdateSoftwarePackage(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
