import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_SERVICE_CUSTOMER,
  DELETE_SERVICE_CUSTOMER,
  GET_SERVICE_CUSTOMER,
  UPDATE_SERVICE_CUSTOMER,
} from "./actionTypes"
import {
  getServiceCustomerSuccess,
  getServiceCustomerFail,
  addServiceCustomerFail,
  addServiceCustomerSuccess,
  updateServiceCustomerSuccess,
  updateServiceCustomerFail,
  deleteServiceCustomerSuccess,
  deleteServiceCustomerFail,
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getServiceCustomers,
  addNewServiceCustomer,
  // updateServiceCustomer,
  // deleteServiceCustomer
} from "../../helpers/backend_api/serviceCustomers"

function* fetchServiceCustomer() {
  try {
    const response = yield call(getServiceCustomers)
    if (response.code === 200) {
      yield put(getServiceCustomerSuccess(response.message))
    }
    else {
      yield put(getServiceCustomerFail(error))
    }
  } catch (error) {
    yield put(getServiceCustomerFail(error))
  }
}

function* onAddNewServiceCustomer({ payload: serviceCustomer }) {
  try {
    const response = yield call(addNewServiceCustomer, serviceCustomer);
    if (response.code === 201) {
      const serviceCustomers = yield call(getServiceCustomers)
      if (serviceCustomers.code === 200) {
        yield put(getServiceCustomerSuccess(serviceCustomers.message))
        yield put(closeModalAdd())
      }
      else {
        yield put(getServiceCustomerFail(error))
      }
    }
  } catch (error) {
    yield put(addServiceCustomerFail(error))
  }
}

function* onUpdateServiceCustomer({ payload: serviceCustomer }) {
  try {
    const response = yield call(updateServiceCustomer, serviceCustomer);
    if (response.code === 200) {
      const serviceCustomersRes = yield call(getServiceCustomers)
      if (serviceCustomersRes.code === 200) {
        yield put(getServiceCustomerSuccess(serviceCustomersRes.message))
        yield put(closeModalEdit())
      }
      else {
        yield put(getServiceCustomerFail(error))
      }
    }
  } catch (error) {
    yield put(updateServiceCustomerFail(error))
  }
}

function* onDeleteServiceCustomer({ payload: serviceCustomer }) {
  try {
    const response = yield call(deleteServiceCustomer, serviceCustomer);
    if (response.code === 200) {
      const serviceCustomersRes = yield call(getServiceCustomers)
      if (serviceCustomersRes.code === 200) {
        yield put(getServiceCustomerSuccess(serviceCustomersRes.message))
      }
      else {
        yield put(getServiceCustomerFail(error))
      }
    }
  } catch (error) {
    yield put(deleteServiceCustomerFail(error))
  }
}

function* serviceCustomerSaga() {
  yield takeEvery(GET_SERVICE_CUSTOMER, fetchServiceCustomer)
  yield takeEvery(ADD_NEW_SERVICE_CUSTOMER, onAddNewServiceCustomer)
  // yield takeEvery(UPDATE_SERVICE_CUSTOMER, onUpdateServiceCustomer)
  // yield takeEvery(DELETE_SERVICE_CUSTOMER, onDeleteServiceCustomer)
}

export default serviceCustomerSaga
