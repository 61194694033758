import {
  GET_SOFTWARESERVICES,
  GET_SOFTWARESERVICES_FAIL,
  GET_SOFTWARESERVICES_SUCCESS,
  ADD_NEW_SOFTWARESERVICE,
  ADD_SOFTWARESERVICE_SUCCESS,
  ADD_SOFTWARESERVICE_FAIL,
  UPDATE_SOFTWARESERVICE,
  UPDATE_SOFTWARESERVICE_SUCCESS,
  UPDATE_SOFTWARESERVICE_FAIL,
  DELETE_SOFTWARESERVICE,
  DELETE_SOFTWARESERVICE_SUCCESS,
  DELETE_SOFTWARESERVICE_FAIL,
  SHOW_MESSAGE_SUCCESS_NOTIFICATION,
  SHOW_MESSAGE_ERROR_NOTIFICATION,
  DEPLOY_SOFTWARESERVICE,
  DEPLOY_SOFTWARESERVICE_SUCCESS,
  DEPLOY_SOFTWARESERVICE_FAIL,
  RESET_PASSWORD_SOFTWARESERVICE,
  RESET_PASSWORD_SOFTWARESERVICE_SUCCESS,
  RESET_PASSWORD_SOFTWARESERVICE_FAIL,
} from "./actionTypes"

export const getSoftwareServices = () => ({
  type: GET_SOFTWARESERVICES,
})

export const getSoftwareServicesSuccess = softwareservices => ({
  type: GET_SOFTWARESERVICES_SUCCESS,
  payload: softwareservices,
})

export const getSoftwareServicesFail = error => ({
  type: GET_SOFTWARESERVICES_FAIL,
  payload: error,
})

export const addNewSoftwareService = softwareservice => ({
  type: ADD_NEW_SOFTWARESERVICE,
  payload: softwareservice,
})

export const addSoftwareServiceSuccess = softwareservice => ({
  type: ADD_SOFTWARESERVICE_SUCCESS,
  payload: softwareservice,
})

export const addSoftwareServiceFail = error => ({
  type: ADD_SOFTWARESERVICE_FAIL,
  payload: error,
})

export const onUpdateSoftwareService = softwareservice => ({
  type: UPDATE_SOFTWARESERVICE,
  payload: softwareservice,
})

export const updateSoftwareServiceSuccess = softwareservice => ({
  type: UPDATE_SOFTWARESERVICE_SUCCESS,
  payload: softwareservice,
})

export const updateSoftwareServiceFail = error => ({
  type: UPDATE_SOFTWARESERVICE_FAIL,
  payload: error,
})

export const deleteSoftwareService = softwareservice => ({
  type: DELETE_SOFTWARESERVICE,
  payload: softwareservice,
})

export const deleteSoftwareServiceSuccess = softwareservice => ({
  type: DELETE_SOFTWARESERVICE_SUCCESS,
  payload: softwareservice,
})

export const deleteSoftwareServiceFail = error => ({
  type: DELETE_SOFTWARESERVICE_FAIL,
  payload: error,
})

// Message

export const showSuccessNotification = message => ({
  type: SHOW_MESSAGE_SUCCESS_NOTIFICATION,
  payload: message,
})

export const showErrorNotification = message => ({
  type: SHOW_MESSAGE_ERROR_NOTIFICATION,
  payload: message,
})

// Deploy

export const deploySoftwareService = softwareservice => ({
  type: DEPLOY_SOFTWARESERVICE,
  payload: softwareservice,
})

export const deploySoftwareServiceSuccess = softwareservice => ({
  type: DEPLOY_SOFTWARESERVICE_SUCCESS,
  payload: softwareservice,
})

export const deploySoftwareServiceFail = error => ({
  type: DEPLOY_SOFTWARESERVICE_FAIL,
  payload: error,
})

// Reset Password

export const resetPasswordSoftwareService = softwareservice => ({
  type: RESET_PASSWORD_SOFTWARESERVICE,
  payload: softwareservice,
})

export const resetPasswordSoftwareServiceSuccess = softwareservice => ({
  type: RESET_PASSWORD_SOFTWARESERVICE_SUCCESS,
  payload: softwareservice,
})

export const resetPasswordSoftwareServiceFail = error => ({
  type: RESET_PASSWORD_SOFTWARESERVICE_FAIL,
  payload: error,
})
