import React, { useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewServiceType
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap"
import { useForm } from 'react-hook-form';
import ObjectID from 'bson-objectid';

const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewServiceType } = props;
    const [fields, setFields] = useState({});
    const [myError, setMyErrors] = useState({});
    const [packages, setPackages] = useState([])

    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleOk = () => {
        onCloseModalAdd();
    };
    const handleCancel = () => {
        onCloseModalAdd();
    };

    function handleAddRowNested() {
        let objId = new ObjectID();
        const item = { id: objId.toHexString(), package_name: "" }
        setPackages([...packages, item])
    }

    function handleRemoveRowNested(e, idx) {
        let newPacks = packages.filter(pack => pack.id !== packages[idx].id)
        setPackages(newPacks)
    }

    const handleInputNested = (e, idx) => {
        packages[idx].package_name = e.target.value;
        setPackages(packages)
    }

    const onSubmit = data => {
        let newData = {
            name: data.name,
            package_list: packages,
        }
    };

    const validateInput = () => {
        let isValid = true;
        let errs = myError;
        if (!fields.r_name) {
            errs.r_name = {
                type: 'required',
                message: 'Vai trò không được phép để trống'
            }
            isValid = false;
        }
        setMyErrors(errs);
        return isValid;
    }

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Tên loại dịch vụ <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            aria-invalid={errors.name ? "true" : "false"}
                                            {...register('name', {
                                                required: true,
                                            })}
                                            className="form-control"
                                            placeholder='Tên loại dịch vụ...'
                                        />
                                        {errors.name && errors.name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên loại dịch vụ không được để trống</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Mô tả nhóm dịch vụ</label>
                                </Col>
                                <Col md={8}>
                                    {packages.map((pack, idx) => (
                                        <div id={"nested" + idx} key={idx}>
                                            <Row className="mb-2">
                                                <Col md="10">
                                                    <Input
                                                        type="text"
                                                        className="inner form-control"
                                                        placeholder={
                                                            "Tên dịch vụ... " +
                                                            (idx + 1)
                                                        }
                                                        onChange={(e) => handleInputNested(e, idx)}
                                                    />
                                                </Col>
                                                <Col md="2">
                                                    <Button
                                                        onClick={e => {
                                                            handleRemoveRowNested(e, idx)
                                                        }}
                                                        type="button"
                                                        color="primary"
                                                        className="btn-block inner"
                                                        style={{ width: "100%" }}
                                                    >
                                                        {" "}
                                                        Delete{" "}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Button
                                        onClick={() => {
                                            handleAddRowNested()
                                        }}
                                        color="success"
                                        className="mt-1"
                                    >
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thêm mới
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func
}

const mapStateToProps = ({ user, helper }) => ({
    modalAdd: helper.modalAdd
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewServiceType: (message) => dispatch(addNewServiceType(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);