import React, { useEffect, useState } from 'react';
import { Table, Modal, DatePicker } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CHANGE_OPEN_MODAL_DETAIL } from 'store/helper/actionTypes';
import Permission from 'permissions/Permission';
import Show from "./Show"
import moment from 'moment';

const SystemLogTable = (props) => {

    const columns = [
        {
            title: 'Tài khoản',
            width: 50,
            dataIndex: 'user',
            key: 'user',
            fixed: 'left',
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            width: 150,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (text) => <>
                {text === 'Success' && (<span className='text-success'>{text}</span>)}
                {text === 'Error' && (<span className='text-danger'>{text}</span>)}
            </>,
        },
        {
            title: 'Ghi chú',
            dataIndex: 'notes',
            key: 'notes',
            width: 100,
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'remote_address',
            key: 'remote_address',
            width: 100,
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 100,
            render: (text) => <>
                {moment(text, 'YYYY/MM/DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}
            </>,
        },
        {
            title: 'Hoạt động',
            key: 'operation',
            fixed: 'right',
            width: 50,
            render: (text, record, index) => <>
                <Permission myRole={myRole} permission={{ label: 'system log', action: "view" }}>
                    <button type="button" onClick={() => handleShow(record)} className="btn btn-outline-info btn-sm waves-effect waves-light me-1"><i className="fas fa-eye"></i></button>
                </Permission>
            </>,
        },
    ]

    const { systemLogs, onOpenModalDetail, totalPages, changePage, myRole } = props;
    const [systemLogsList, setSystemLogs] = useState([]);
    const [dataRow, setDataRow] = useState({});

    const handleShow = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Hiển thị chi tiết"
        }
        setDataRow(row)
        onOpenModalDetail(modal);
    }


    useEffect(() => {
        let data = [];
        systemLogs?.map((systemLog, id) => {
            data.push({
                key: id,
                user: systemLog.user,
                action: systemLog.action,
                status: systemLog.status,
                notes: systemLog.notes,
                remote_address: systemLog.remote_address,
                created_at: systemLog.created_at,
            })
        });
        setSystemLogs(data)
    }, [systemLogs])

    return (
        <>
            <Show dataRow={dataRow}></Show>
            <Table
                columns={columns}
                dataSource={systemLogsList}
                pagination={{
                    pageSize: 10,
                    total: totalPages,
                    onChange: (page) => {
                        changePage(page)
                    },
                    showSizeChanger: false
                }}
            />
        </>
    );

};

SystemLogTable.propTypes = {
    t: PropTypes.any,
    systemLogs: PropTypes.array,
    onOpenModalDetail: PropTypes.func,
    totalPages: PropTypes.number,
    changePage: PropTypes.func,
    myRole: PropTypes.array
}

const mapStateToProps = ({ systemLog, Login }) => ({
    systemLogs: systemLog.systemLogs.message,
    totalPages: systemLog.systemLogs.total_page,
    myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
    onOpenModalDetail: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_DETAIL, payload: modal }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SystemLogTable);