import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getRoles = () => get(url.GET_ROLES)
const addNewRole = (data) => post(url.GET_ROLES, data)
const updateRole = (data) => post(url.GET_ROLES, data)
const deleteRole = (data) => post(url.GET_ROLES, data)
const getConstraints = () => get(url.GET_ROLE_TYPE)
export {
    getRoles,
    addNewRole,
    updateRole,
    deleteRole,
    getConstraints
}
