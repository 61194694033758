import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getDeviceServices = () => get(url.GET_DEVICE_SERVICES)
const addNewDeviceService = (data) => post(url.GET_DEVICE_SERVICES, data)
const updateDeviceService = (data) => post(url.GET_DEVICE_SERVICES, data)
const deleteDeviceService = (data) => post(url.GET_DEVICE_SERVICES, data)

export {
    getDeviceServices,
    addNewDeviceService,
    updateDeviceService,
    deleteDeviceService
}
