import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
import {
    deleteDevicePackage
} from "../../store/actions"
import Permission from 'permissions/Permission';

// import { devicePackages } from "../../common/data/devicePackages"

const DevicePackageTable = (props) => {

    const columns = [
        {
            title: 'Loại thiết bị',
            width: 200,
            dataIndex: 'hardware_type_name',
            key: 'hardware_type_name',
        },
        {
            title: 'Tên thiết bị',
            width: 150,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Model',
            width: 200,
            dataIndex: 'hardware_model',
            key: 'hardware_model',
        },
        {
            title: 'Giá bán',
            width: 200,
            dataIndex: 'price_cost',
            key: 'price_cost',
        },
        {
            title: 'Giá thuê',
            width: 200,
            dataIndex: 'rent_cost',
            key: 'rent_cost',
        },
        {
            title: 'Thao tác',
            key: 'operation',
            width: 100,
            render: (text, record, index) => <>
                <Permission myRole={myRole} permission={{ label: 'hardware service pack', action: "update" }}>
                    <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                </Permission>

                <Permission myRole={myRole} permission={{ label: 'hardware service pack', action: "delete" }}>
                    <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
                </Permission>
            </>,
        },
    ]

    const { devicePackages, onOpenModalEdit, onDeleteDevicePackage, keyword, myRole } = props;
    const [dataRow, setDataRow] = useState({});
    const [devicePackageList, setDevicePackages] = useState([]);

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật gói thiết bị"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        if (keyword) {
            const filteredData = devicePackageList.filter(entry =>
                (entry?.name).toLowerCase().includes(keyword.toLowerCase())
            );
            setDevicePackages(filteredData);
        } else {
            let data = [];
            devicePackages?.map(devicePackage => {
                data.push({
                    key: devicePackage.id,
                    id: devicePackage.id,
                    name: devicePackage.name,
                    hardware_model: devicePackage.hardware_model,
                    hardware_type_name: devicePackage.hardware_type.name,
                    hardware_type: devicePackage.hardware_type.id,
                    price_cost_raw: devicePackage.price_cost,
                    rent_cost_raw: devicePackage.rent_cost,
                    price_cost: new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(devicePackage.price_cost),
                    rent_cost: new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(devicePackage.rent_cost)
                })
            });
            setDevicePackages(data)
        }

    }, [devicePackages, keyword])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá gói thiết bị "${row.name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                let data = { id: row.id };
                let message = {
                    'action': 'delete',
                    'message': data,
                    "type_message": "map"
                }
                // console.log(message);
                onDeleteDevicePackage(message);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <Edit dataRow={dataRow}></Edit>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                columns={columns}
                size={'small'}
                dataSource={devicePackageList}
            />
        </>

    );

};

DevicePackageTable.propTypes = {
    t: PropTypes.any,
    devicePackages: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onOpenModalEdit: PropTypes.func,
    onDeleteDevicePackage: PropTypes.func,
    keyword: PropTypes.string,
    myRole: PropTypes.array
}

const mapStateToProps = ({ devicePackage, Login }) => ({
    devicePackages: devicePackage.devicePackages,
    myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onDeleteDevicePackage: (message) => dispatch(deleteDevicePackage(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(DevicePackageTable);