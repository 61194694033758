import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import ChangePassword from "./ChangePassword"
import { CHANGE_OPEN_MODAL_EDIT, CHANGE_OPEN_MODAL_DETAIL } from 'store/helper/actionTypes';
import {
    closeModalDelete,
    deleteUser
} from "../../store/actions"
import Permission from 'permissions/Permission';

const UserTable = (props) => {

    const columns = [
        {
            title: 'Tài khoản',
            width: 50,
            dataIndex: 'user_name',
            key: 'user_name',
            fixed: 'left',
        },
        {
            title: 'Ngôn ngữ mặc định',
            dataIndex: 'default_language',
            key: 'default_language',
            width: 150,
        },
        // {
        //     title: 'Vai trò',
        //     dataIndex: 'roles',
        //     key: 'roles',
        //     width: 150,
        // },
        {
            title: 'Tắt thông báo',
            dataIndex: 'disable_notify',
            key: 'disable_notify',
            width: 100,
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 60,
            render: (text, record, index) => <>
                <Permission myRole={myRole} permission={{ label: 'user', action: "update" }}>
                    <button type="button" onClick={() => handleChangePassword(record)} className="btn btn-outline-info btn-sm waves-effect waves-light me-1"><i className="fas fa-key"></i></button>
                    <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                </Permission>

                <Permission myRole={myRole} permission={{ label: 'user', action: "delete" }}>
                    <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
                </Permission>
            </>,
        },
    ]

    const { users, onOpenModalEdit, onDeleteUser, onOpenModalDetail, keyword, myRole } = props;
    const [dataRow, setDataRow] = useState({});
    const [usersList, setUsers] = useState([]);

    const handleShow = (row) => {
        console.log(row);
    }

    const handleChangePassword = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Thay đổi mật khẩu"
        }
        setDataRow(row)
        onOpenModalDetail(modal)
    }

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật người dùng"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        if (keyword) {
            const filteredData = usersList.filter(entry =>
                (entry?.user_name).toLowerCase().includes(keyword.toLowerCase())
            );
            setUsers(filteredData);
        } else {
            let data = [];
            users?.map(user => {
                data.push({
                    key: user.id,
                    user_name: user.user_name,
                    default_language: user.default_language,
                    roles: "Admin, System Admin",
                    disable_notify: user.disable_notify,
                })
            });
            setUsers(data)
        }

    }, [users, keyword])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá người dùng "${row.user_name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                let newData = {
                    id: row.key,
                };
                let message = {
                    'action': 'delete',
                    'message': newData,
                    "type_message": "map"
                }
                // console.log(message);
                onDeleteUser(message);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <ChangePassword dataRow={dataRow}></ChangePassword>
            <Edit dataRow={dataRow}></Edit>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                columns={columns}
                size={'small'}
                dataSource={usersList}
            />
        </>

    );

};

UserTable.propTypes = {
    t: PropTypes.any,
    users: PropTypes.array,
    onGetUsers: PropTypes.func,
    onOpenModalEdit: PropTypes.func,
    onOpenModalDetail: PropTypes.func,
    keyword: PropTypes.string,
    myRole: PropTypes.array
}

const mapStateToProps = ({ user, Login }) => ({
    users: user.users,
    myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onOpenModalDetail: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_DETAIL, payload: modal }),
    onDeleteUser: (message) => dispatch(deleteUser(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);