import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Switch } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalDetail,
} from "../../store/actions"

import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';
import moment from 'moment';

const Show = (props) => {
    const { modalDetail, onCloseModalDetail, dataRow } = props;

    const handleCancel = () => {
        onCloseModalDetail();
    };

    return (
        <>
            <Modal isOpen={modalDetail.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <ModalHeader toggle={handleCancel}>{modalDetail.title}</ModalHeader>
                <ModalBody>

                    <Container>
                        <table className='table table-stripe'>
                            <tbody>
                                <tr>
                                    <th>Tài khoản</th>
                                    <td>{dataRow.user}</td>
                                </tr>
                                <tr>
                                    <th>Thao tác</th>
                                    <td>{dataRow.action}</td>
                                </tr>
                                <tr>
                                    <th>Trạng thái</th>
                                    <td>
                                        {dataRow.status === 'Success' && (<span className='text-success'>{dataRow.status}</span>)}
                                        {dataRow.status === 'Error' && (<span className='text-danger'>{dataRow.status}</span>)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Ghi chú</th>
                                    <td>{dataRow.notes}</td>
                                </tr>
                                <tr>
                                    <th>Địa chỉ</th>
                                    <td>{dataRow.remote_address}</td>
                                </tr>
                                <tr>
                                    <th>Ngày tạo</th>
                                    <td>{moment(dataRow.created_at, 'YYYY/MM/DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <ButtonAnt onClick={handleCancel}>
                        Đóng
                    </ButtonAnt>
                </ModalFooter>
            </Modal>
        </>
    );
};
Show.propTypes = {
    t: PropTypes.any,
    modalDetail: PropTypes.object,
    onCloseModalDetail: PropTypes.func
}

const mapStateToProps = ({ user, helper }) => ({
    modalDetail: helper.modalDetail
})

const mapDispatchToProps = dispatch => ({
    onCloseModalDetail: () => dispatch(closeModalDetail()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Show);