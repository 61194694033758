/* SERVICE_CUSTOMER */
export const GET_SERVICE_CUSTOMER = "GET_SERVICE_CUSTOMER"
export const GET_SERVICE_CUSTOMER_SUCCESS = "GET_SERVICE_CUSTOMER_SUCCESS"
export const GET_SERVICE_CUSTOMER_FAIL = "GET_SERVICE_CUSTOMER_FAIL"

export const ADD_NEW_SERVICE_CUSTOMER = "ADD_NEW_SERVICE_CUSTOMER"
export const ADD_SERVICE_CUSTOMER_SUCCESS = "ADD_SERVICE_CUSTOMER_SUCCESS"
export const ADD_SERVICE_CUSTOMER_FAIL = "ADD_SERVICE_CUSTOMER_FAIL"

export const UPDATE_SERVICE_CUSTOMER = "UPDATE_SERVICE_CUSTOMER"
export const UPDATE_SERVICE_CUSTOMER_SUCCESS = "UPDATE_SERVICE_CUSTOMER_SUCCESS"
export const UPDATE_SERVICE_CUSTOMER_FAIL = "UPDATE_SERVICE_CUSTOMER_FAIL"

export const DELETE_SERVICE_CUSTOMER = "DELETE_SERVICE_CUSTOMER"
export const DELETE_SERVICE_CUSTOMER_SUCCESS = "DELETE_SERVICE_CUSTOMER_SUCCESS"
export const DELETE_SERVICE_CUSTOMER_FAIL = "DELETE_SERVICE_CUSTOMER_FAIL"
