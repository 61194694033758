import {
  GET_DEVICE_TYPES,
  GET_DEVICE_TYPES_FAIL,
  GET_DEVICE_TYPES_SUCCESS,
  ADD_NEW_DEVICE_TYPE,
  ADD_DEVICE_TYPE_SUCCESS,
  ADD_DEVICE_TYPE_FAIL,
  UPDATE_DEVICE_TYPE,
  UPDATE_DEVICE_TYPE_SUCCESS,
  UPDATE_DEVICE_TYPE_FAIL,
  DELETE_DEVICE_TYPE,
  DELETE_DEVICE_TYPE_SUCCESS,
  DELETE_DEVICE_TYPE_FAIL,
} from "./actionTypes"

export const getDeviceTypes = () => ({
  type: GET_DEVICE_TYPES,
})

export const getDeviceTypesSuccess = deviceTypes => ({
  type: GET_DEVICE_TYPES_SUCCESS,
  payload: deviceTypes,
})

export const getDeviceTypesFail = error => ({
  type: GET_DEVICE_TYPES_FAIL,
  payload: error,
})

export const addNewDeviceType = deviceType => ({
  type: ADD_NEW_DEVICE_TYPE,
  payload: deviceType,
})

export const addDeviceTypeSuccess = deviceType => ({
  type: ADD_DEVICE_TYPE_SUCCESS,
  payload: deviceType,
})

export const addDeviceTypeFail = error => ({
  type: ADD_DEVICE_TYPE_FAIL,
  payload: error,
})

export const updateDeviceType = deviceType => ({
  type: UPDATE_DEVICE_TYPE,
  payload: deviceType,
})

export const updateDeviceTypeSuccess = deviceType => ({
  type: UPDATE_DEVICE_TYPE_SUCCESS,
  payload: deviceType,
})

export const updateDeviceTypeFail = error => ({
  type: UPDATE_DEVICE_TYPE_FAIL,
  payload: error,
})

export const deleteDeviceType = deviceType => ({
  type: DELETE_DEVICE_TYPE,
  payload: deviceType,
})

export const deleteDeviceTypeSuccess = deviceType => ({
  type: DELETE_DEVICE_TYPE_SUCCESS,
  payload: deviceType,
})

export const deleteDeviceTypeFail = error => ({
  type: DELETE_DEVICE_TYPE_FAIL,
  payload: error,
})