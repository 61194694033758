/* USER_ROLE */
export const GET_USER_ROLE = "GET_USER_ROLE"
export const GET_USER_ROLE_SUCCESS = "GET_USER_ROLE_SUCCESS"
export const GET_USER_ROLE_FAIL = "GET_USER_ROLE_FAIL"

export const GET_USER_ASSIGN_FORM = "GET_USER_ASSIGN_FORM"
export const GET_USER_ASSIGN_FORM_SUCCESS = "GET_USER_ASSIGN_FORM_SUCCESS"
export const GET_USER_ASSIGN_FORM_FAIL = "GET_USER_ASSIGN_FORM_FAIL"

export const ADD_USER_ASSIGN = "ADD_USER_ASSIGN"
export const ADD_USER_ASSIGN_SUCCESS = "ADD_USER_ASSIGN_SUCCESS"
export const ADD_USER_ASSIGN_FAIL = "ADD_USER_ASSIGN_FAIL"

export const DELETE_USER_ASSIGN = "DELETE_USER_ASSIGN"
export const DELETE_USER_ASSIGN_SUCCESS = "DELETE_USER_ASSIGN_SUCCESS"
export const DELETE_USER_ASSIGN_FAIL = "DELETE_USER_ASSIGN_FAIL"


// OLD
export const ADD_NEW_USER_ROLE = "ADD_NEW_USER_ROLE"
export const ADD_USER_ROLE_SUCCESS = "ADD_USER_ROLE_SUCCESS"
export const ADD_USER_ROLE_FAIL = "ADD_USER_ROLE_FAIL"

export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE"
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS"
export const UPDATE_USER_ROLE_FAIL = "UPDATE_USER_ROLE_FAIL"

export const DELETE_USER_ROLE = "DELETE_USER_ROLE"
export const DELETE_USER_ROLE_SUCCESS = "DELETE_USER_ROLE_SUCCESS"
export const DELETE_USER_ROLE_FAIL = "DELETE_USER_ROLE_FAIL"
