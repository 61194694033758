import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Switch } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalTransfer,
    // assignUserToRole
} from "../../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';
import TransferUser from "./TransferUser";
const AssignToUser = (props) => {
    const { modalTransfer, onCloseModalTransfer, onAssignToUser, onGetUserRole, role_id } = props;

    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleCancel = () => {
        onCloseModalTransfer();
    };

    const onSubmit = data => {
        let message = {
            'action': 'create',
            'message': merge,
            "type_message": "map"
        }
        // onAssignToUser(message);
    };

    return (
        <>
            <Modal isOpen={modalTransfer.isOpenModal} toggle={handleCancel} fullscreen={"xl"} size={"xl"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalTransfer.title}</ModalHeader>
                    <ModalBody>
                        <Container>
                            <TransferUser role_id={role_id}></TransferUser>
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
AssignToUser.propTypes = {
    t: PropTypes.any,
    modalTransfer: PropTypes.object,
    onCloseModalTransfer: PropTypes.func,
    role_id: PropTypes.string
}

const mapStateToProps = ({ helper }) => ({
    modalTransfer: helper.modalTransfer,
})

const mapDispatchToProps = dispatch => ({
    onCloseModalTransfer: () => dispatch(closeModalTransfer()),
    // onAssignToUser: (message) => dispatch(assignUserToRole(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignToUser);