import React, { useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt} from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewProductComponent
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';

const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewProductComponent } = props;
    const [fields, setFields] = useState({});
    const [myError, setMyErrors] = useState({});

    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleOk = () => {
        onCloseModalAdd();
    };
    const handleCancel = () => {
        onCloseModalAdd();
    };
	
    const onSubmit = data => {
        if (validateInput()) {
            let newData = {
                name: data.name,
                decriptions: data.decriptions,			
                group: data.group,					
            };
			let MergeData = {...newData,...fields}
            let message = {
                'action': 'create',
                'message': MergeData,
                "type_message": "map"
            }
            onAddNewProductComponent(message);
        }

    };
    const validateInput = () => {
        let isValid = true;
        let errs = myError;
        setMyErrors(errs);
        return isValid;
    }
	
    const handleInput = (e) => {
        let currentFields = fields;
        currentFields[e.target.name] = e.target.value;
        setFields(currentFields);
    }	

    const onChange = (value) => {
        console.log(`selected ${value}`);
        delete myError["default_language"];
        setMyErrors(myError);
        fields.default_language = value;
        setFields(fields);
    };

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Tên tính năng<span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            aria-invalid={errors.name ? "true" : "false"}
                                            {...register('name', {
                                                required: true,
                                                pattern: /^[a-zA-Z ]*$/
                                            })}
                                            className="form-control"
                                            placeholder='Nhập tên tính năng...'
                                        />
                                        {errors.name && errors.name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên tính năng không được để trống</span>
                                        )}
                                        {errors.name && errors.name.type === "pattern" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên tính năng không có ký tự đặc biệt</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Nhóm tính năng<span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            aria-invalid={errors.group ? "true" : "false"}
                                            {...register('group', {
                                                required: true,
                                                pattern: /^[a-zA-Z ]*$/
                                            })}
                                            className="form-control"
                                            placeholder='Nhập vào nhóm tính năng...'
                                        />
                                        {errors.group && errors.group.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên nhóm tính năng không được để trống</span>
                                        )}
                                        {errors.group && errors.group.type === "pattern" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên nhóm tính năng không có ký tự đặc biệt</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>	
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Mô tả tính năng</Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <textarea
                                            {...register('decriptions', {
                                                required: false,
                                            })}
                                            className="form-control"
											rows={4}
                                            placeholder='Nhập vào mô tả tính năng...'
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>								
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thêm mới
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func
}

const mapStateToProps = ({ productcomponent, helper }) => ({
    modalAdd: helper.modalAdd
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewProductComponent: (message) => dispatch(addNewProductComponent(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);