import React, { useEffect, useState } from "react"
import { Table, Modal, Alert, Space, Button, Tooltip } from "antd"
import { PoweroffOutlined, SettingOutlined } from "@ant-design/icons"
import { queries } from "@testing-library/react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from "react-color"
import Edit from "./Edit"
import Detail from "./Detail"
import {
  CHANGE_OPEN_MODAL_EDIT,
  CHANGE_OPEN_MODAL_DETAIL,
} from "store/helper/actionTypes"
import {
  closeModalDelete,
  deleteSoftwareService,
  deploySoftwareService,
  resetPasswordSoftwareService,
} from "../../store/actions"

import _ from "lodash"
import Permission from "permissions/Permission"

const SoftwareServiceTable = props => {
  const [softwareservicesList, setSoftwareServices] = useState([])
  const [loadings, setLoadings] = useState([])
  const {
    softwareservices,
    onOpenModalEdit,
    onOpenModalDetail,
    onDeleteSoftwareService,
    onDeploySoftwareService,
    onResetPasswordSoftwareService,
    keyword,
    myRole

  } = props
  const [dataRow, setDataRow] = useState({})
  const [showMessage, setShowMessage] = useState(false)
  const handleShow = row => {
    let modal = {
      isOpenModal: true,
      title: "Thông tin về gói dịch vụ",
    }
    setDataRow(row)
    onOpenModalDetail(modal)
  }

  const handleEdit = row => {
    let modal = {
      isOpenModal: true,
      title: "Cập nhật thông tin dịch vụ phần mềm",
    }
    setDataRow(row)
    onOpenModalEdit(modal)
  }

  const handleDeploy = row => {
    setLoadings(prevLoadings => {
      const newLoadings = [...prevLoadings]
      newLoadings[row.key] = true
      return newLoadings
    })
    setTimeout(() => {
      setLoadings(prevLoadings => {
        const newLoadings = [...prevLoadings]
        newLoadings[row.key] = false
        return newLoadings
      })
    }, 3000)

    onDeploySoftwareService(row.key)
  }

  const handleDelete = row => {
    confirm(row)
  }

  const handleResetPassword = row => {
    confirmPassword(row)
  }

  useEffect(() => {
    if (keyword) {
      const filteredData = softwareservicesList.filter(entry =>
        (entry?.customer_name).toLowerCase().includes(keyword.toLowerCase())
      );
      setSoftwareServices(filteredData);
    } else {
      let data = []
      softwareservices?.map(softwareservice => {
        data.push({
          key: softwareservice.id,
          customer_name: softwareservice.customer.company_name,
          domain: softwareservice.domain,
          customer_id: softwareservice.customer.id,
          status: softwareservice.status,
          employee: softwareservice.employee,
          web_user: softwareservice.web_user,
          mobile_user: softwareservice.mobile_user,
          connective: softwareservice.connective,
          on_premise: softwareservice.on_premise,
          start_date: softwareservice.start_date,
          service_package: softwareservice.service_pack.name,
          service_pack_id: softwareservice.service_pack.id,
          terminate_date: softwareservice.terminate_date,
          one_time_payment: softwareservice.one_time_payment,
          billing_date: softwareservice.billing_date,
          billing_cycle: softwareservice.billing_cycle,
          billing_cycle_type: softwareservice.billing_cycle_type,
          is_deploy: softwareservice.is_deploy,
        })
      })
      setSoftwareServices(data)
    }

  }, [softwareservices, keyword])

  const columns = [
    {
      title: "Tên khách hàng",
      width: 60,
      dataIndex: "customer_name",
      key: "customer_name",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Tên miền",
      width: 60,
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Gói dịch vụ",
      width: 40,
      dataIndex: "service_package",
      key: "service_package",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 50,
      render: text => (
        <span
          className={` text-${text == "active" ? "success" : "warning"} btn-sm`}
          style={{ fontWeight: "bold", textTransform: "capitalize" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Ngày bắt đầu sử dụng",
      dataIndex: "start_date",
      key: "start_date",
      width: 60,
    },

    {
      title: "Ngày bắt đầu tính cước",
      dataIndex: "billing_date",
      key: "billing_date",
      width: 60,
    },
    {
      title: "Ngày ngưng dịch vụ",
      dataIndex: "terminate_date",
      key: "terminate_date",
      width: 60,
    },
    {
      title: "Thao tác",
      key: "operation",
      fixed: "right",
      width: 60,
      render: (text, record, index) => (
        <>
          {/* <button
              disabled={record.is_deploy == "Yes" ? true : false}
              type="button"
              onClick={() => handleDeploy(record)}
              className="btn btn-outline-info btn-sm waves-effect waves-light me-1"
              style={{
                opacity: record.is_deploy === "Yes" ? 0.5 : 1,
                pointerEvents: record.is_deploy === "Yes" ? "none" : "auto",
              }}
            >
              <i className="fas fa-cog"></i>
            </button> */}
          <Permission myRole={myRole} permission={{ label: 'software service', action: "update" }}>
            <Space direction="vertical">
              <Space wrap>
                <Button
                  className="btn btn-outline-info btn-sm  me-1"
                  disabled={
                    record.is_deploy == "Yes" || record.on_premise == "Yes"
                      ? true
                      : false
                  }
                  type="primary"
                  icon={<i className="fas fa-cog"></i>}
                  ghost
                  loading={loadings[record.key]}
                  onClick={() => handleDeploy(record)}
                  style={{ height: "28px" }}
                ></Button>
              </Space>
            </Space>
            <Space direction="vertical">
              <Space wrap>
                <Button
                  className="btn btn-outline-info btn-sm  me-1"
                  disabled={
                    record.is_deploy == "No" || record.on_premise == "Yes"
                      ? true
                      : false
                  }
                  type="primary"
                  icon={<i className="fas fa-key"></i>}
                  ghost
                  loading={loadings[record.key]}
                  onClick={() => handleResetPassword(record)}
                  style={{ height: "28px", color: "black" }}
                ></Button>
              </Space>
            </Space>
          </Permission>

          <button
            type="button"
            onClick={() => handleShow(record)}
            className="btn btn-outline-success btn-sm waves-effect waves-light me-1"
          >
            <i className="fas fa-eye"></i>
          </button>
          <Permission myRole={myRole} permission={{ label: 'software service', action: "update" }}>
            <button
              type="button"
              onClick={() => handleEdit(record)}
              className="btn btn-outline-warning btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-edit"></i>
            </button>
          </Permission>
          <Permission myRole={myRole} permission={{ label: 'software service', action: "delete" }}>
            <button
              type="button"
              disabled={record.status !== "terminate" ? true : false}
              onClick={() => handleDelete(record)}
              className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-trash"></i>
            </button>
          </Permission>
        </>
      ),
    },
  ]

  const confirm = row => {
    Modal.confirm({
      title: "Bạn có chắc",
      icon: "",
      content: `Bạn có muốn xoá gói phần mềm "${row.service_package}" ra khỏi khách hàng "${row.customer_name}"`,
      okText: "Đồng ý",
      cancelText: "Đóng",
      onOk: () => {
        let newData = {
          id: row.key,
        }
        let message = {
          action: "delete",
          message: newData,
          type_message: "map",
        }
        onDeleteSoftwareService(message)
        // setShowMessage(true)
      },
      okButtonProps: {
        style: {
          backgroundColor: "#ff4d4f",
        },
      },
      centered: true,
    })
  }

  const confirmPassword = row => {
    Modal.confirm({
      title: "Reset mật khẩu",
      icon: "",
      content: `Bạn có muốn reset mật khẩu`,
      okText: "Đồng ý",
      cancelText: "Đóng",
      onOk: () => {
        onResetPasswordSoftwareService(row.key)
      },
      okButtonProps: {
        style: {
          backgroundColor: "#ff4d4f",
        },
      },
      centered: true,
    })
  }

  const closeAlert = () => {
    setShowMessage(false)
  }
  if (showMessage) {
    setTimeout(closeAlert, 3000) // đóng thông báo sau 3 giây
  }
  return (
    <>
      <Edit dataRow={dataRow}></Edit>
      <Detail dataRow={dataRow}></Detail>
      <Table
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
        columns={columns}
        size={'small'}
        dataSource={softwareservicesList}
        scroll={{
          x: 1500,
        }}
      />
      {showMessage && (
        <Alert
          message="Xóa thành công"
          type="success"
          onClose={closeAlert}
          style={{
            position: "fixed",
            fontWeight: "600",
            top: "100px",
            right: "20px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 50px",
          }}
        />
      )}
    </>
  )
}

SoftwareServiceTable.propTypes = {
  t: PropTypes.any,
  softwareservices: PropTypes.array,
  onGetSoftwareServices: PropTypes.func,
  onOpenModalEdit: PropTypes.func,
  onOpenModalDetail: PropTypes.func,
  keyword: PropTypes.string,
  myRole: PropTypes.array
}

const mapStateToProps = ({ softwareservice, Login }) => ({
  softwareservices: softwareservice.softwareservices,
  myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
  onOpenModalEdit: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
  onOpenModalDetail: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_DETAIL, payload: modal }),
  onDeleteSoftwareService: message => dispatch(deleteSoftwareService(message)),
  onDeploySoftwareService: message => dispatch(deploySoftwareService(message)),
  onResetPasswordSoftwareService: message =>
    dispatch(resetPasswordSoftwareService(message)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SoftwareServiceTable)
