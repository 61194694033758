import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getDevicePackages = () => get(url.GET_DEVICE_PACKAGES)
const addNewDevicePackage = (data) => post(url.GET_DEVICE_PACKAGES, data)
const updateDevicePackage = (data) => post(url.GET_DEVICE_PACKAGES, data)
const deleteDevicePackage = (data) => post(url.GET_DEVICE_PACKAGES, data)

export {
    getDevicePackages,
    addNewDevicePackage,
    updateDevicePackage,
    deleteDevicePackage
}
