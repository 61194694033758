import React, { useEffect, useState } from 'react';
import { Table, Modal, Tooltip } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
import {
    closeModalDelete,
    deleteProductComponent
} from "../../store/actions"
import Permission from 'permissions/Permission';

const ProductComponentTable = (props) => {

    const columns = [
        {
            title: 'Tên tính năng',
            width: 50,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Nhóm tính năng',
            dataIndex: 'group',
            key: 'group',
            width: 100,
        },
        {
            title: 'Mô tả tính năng',
            dataIndex: 'decriptions',
            key: 'decriptions',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 50,
            render: (text, record, index) => <>
                {/* <button type="button" onClick={() => handleShow(record)} className="btn btn-outline-info btn-sm waves-effect waves-light me-1"><i className="fas fa-eye"></i></button> */}
                <Permission myRole={myRole} permission={{ label: 'product component', action: "update" }}>
                    <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                </Permission>
                <Permission myRole={myRole} permission={{ label: 'product component', action: "delete" }}>
                    <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
                </Permission>

            </>,
        },
    ]

    const { productcomponents, onOpenModalEdit, onDeleteProductComponent, keyword, myRole } = props;
    const [dataRow, setDataRow] = useState({});
    const [productcomponentsList, setProductComponents] = useState([]);

    const handleShow = (row) => {
        console.log(row);
    }

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật tính năng phần mềm"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        if (keyword) {
            const filteredData = productcomponentsList.filter(entry =>
                (entry?.name).toLowerCase().includes(keyword.toLowerCase())
            );
            setProductComponents(filteredData);
        } else {
            let data = [];
            productcomponents?.map(productcomponent => {
                data.push({
                    key: productcomponent.id,
                    name: productcomponent.name,
                    decriptions: productcomponent.decriptions,
                    tags: productcomponent.tags,
                    group: productcomponent.group,
                })
            });
            setProductComponents(data)
        }

    }, [productcomponents, keyword])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá tính năng phần mềm "${row.name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                let newData = {
                    id: row.key,
                };
                let message = {
                    'action': 'delete',
                    'message': newData,
                    "type_message": "map"
                }
                onDeleteProductComponent(message);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <Edit dataRow={dataRow}></Edit>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                columns={columns}
                size={'small'}
                dataSource={productcomponentsList}

            />
        </>

    );

};

ProductComponentTable.propTypes = {
    t: PropTypes.any,
    productcomponents: PropTypes.array,
    onGetProductComponents: PropTypes.func,
    onOpenModalEdit: PropTypes.func,
    keyword: PropTypes.string,
    myRole: PropTypes.array
}

const mapStateToProps = ({ productcomponent, Login }) => ({
    productcomponents: productcomponent.productcomponents,
    myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onDeleteProductComponent: (message) => dispatch(deleteProductComponent(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductComponentTable);