import React, { useEffect, useState } from "react"
import { Table, Modal, Alert, Tooltip } from "antd"
import { queries } from "@testing-library/react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from "react-color"
import Edit from "./Edit"
import CustomersContactDetail from "./Detail"
import { CHANGE_OPEN_MODAL_EDIT } from "store/helper/actionTypes"
import { deleteContact, deleteCustomer } from "../../../store/actions"
import Permission from "permissions/Permission"
import { OPEN_DETAIL } from "store/customer/actionTypes"

const ContactTable = props => {
  const columns = [
    {
      title: "Khách hàng",
      width: 200,
      dataIndex: "company_name",
      key: "company_name",
      ellipsis: {
        showTitle: false,
      },
      render: text => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Tên người liên hệ",
      width: 150,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Chức danh",
      dataIndex: "job_title",
      key: "job_title",
      width: 150,
    },
    {
      title: "Email liên hệ",
      dataIndex: "email_contact",
      key: "email_contact",
      width: 150,
    },
    {
      title: "Số điện thoại liên hệ",
      dataIndex: "phone_contact",
      key: "phone_contact",
      width: 150,
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
      width: 150,
      render: text => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Thao tác",
      key: "operation",
      fixed: "right",
      width: 70,
      render: (text, record, index) => (
        <>
          {/* {Permission({ label: "customer contact", action: "update" }) && (
            <button
              type="button"
              onClick={() => handleShow(record)}
              className="btn btn-outline-info btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-eye"></i>
            </button>
          )} */}
          <Permission myRole={myRole} permission={{ label: "customer contact", action: "update" }}>
            <button
              type="button"
              onClick={() => handleEdit(record)}
              className="btn btn-outline-success btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-edit"></i>
            </button>
          </Permission>
          <Permission myRole={myRole} permission={{ label: "customer contact", action: "delete" }}>
            <button
              type="button"
              onClick={() => handleDelete(record)}
              className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-trash"></i>
            </button>
          </Permission>
        </>
      ),
    },
  ]

  const {
    contacts,
    onOpenModalEdit,
    onDeleteContact,
    onOpenDetail,
    contactDetail,
    keyword,
    myRole
  } = props
  const [dataRow, setDataRow] = useState({})
  const [contactsList, setContacts] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const handleShow = row => {
    onOpenDetail({
      isOpenDetail: true,
      idContact: row,
    })
  }

  const handleEdit = row => {
    let modal = {
      isOpenModal: true,
      title: "Cập nhật thông tin liên hệ",
    }
    setDataRow(row)
    onOpenModalEdit(modal)
  }

  const handleDelete = row => {
    confirm(row)
  }

  useEffect(() => {
    if (keyword) {
      const filteredData = contactsList.filter(
        entry =>
          (entry?.company_name).toLowerCase().includes(keyword.toLowerCase()) ||
          (entry?.name).toLowerCase().includes(keyword.toLowerCase()) ||
          (entry?.phone_contact).toLowerCase().includes(keyword.toLowerCase())
      )
      setContacts(filteredData)
    } else {
      let data = []
      contacts?.map(contact => {
        data.push({
          key: contact.id,
          id: contact.id,
          company_name: contact.customer.company_name,
          company_id: contact.customer.id,
          job_title: contact.job_title,
          phone_contact: contact.phone_contact,
          name: contact.name,
          email_contact: contact.email_contact,
          notes: contact.notes,
        })
      })
      setContacts(data)
    }
  }, [contacts, keyword])

  const confirm = row => {
    Modal.confirm({
      title: "Bạn có chắc",
      icon: "",
      content: `Bạn có muốn xoá khách hàng "${row.company_name}"`,
      okText: "Đồng ý",
      cancelText: "Đóng",
      onOk: () => {
        let data = { id: row.id }
        let message = {
          action: "delete",
          message: data,
          type_message: "map",
        }
        // console.log(message);
        onDeleteContact(message)
        // setShowMessage(true)
      },
      okButtonProps: {
        style: {
          backgroundColor: "#ff4d4f",
        },
      },
      centered: true,
    })
  }
  const closeAlert = () => {
    setShowMessage(false)
  }
  if (showMessage) {
    setTimeout(closeAlert, 3000) // đóng thông báo sau 3 giây
  }

  return (
    <>
      <Edit dataRow={dataRow}></Edit>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={columns}
        size={"small"}
        dataSource={contactsList}
        scroll={{
          x: 1500,
        }}
      />
      {showMessage && (
        <Alert
          message="Xóa thành công"
          type="success"
          onClose={closeAlert}
          style={{
            position: "fixed",
            fontWeight: "600",
            top: "100px",
            right: "20px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 50px",
          }}
        />
      )}
    </>
  )
}

ContactTable.propTypes = {
  t: PropTypes.any,
  customers: PropTypes.array,
  contacts: PropTypes.array,
  onGetContacts: PropTypes.func,
  onGetCustomers: PropTypes.func,
  onOpenModalEdit: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  keyword: PropTypes.string,
  myRole: PropTypes.array
}

const mapStateToProps = ({ customer, Login }) => ({
  customers: customer.customers,
  contacts: customer.contacts,
  contactDetail: customer.contactDetail,
  myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
  onOpenModalEdit: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
  onOpenDetail: detail => dispatch({ type: OPEN_DETAIL, payload: detail }),
  onDeleteContact: message => dispatch(deleteContact(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactTable)
