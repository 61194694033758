import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"

// Login Redux States
import { GET_MY_ROLE, LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess, getMyRoleSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  postLogin, getMyRole
} from "../../../helpers/backend_api/Authenticate"

function mergeArray() {

}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      username: user.email,
      password: user.password,
    })
    if (response.code === 200) {
      localStorage.setItem("authUser", response.message.access_token)
      localStorage.setItem("refresh_token", response.message.refresh_token)
      const getRoleRes = yield call(getMyRole);
      if (getRoleRes.code === 200) {
        yield put(getMyRoleSuccess(getRoleRes.message))
      }
      yield put(loginSuccess(response))

      history.push("/customers")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* onGetMyRole() {
  try {
    const response = yield call(getMyRole)
    if (response.code === 200) {
      yield put(getMyRoleSuccess(response.message))
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("refresh_token")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    // history.push("/login")
    window.location.href = "/login";
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(GET_MY_ROLE, onGetMyRole)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
