import React, { useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewServiceType
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';

const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewServiceType } = props;
    const [fields, setFields] = useState({});
    const [myError, setMyErrors] = useState({});

    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleCancel = () => {
        onCloseModalAdd();
    };

    const onSubmit = data => {
        let message = {
            'action': 'create',
            'message': data,
            "type_message": "map"
        }
        // onAddNewServiceType(message);
    };

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Tên loại dịch vụ <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            aria-invalid={errors.name ? "true" : "false"}
                                            {...register('name', {
                                                required: true,
                                            })}
                                            className="form-control"
                                            placeholder='Tên loại dịch vụ...'
                                        />
                                        {errors.name && errors.name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên tài khoản không được để trống</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Mô tả</label>
                                </Col>
                                <Col md={8}>
                                    <textarea
                                        aria-invalid={errors.description ? "true" : "false"}
                                        {...register('description')}
                                        className="form-control"
                                        placeholder='Mô tả...'
                                    ></textarea>
                                </Col>
                            </Row>
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thêm mới
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func,
    onAddNewServiceType: PropTypes.func
}

const mapStateToProps = ({ helper }) => ({
    modalAdd: helper.modalAdd
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewServiceType: (message) => dispatch(addNewServiceType(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);