import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import _ from "lodash"
// Calender Redux States
import {
  GET_SYSTEM_LOGS,
} from "./actionTypes"
import {
  getSystemLogsSuccess,
  getSystemLogsFail,
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getSystemLogs,
} from "../../helpers/backend_api/systemLogs"

function* fetchSystemLogs({ payload: dateRange }) {
  yield put(getSystemLogsFail({}))

  try {
    let filter = "";
    if (!_.isEmpty(dateRange)) {
      filter = `&${Object.keys(dateRange).map((key) => [key, dateRange[key]].join('=')).join("&")}`;
    }
    const response = yield call(getSystemLogs, filter)
    if (response.code === 200) {
      yield put(getSystemLogsSuccess(response))
    }
    else {
      yield put(getSystemLogsFail(response))
    }
  } catch (error) {
    yield put(getSystemLogsFail(error))
  }
}

function* systemLogSaga() {
  yield takeLatest(GET_SYSTEM_LOGS, fetchSystemLogs)
}

export default systemLogSaga
