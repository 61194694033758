/* SERVICES */
export const GET_SERVICES = "GET_SERVICES"
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS"
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL"

export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE"
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS"
export const ADD_SERVICE_FAIL = "ADD_SERVICE_FAIL"

export const UPDATE_SERVICE = "UPDATE_SERVICE"
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS"
export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL"

export const DELETE_SERVICE = "DELETE_SERVICE"
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS"
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL"
