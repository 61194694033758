import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import ShowPackage from "./ShowPackage"
import { CHANGE_OPEN_MODAL_EDIT, CHANGE_OPEN_MODAL_DETAIL } from 'store/helper/actionTypes';
import { services } from "../../common/data/services"

const ServiceTable = (props) => {

    const columns = [
        {
            title: 'Tên dịch vụ',
            dataIndex: 'name',
            key: 'name',
            width: 300,
        },
        {
            title: 'Nhóm dịch vụ',
            align: "center",
            dataIndex: ['package_group_ids'],
            key: 'package_group_ids',
            width: 200,
            render: (record) => <>
                <button type="button" onClick={() => handleShowPack(record)} className="btn btn-outline-primary btn-sm waves-effect waves-light me-1"><i className="fas fa-cube"></i></button>
            </>
        },
        {
            title: 'Loại dịch vụ',
            align: "center",
            dataIndex: 'service_type',
            key: 'service_type',
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 200,
            render: (text, record, index) => <>
                <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
            </>,
        },
    ]

    const { customers, onOpenModalEdit, onOpenModalDetail } = props;
    const [dataRow, setDataRow] = useState({});
    const [packages, setPackages] = useState([]);
    const [serviceList, setServices] = useState([]);

    const handleShowPack = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Chi tiết gói dịch vụ"
        }
        setPackages(row);
        onOpenModalDetail(modal);

    }

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật loại dịch vụ"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        let data = [];
        services?.map(service => {
            data.push({
                key: service.id,
                name: service.name,
                package_group_ids: service.package_group_ids,
                service_type: service.service_type.name,
            })
        });
        setServices(data)
    }, [services])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá loại dịch vụ "${row.user_name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                console.log(row)
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <ShowPackage packages={packages}></ShowPackage>
            <Edit dataRow={dataRow}></Edit>
            <Table
                columns={columns}
                dataSource={serviceList}
                scroll={{
                    x: 1500,
                }}
            />
        </>

    );

};

ServiceTable.propTypes = {
    t: PropTypes.any,
    customers: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onOpenModalEdit: PropTypes.func,
    onOpenModalDetail: PropTypes.func,
}

const mapStateToProps = ({ customer }) => ({
    customers: customer.customers,
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onOpenModalDetail: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_DETAIL, payload: modal }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTable);