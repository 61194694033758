import React, { useEffect, useState } from "react"
import {
  Alert,
  Button as ButtonAnt,
  Select as SelectAnt,
  Checkbox,
  Button,
} from "antd"
import Select from "react-select"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { closeModalAdd, addNewSoftwarePackage } from "../../store/actions"
import {
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"

const Add = props => {
  const CheckboxGroup = Checkbox.Group
  const {
    modalAdd,
    onCloseModalAdd,
    onAddNewSoftwarePackage,
    productcomponents,
    softwarepackages,
  } = props
  const [fields, setFields] = useState({})
  const [myError, setMyErrors] = useState({})
  const [showMessage, setShowMessage] = useState(false)
  const [listProductComponent, setListProductComponent] = useState([])
  const [groupSoft, setGroupSoft] = useState([])
  const [expandedGroupIndex, setExpandedGroupIndex] = useState(null)
  const [checkedLists, setCheckedLists] = useState(groupSoft.map(group => []))
  const [checkAlls, setCheckAlls] = useState(groupSoft.map(() => false))
  const [allChecked, setAllChecked] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm()

  const handleOk = () => {
    onCloseModalAdd()
  }
  const handleCancel = () => {
    onCloseModalAdd()
  }
  useEffect(() => {
    let data = []
    // Gom nhom
    let groups = []
    if (productcomponents.length > 0) {
      groups = _.groupBy(productcomponents, "group")
    }
    let newGroup = []
    Object.keys(groups).map(key => {
      newGroup.push({
        name: key,
        packages: groups[key],
      })
    })
    data.push({
      component: newGroup,
    })
    setGroupSoft(newGroup)

    // CŨ
    let dataProductComponent = []
    productcomponents?.map(product => {
      dataProductComponent.push({
        value: product.id,
        label: product.name,
      })
    })
    setListProductComponent(dataProductComponent)
  }, [productcomponents])

  const onSubmit = data => {
    if (validateInput()) {
      const flattenedArray = checkedLists.flat().filter(Boolean)

      let newData = {
        name: data.name,
        specifications: data.specifications,
        // group: data.group,
        component: flattenedArray,
      }
      let MergeData = { ...newData, ...fields }
      let message = {
        action: "create",
        message: MergeData,
        type_message: "map",
      }
      onAddNewSoftwarePackage(message)
      // setShowMessage(true)
      reset()
      setCheckedLists(groupSoft.map(group => []))
      setCheckAlls(groupSoft.map(() => false))
    }
  }

  const closeAlert = () => {
    setShowMessage(false)
  }

  if (showMessage) {
    setTimeout(closeAlert, 3000) // đóng thông báo sau 5 giây
  }

  const validateInput = () => {
    let isValid = true
    let errs = myError
    setMyErrors(errs)
    return isValid
  }

  const handleInput = e => {
    let currentFields = fields
    currentFields[e.target.name] = e.target.value
    setFields(currentFields)
  }

  const onChange = value => {
    delete myError["default_language"]
    setMyErrors(myError)
    fields.default_language = value
    setFields(fields)
  }

  // Check 1

  const handleChange1 = (checkedValues, groupIndex, val) => {
    const newCheckedList = [...checkedLists]
    newCheckedList[groupIndex] = checkedValues
    setCheckedLists(newCheckedList)
    if (checkedValues.length === groupSoft[groupIndex].packages.length) {
      const newCheckAlls = [...checkAlls]
      newCheckAlls[groupIndex] = true
      setCheckAlls(newCheckAlls)
    }
    if (checkedValues.length < groupSoft[groupIndex].packages.length) {
      const newCheckAlls = [...checkAlls]
      newCheckAlls[groupIndex] = false
      setCheckAlls(newCheckAlls)
    }
  }

  return (
    <>
      <style>
        {`.custom-modal .modal-content {
    width: 110%;
    max-width: 1200px;
  }`}
      </style>
      <Modal
        isOpen={modalAdd.isOpenModal}
        toggle={handleCancel}
        fullscreen={"lg"}
        size={"lg"}
        className="custom-modal"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
          <ModalBody>
            <Container>
              <Row className="mb-3">
                <Col md={3}>
                  <Label>
                    Tên gói dịch vụ phần mềm
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <input
                      aria-invalid={errors.name ? "true" : "false"}
                      {...register("name", {
                        required: true,
                        // pattern: /^[a-zA-Z0-9 ]*$/,
                      })}
                      className="form-control"
                      placeholder="Nhập tên gói dịch vụ phần mềm..."
                    />
                    {errors.name && errors.name.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Tên gói dịch vụ phần mềm không được để trống
                      </span>
                    )}
                    {/* {errors.name && errors.name.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Tên phần mềm không có ký tự đặc biệt
                      </span>
                    )} */}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={3}>
                  <Label>
                    Tính năng gói dịch vụ phần mềm
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <div
                      style={{
                        maxHeight: "700px",
                        overflow: "auto",
                      }}
                    >
                      <div className="row">
                        <div className="col col-md-6">
                          <h5>Nhóm tính năng</h5>
                        </div>
                        <div className="col col-md-6">
                          <h5>Tên tính năng</h5>
                        </div>
                      </div>
                      {groupSoft.length > 0 &&
                        groupSoft.map((group, groupIndex) => {
                          const isExpanded = expandedGroupIndex === groupIndex
                          return (
                            <div
                              key={groupIndex}
                              className="row mt-3"
                              style={{ borderTop: "1px solid" }}
                            >
                              <div className="col col-md-6">
                                <Checkbox
                                  {...register("component")}
                                  onChange={(e, value) => {
                                    handleChange1(
                                      e.target.checked
                                        ? group.packages.map(pkg => pkg.id)
                                        : [],
                                      groupIndex,
                                      e.target.checked
                                    )

                                    setCheckAlls(prevCheckAlls => {
                                      const newCheckAlls = [...prevCheckAlls]
                                      newCheckAlls[groupIndex] =
                                        e.target.checked
                                      return newCheckAlls
                                    })
                                    setValue("component", checkedLists, {
                                      shouldValidate: true,
                                    })
                                    setExpandedGroupIndex(groupIndex)
                                  }}
                                  checked={checkAlls[groupIndex]}
                                  indeterminate={
                                    checkedLists[groupIndex] !== undefined &&
                                    checkedLists[groupIndex].length > 0 &&
                                    checkedLists[groupIndex].length <
                                      groupSoft[groupIndex].packages.length
                                  }
                                >
                                  {group.name}
                                </Checkbox>
                              </div>
                              <div className="col col-md-6">
                                {
                                  <style>
                                    {`
                              .ant-checkbox-group label{
                                  display: flex;
                                  margin: 0 !important;
                     
                              }
                              `}
                                  </style>
                                }
                                <div
                                  style={{
                                    textAlign: "left",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Button
                                    type="text"
                                    size="small"
                                    className={"text-primary"}
                                    onClick={() => {
                                      if (isExpanded) {
                                        setExpandedGroupIndex(null)
                                      } else {
                                        setExpandedGroupIndex(groupIndex)
                                      }
                                    }}
                                  >
                                    {isExpanded ? "Thu gọn -" : "Thêm +"}
                                  </Button>
                                </div>
                                <CheckboxGroup
                                  style={{
                                    display: isExpanded ? "block" : "none",
                                  }}
                                  options={group.packages.map(pkg => ({
                                    value: pkg.id,
                                    label: pkg.name,
                                  }))}
                                  value={checkedLists[groupIndex]}
                                  onChange={checkedValues =>
                                    handleChange1(checkedValues, groupIndex)
                                  }
                                />
                              </div>
                            </div>
                          )
                        })}
                    </div>

                    {/* {checkedLists.length == 0 && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng chọn tính năng
                      </span>
                    )} */}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <Label>
                    Mô tả gói dịch vụ phần mềm
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <textarea
                      {...register("specifications", {
                        required: true,
                      })}
                      className="form-control"
                      rows={4}
                      placeholder="Nhập vào mô tả gói dịch vụ phần mềm..."
                    />
                    {errors.specifications &&
                      errors.specifications.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Mô tả gói dịch vụ phần mềm không được để trống
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <ButtonAnt
              htmlType="submit"
              type="primary"
              disabled={checkedLists.length == 0}
            >
              Thêm mới
            </ButtonAnt>
            <ButtonAnt onClick={handleCancel}>Đóng</ButtonAnt>
          </ModalFooter>
        </Form>
      </Modal>
      {showMessage && (
        <Alert
          message="Thêm thành công"
          type="success"
          onClose={closeAlert}
          style={{
            position: "fixed",
            fontWeight: "600",
            top: "100px",
            right: "20px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 50px",
          }}
        />
      )}
    </>
  )
}
Add.propTypes = {
  t: PropTypes.any,
  modalAdd: PropTypes.object,
  onCloseModalAdd: PropTypes.func,
}

const mapStateToProps = ({ softwarepackage, helper, productcomponent }) => ({
  modalAdd: helper.modalAdd,
  productcomponents: productcomponent.productcomponents,
  softwarepackages: softwarepackage.softwarepackages,
})

const mapDispatchToProps = dispatch => ({
  onCloseModalAdd: () => dispatch(closeModalAdd()),
  onAddNewSoftwarePackage: message => dispatch(addNewSoftwarePackage(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Add)
