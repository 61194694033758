import React, { useEffect, useState } from "react"
import {
  Alert,
  Button as ButtonAnt,
  Radio,
  Select,
  Input,
  Select as SelectAnt,
} from "antd"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  closeModalEdit,
  onUpdateSoftwareService,
  updateContact,
} from "../../../store/actions"

import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import { Controller, useForm } from "react-hook-form"

const Edit = props => {
  const { modalEdit, onCloseModalEdit, dataRow, customers, onEditContact } =
    props
  const [listCustomers, setListCustomers] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const {
    control,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [fields, setFields] = useState({})
  const [myError, setMyErrors] = useState({})
  useEffect(() => {
    reset({
      key: dataRow.key,
      id: dataRow.id,
      customer: dataRow.company_id,
      email_contact: dataRow.email_contact,
      job_title: dataRow.job_title,
      name: dataRow.name,
      notes: dataRow.notes,
      phone_contact: dataRow.phone_contact,
    })
    let data = []
    let dataSoftwarePackage = []
    customers?.map(customer => {
      data.push({
        value: customer.id,
        label: customer.company_name,
      })
    })
    setListCustomers(data)
  }, [reset, dataRow, customers])

  const handleOk = () => {
    onCloseModalEdit()
  }
  const handleCancel = () => {
    onCloseModalEdit()
  }

  const onSubmit = data => {
    if (validateInput()) {
      let newData = {
        id: dataRow.id,
        customer: data.customer,
        email_contact: data.email_contact,
        job_title: data.job_title,
        name: data.name,
        notes: data.notes,
        phone_contact: data.phone_contact,
      }

      let message = {
        action: "update",
        message: newData,
        type_message: "map",
      }
      onEditContact(message)
      // setShowMessage(true)
    }
  }

  const validateInput = () => {
    let isValid = true
    let errs = myError
    setMyErrors(errs)
    return isValid
  }

  const onChange = value => {
    setMyErrors(myError)
    fields.r_name = value
    setFields(fields)
  }
  const statusSM = watch("status_sm")
  const oneTimepayment = watch("one_time_payment_sm")

  const closeAlert = () => {
    setShowMessage(false)
  }
  if (showMessage) {
    setTimeout(closeAlert, 3000) // đóng thông báo sau 3 giây
  }
  return (
    <>
      <Modal
        isOpen={modalEdit.isOpenModal}
        toggle={handleCancel}
        fullscreen={"lg"}
        size={"lg"}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={handleCancel}>{modalEdit.title}</ModalHeader>
          <ModalBody>
            <Container>
              <Row className="mb-3">
                <Col md={3}>
                  <Label>
                    Khách hàng
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <SelectAnt
                      {...register("customer", { required: true })}
                      value={watch("customer")}
                      onChange={value =>
                        setValue("customer", value, {
                          shouldValidate: true,
                        })
                      }
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn khách hàng"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={listCustomers}
                    />
                    {errors.customer && errors.customer.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng chọn khách hàng
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>
                    Tên người liên hệ
                    <span className="text-danger">*</span>{" "}
                  </label>
                </Col>
                <Col md={8}>
                  <input
                    name="name"
                    {...register("name", {
                      required: true,
                      // pattern: /^[a-zA-Z0-9 ]*$/,
                    })}
                    className="form-control"
                    placeholder="Tên người liên hệ..."
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span
                      role="alert"
                      className="text-danger"
                      style={{ fontSize: "13px" }}
                    >
                      Tên người liên hệ không được để trống
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>
                    Chức danh <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col md={8}>
                  <input
                    name="job_title"
                    {...register("job_title", {
                      required: true,
                      // pattern: /^[a-zA-Z0-9 ]*$/,
                    })}
                    className="form-control"
                    placeholder="Chức danh..."
                  />
                  {errors.job_title && errors.job_title.type === "required" && (
                    <span
                      role="alert"
                      className="text-danger"
                      style={{ fontSize: "13px" }}
                    >
                      Chức danh không được để trống
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>
                    Email liên hệ <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col md={8}>
                  <input
                    name="email_contact"
                    {...register("email_contact", {
                      required: true,
                      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    })}
                    className="form-control"
                    placeholder="Email liên hệ..."
                  />
                  {errors.email_contact &&
                    errors.email_contact.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Email liên hệ không được để trống
                      </span>
                    )}
                  {errors.email_contact &&
                    errors.email_contact.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Email không đúng định dạng
                      </span>
                    )}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={3}>
                  <label>
                    Số điện thoại liên hệ <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col md={8}>
                  <input
                    name="phone_contact"
                    type="number"
                    {...register("phone_contact", {
                      required: true,
                      pattern: /^\d*[0-9]\d*$/,
                    })}
                    className="form-control"
                    placeholder="Số điện thoại..."
                  />
                  {errors.phone_contact &&
                    errors.phone_contact.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Số điện thoại liên hệ không được để trống
                      </span>
                    )}
                  {errors.phone_contact &&
                    errors.phone_contact.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng nhập vào 1 số dương
                      </span>
                    )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>Ghi chú</label>
                </Col>
                <Col md={8}>
                  <textarea
                    name="notes"
                    {...register("notes")}
                    className="form-control"
                    placeholder="Ghi chú..."
                  ></textarea>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            {" "}
            <ButtonAnt
              htmlType="submit"
              className="text-white"
              style={{ backgroundColor: "#6ECCAF" }}
            >
              Cập nhật
            </ButtonAnt>{" "}
            <ButtonAnt onClick={handleCancel}>Đóng</ButtonAnt>
          </ModalFooter>
        </Form>
      </Modal>
      {showMessage && (
        <Alert
          message="Cập nhật thành công"
          type="success"
          onClose={closeAlert}
          style={{
            position: "fixed",
            fontWeight: "600",
            top: "100px",
            right: "20px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 50px",
          }}
        />
      )}
    </>
  )
}
Edit.propTypes = {
  t: PropTypes.any,
  modalEdit: PropTypes.object,
  onCloseModalEdit: PropTypes.func,
}

const mapStateToProps = ({ helper, customer }) => ({
  modalEdit: helper.modalEdit,
  customers: customer.customers,
})

const mapDispatchToProps = dispatch => ({
  onCloseModalEdit: () => dispatch(closeModalEdit()),
  onEditContact: message => dispatch(updateContact(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
