import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_DEVICE_TYPE,
  DELETE_DEVICE_TYPE,
  GET_DEVICE_TYPES,
  UPDATE_DEVICE_TYPE,
} from "./actionTypes"
import {
  getDeviceTypesSuccess,
  getDeviceTypesFail,
  addDeviceTypeFail,
  addDeviceTypeSuccess,
  updateDeviceTypeSuccess,
  updateDeviceTypeFail,
  deleteDeviceTypeSuccess,
  deleteDeviceTypeFail,
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getDeviceTypes,
  addNewDeviceType,
  updateDeviceType,
  deleteDeviceType
} from "../../helpers/backend_api/deviceTypes"

function* fetchDeviceTypes() {
  yield put(getDeviceTypesFail({}))

  try {
    const response = yield call(getDeviceTypes)
    if (response.code === 200) {
      yield put(getDeviceTypesSuccess(response.message))
    }
    else {
      yield put(getDeviceTypesFail(error))
    }
  } catch (error) {
    yield put(getDeviceTypesFail(error))
  }
}

function* onAddNewDeviceType({ payload: deviceType }) {
  try {
    const response = yield call(addNewDeviceType, deviceType);
    if (response.code === 201) {
      yield call(fetchDeviceTypes)
      yield put(closeModalAdd())
    }
    else {
      yield put(addDeviceTypeFail(response))
    }
  } catch (error) {
    yield put(addDeviceTypeFail(error))
  }
}

function* onUpdateDeviceType({ payload: deviceType }) {
  try {
    const response = yield call(updateDeviceType, deviceType);
    if (response.code === 200) {
      yield call(fetchDeviceTypes)
      yield put(closeModalEdit())
    }
    else {
      yield put(updateDeviceTypeFail(response))
    }
  } catch (error) {
    yield put(updateDeviceTypeFail(error))
  }
}

function* onDeleteDeviceType({ payload: deviceType }) {
  try {
    const response = yield call(deleteDeviceType, deviceType);
    if (response.code === 200) {
      yield call(fetchDeviceTypes)
    }
    else {
      yield put(deleteDeviceTypeFail(response))
    }
  } catch (error) {
    yield put(deleteDeviceTypeFail(error))
  }
}

function* deviceTypeSaga() {
  yield takeEvery(GET_DEVICE_TYPES, fetchDeviceTypes)
  yield takeEvery(ADD_NEW_DEVICE_TYPE, onAddNewDeviceType)
  yield takeEvery(UPDATE_DEVICE_TYPE, onUpdateDeviceType)
  yield takeEvery(DELETE_DEVICE_TYPE, onDeleteDeviceType)
}

export default deviceTypeSaga
