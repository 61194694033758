import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_PRODUCTCOMPONENT,
  DELETE_PRODUCTCOMPONENT,
  GET_PRODUCTCOMPONENTS,
  UPDATE_PRODUCTCOMPONENT,
} from "./actionTypes"
import {
  getProductComponentsSuccess,
  getProductComponentsFail,
  addProductComponentFail,
  addProductComponentSuccess,
  updateProductComponentSuccess,
  updateProductComponentFail,
  deleteProductComponentSuccess,
  deleteProductComponentFail,
} from "./actions"

import { closeModalAdd, closeModalEdit } from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getProductComponents,
  addNewProductComponent,
  updateProductComponent,
  deleteProductComponent,
} from "../../helpers/backend_api/productcomponents"
import { notification } from "antd"

const showErrorMessage = message => {
  notification.error({
    message: message,
    duration: 3, // Đặt thời gian tự động tắt (giây)
    style: { right: 0, zIndex: 9999 },
  })
}

const showSuccessMessage = message => {
  notification.success({
    message: message,
    duration: 3, // Đặt thời gian tự động tắt (giây)
    style: { right: 0, zIndex: 9999 },
  })
}
function* fetchProductComponents() {
  try {
    const response = yield call(getProductComponents)
    if (response.code === 200) {
      yield put(getProductComponentsSuccess(response.message))
    } else {
      yield put(getProductComponentsFail(error))
    }
  } catch (error) {
    yield put(getProductComponentsFail(error))
  }
}

function* onAddNewProductComponent({ payload: productcomponent }) {
  try {
    const response = yield call(addNewProductComponent, productcomponent)
    if (response.code === 201) {
      const response = yield call(getProductComponents)
      if (response.code === 200) {
        yield put(getProductComponentsSuccess(response.message))
        showSuccessMessage("Thêm thành công")
        yield put(closeModalAdd())
      } else {
        yield put(getProductComponentsFail(error))
        showErrorMessage(
          "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra"
        )
      }
    } else {
      yield put(getProductComponentsFail(error))
      showErrorMessage(
        "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra"
      )
    }
    // yield put(addProductComponentSuccess(response))
  } catch (error) {
    yield put(addProductComponentFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra"
    )
  }
}

function* onUpdateProductComponent({ payload: productcomponent }) {
  try {
    const response = yield call(updateProductComponent, productcomponent)
    if (response.code === 200) {
      const response = yield call(getProductComponents)
      if (response.code === 200) {
        yield put(getProductComponentsSuccess(response.message))
        showSuccessMessage("Cập nhật thành công")
        yield put(closeModalEdit())
      } else {
        yield put(getProductComponentsFail(error))
        showErrorMessage(
          "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra"
        )
      }
    } else {
      showErrorMessage(
        "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra"
      )
    }
  } catch (error) {
    yield put(updateProductComponentFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra"
    )
  }
}

function* onDeleteProductComponent({ payload: productcomponent }) {
  try {
    const response = yield call(deleteProductComponent, productcomponent)
    if (response.code === 200) {
      const response = yield call(getProductComponents)
      if (response.code === 200) {
        yield put(getProductComponentsSuccess(response.message))
        // yield put(deleteProductComponentSuccess(response))
        showSuccessMessage("Xóa thành công")
      } else {
        yield put(getProductComponentsFail(error))
        showErrorMessage(
          "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra"
        )
      }
    } else {
      yield put(deleteProductComponentFail(response))
      showErrorMessage(
        "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra"
      )
    }
  } catch (error) {
    yield put(deleteProductComponentFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra"
    )
  }
}

function* productcomponentSaga() {
  yield takeEvery(GET_PRODUCTCOMPONENTS, fetchProductComponents)
  yield takeEvery(ADD_NEW_PRODUCTCOMPONENT, onAddNewProductComponent)
  yield takeEvery(UPDATE_PRODUCTCOMPONENT, onUpdateProductComponent)
  yield takeEvery(DELETE_PRODUCTCOMPONENT, onDeleteProductComponent)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default productcomponentSaga
