import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getPackageGroups = () => get(url.GET_PACKAGE_GROUPS)
const addNewPackageGroup = (data) => post(url.GET_PACKAGE_GROUPS, data)

export {
    getPackageGroups,
    addNewPackageGroup
}
