/* SOFTWAREPACKAGES */
export const GET_SOFTWAREPACKAGES = "GET_SOFTWAREPACKAGES"
export const GET_SOFTWAREPACKAGES_SUCCESS = "GET_SOFTWAREPACKAGES_SUCCESS"
export const GET_SOFTWAREPACKAGES_FAIL = "GET_SOFTWAREPACKAGES_FAIL"

export const ADD_NEW_SOFTWAREPACKAGE = "ADD_NEW_SOFTWAREPACKAGE"
export const ADD_SOFTWAREPACKAGE_SUCCESS = "ADD_SOFTWAREPACKAGE_SUCCESS"
export const ADD_SOFTWAREPACKAGE_FAIL = "ADD_SOFTWAREPACKAGE_FAIL"

export const UPDATE_SOFTWAREPACKAGE = "UPDATE_SOFTWAREPACKAGE"
export const UPDATE_SOFTWAREPACKAGE_SUCCESS = "UPDATE_SOFTWAREPACKAGE_SUCCESS"
export const UPDATE_SOFTWAREPACKAGE_FAIL = "UPDATE_SOFTWAREPACKAGE_FAIL"

export const DELETE_SOFTWAREPACKAGE = "DELETE_SOFTWAREPACKAGE"
export const DELETE_SOFTWAREPACKAGE_SUCCESS = "DELETE_SOFTWAREPACKAGE_SUCCESS"
export const DELETE_SOFTWAREPACKAGE_FAIL = "DELETE_SOFTWAREPACKAGE_FAIL"