import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_SOFTWAREPACKAGE,
  DELETE_SOFTWAREPACKAGE,
  GET_SOFTWAREPACKAGES,
  UPDATE_SOFTWAREPACKAGE,
} from "./actionTypes"
import {
  getSoftwarePackagesSuccess,
  getSoftwarePackagesFail,
  addSoftwarePackageFail,
  addSoftwarePackageSuccess,
  updateSoftwarePackageSuccess,
  updateSoftwarePackageFail,
  deleteSoftwarePackageSuccess,
  deleteSoftwarePackageFail,
} from "./actions"

import { closeModalAdd, closeModalEdit } from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getSoftwarePackages,
  addNewSoftwarePackage,
  updateSoftwarePackage,
  deleteSoftwarePackage,
} from "../../helpers/backend_api/softwarepackages"
import { notification } from "antd"

const showErrorMessage = message => {
  notification.error({
    message: message,
    duration: 3, // Đặt thời gian tự động tắt (giây)
    style: { right: 0, zIndex: 9999 },
  })
}

const showSuccessMessage = message => {
  notification.success({
    message: message,
    duration: 3, // Đặt thời gian tự động tắt (giây)
    style: { right: 0, zIndex: 9999 },
  })
}
function* fetchSoftwarePackages() {
  try {
    const response = yield call(getSoftwarePackages)
    if (response.code === 200) {
      yield put(getSoftwarePackagesSuccess(response.message))
    } else {
      yield put(getSoftwarePackagesFail(error))
    }
  } catch (error) {
    yield put(getSoftwarePackagesFail(error))
  }
}

function* onAddNewSoftwarePackage({ payload: softwarepackage }) {
  try {
    const response = yield call(addNewSoftwarePackage, softwarepackage)
    if (response.code === 201) {
      const response = yield call(getSoftwarePackages)
      if (response.code === 200) {
        yield put(getSoftwarePackagesSuccess(response.message))
        showSuccessMessage("Thêm thành công")
        yield put(closeModalAdd())
      } else {
        yield put(getSoftwarePackagesFail(error))
        showErrorMessage(response.message)
      }
    } else {
      yield put(getSoftwarePackagesFail(error))
      showErrorMessage(
        "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
      )
    }
    // yield put(addSoftwarePackageSuccess(response))
  } catch (error) {
    yield put(addSoftwarePackageFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
    )
  }
}

function* onUpdateSoftwarePackage({ payload: softwarepackage }) {
  try {
    const response = yield call(updateSoftwarePackage, softwarepackage)
    if (response.code === 200) {
      const response = yield call(getSoftwarePackages)
      if (response.code === 200) {
        yield put(getSoftwarePackagesSuccess(response.message))
        yield put(closeModalEdit())
        showSuccessMessage("Cập nhật thành công")
      } else {
        yield put(getSoftwarePackagesFail(error))
        showErrorMessage(response.message)
      }
    } else {
      showErrorMessage(response.message)
    }
  } catch (error) {
    yield put(updateSoftwarePackageFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
    )
  }
}

function* onDeleteSoftwarePackage({ payload: softwarepackage }) {
  try {
    const response = yield call(deleteSoftwarePackage, softwarepackage)
    if (response.code === 200) {
      const response = yield call(getSoftwarePackages)
      if (response.code === 200) {
        yield put(getSoftwarePackagesSuccess(response.message))
        // yield put(deleteSoftwarePackageSuccess(response))
        showSuccessMessage("Xóa thành công")
      } else {
        yield put(getSoftwarePackagesFail(error))
        showErrorMessage(response.message)
      }
    } else {
      yield put(deleteSoftwarePackageFail(response))
      showErrorMessage(
        "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
      )
    }
  } catch (error) {
    yield put(deleteSoftwarePackageFail(error))
    showErrorMessage(
      "Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để kiểm tra."
    )
  }
}

function* softwarepackageSaga() {
  yield takeEvery(GET_SOFTWAREPACKAGES, fetchSoftwarePackages)
  yield takeEvery(ADD_NEW_SOFTWAREPACKAGE, onAddNewSoftwarePackage)
  yield takeEvery(UPDATE_SOFTWAREPACKAGE, onUpdateSoftwarePackage)
  yield takeEvery(DELETE_SOFTWAREPACKAGE, onDeleteSoftwarePackage)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default softwarepackageSaga
