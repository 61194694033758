import {
  GET_DEVICE_PACKAGES_SUCCESS,
  GET_DEVICE_PACKAGES_FAIL,
  ADD_DEVICE_PACKAGE_SUCCESS,
  ADD_DEVICE_PACKAGE_FAIL,
  UPDATE_DEVICE_PACKAGE_SUCCESS,
  UPDATE_DEVICE_PACKAGE_FAIL,
  DELETE_DEVICE_PACKAGE_SUCCESS,
  DELETE_DEVICE_PACKAGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  devicePackages: [],
  error: {},
}

const devicePackage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICE_PACKAGES_SUCCESS:
      return {
        ...state,
        devicePackages: action.payload,
      }

    case GET_DEVICE_PACKAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DEVICE_PACKAGE_SUCCESS:
      return {
        ...state,
        devicePackages: [...state.devicePackages, action.payload],
      }

    case ADD_DEVICE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_DEVICE_PACKAGE_SUCCESS:
      return {
        ...state,
        devicePackages: state.devicePackages.map(devicePackage =>
          devicePackage.id.toString() === action.payload.id.toString()
            ? { devicePackage, ...action.payload }
            : devicePackage
        ),
      }

    case UPDATE_DEVICE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DEVICE_PACKAGE_SUCCESS:
      return {
        ...state,
        devicePackages: state.devicePackages.filter(
          devicePackage => devicePackage.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_DEVICE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default devicePackage
