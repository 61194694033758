import React, { useEffect, useState } from "react"
import { Button as ButtonAnt, Select as SelectAnt } from "antd"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { closeModalDetail } from "../../store/actions"

import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"

const Detail = props => {
  const { modalDetail, onCloseModalDetail, onEditSoftwarePackage, dataRow } =
    props
  const handleCancel = () => {
    onCloseModalDetail()
  }
  const columns = [
    {
      title: "Tên gói dịch vụ phần mềm:",
      titleCol: 4,
      dataIndex: "name",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Mô tả gói dịch vụ phần mềm:",
      titleCol: 4,
      dataIndex: "specifications",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Tính năng của gói dịch vụ:",
      titleCol: 12,
      dataIndex: "component",
      dataCol: 12,
      unitType: "show-package",
    },
  ]

  return (
    <>
      <Modal
        isOpen={modalDetail.isOpenModal}
        toggle={handleCancel}
        fullscreen={"lg"}
        size={"lg"}
      >
        <ModalHeader toggle={handleCancel}>{modalDetail.title}</ModalHeader>
        <ModalBody>
          <Container>
            <>
              {columns.map((key, i) => {
                if (key.unitType == "index") {
                  return (
                    <Row key={i}>
                      <Col md={key.titleCol}>
                        <Label>{key.title}</Label>
                      </Col>
                      <Col md={key.dataCol}>{`${dataRow[key.dataIndex]} ${key.unitIndex
                        }`}</Col>
                    </Row>
                  )
                }
                if (key.unitType == "referrer") {
                  return (
                    <Row key={i}>
                      <Col md={key.titleCol}>
                        <Label>{key.title}</Label>
                      </Col>
                      <Col md={key.dataCol}>{`${dataRow[key.dataIndex]} ${dataRow[key.unitIndex]
                        }`}</Col>
                    </Row>
                  )
                }
                if (key.unitType == "show-package") {
                  return (
                    <Row key={i}>
                      <Col md={key.titleCol}>
                        <Label>{key.title}</Label>
                      </Col>
                      <Col
                        md={key.dataCol}
                        style={{ maxHeight: "550px", overflowY: "scroll" }}
                      >
                        <Table bordered hover maxheight="520px">
                          <thead>
                            <tr>
                              <th>Tên nhóm dịch vụ</th>
                              <th>Chi tiết nhóm dịch vụ</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(dataRow[key.dataIndex]) &&
                              dataRow[key.dataIndex].length > 0 &&
                              dataRow[key.dataIndex].map((pack, i) => (
                                <tr key={i}>
                                  <td>{pack.name}</td>
                                  <td>
                                    {pack.packages.length > 0 &&
                                      pack.packages.map((sub, y) => (
                                        <div key={y}>
                                          - {sub.name}
                                          <br />
                                        </div>
                                      ))}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )
                }
                return (
                  <Row key={i}>
                    <Col md={key.titleCol}>
                      <Label>{key.title}</Label>
                    </Col>
                    <Col md={key.dataCol}>{dataRow[key.dataIndex]}</Col>
                  </Row>
                )
              })}
            </>
          </Container>
        </ModalBody>
        <ModalFooter>
          <ButtonAnt onClick={handleCancel}>Đóng</ButtonAnt>
        </ModalFooter>
      </Modal>
    </>
  )
}
Detail.propTypes = {
  t: PropTypes.any,
  modalDetail: PropTypes.object,
  onCloseModalDetail: PropTypes.func,
}

const mapStateToProps = ({ softwarepackage, helper }) => ({
  modalDetail: helper.modalDetail,
})

const mapDispatchToProps = dispatch => ({
  onCloseModalDetail: () => dispatch(closeModalDetail()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
