import React, { useEffect, useState } from "react"
import {
  Alert,
  Button as ButtonAnt,
  Radio,
  Select,
  Input,
  Select as SelectAnt,
  InputNumber,
} from "antd"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { closeModalEdit, onUpdateSoftwareService } from "../../store/actions"

import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import { Controller, useForm } from "react-hook-form"

const Edit = props => {
  const {
    modalEdit,
    onCloseModalEdit,
    onEditSoftwareService,
    dataRow,
    customers,
    softwarepackages,
    // errorResponse,
    // errorNotification,
    // successNotification,
  } = props
  const [listCustomers, setListCustomers] = useState([])
  const [listSoftwarepackage, setListSoftwarepackage] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const {
    control,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      key: dataRow.key,
      customer_sm: "aa",
      status_sm: dataRow.status,
      employee_sm: dataRow.employee,
      web_user_sm: dataRow.web_user,
      mobile_user_sm: dataRow.mobile_user,
      //   connective_sm: dataRow.connective,
      on_premise_sm: dataRow.on_premise,
      start_date_sm: dataRow.start_date,
      service_pack_sm: dataRow.service_package,
      terminate_date_sm: dataRow.terminate_date,
    },
  })
  const [fields, setFields] = useState({})
  const [myError, setMyErrors] = useState({})

  useEffect(() => {
    reset({
      key: dataRow.key,
      customer_sm: dataRow.customer_id,
      domain_sm: dataRow.domain,
      status_sm: dataRow.status,
      employee_sm: dataRow.employee,
      web_user_sm: dataRow.web_user,
      mobile_user_sm: dataRow.mobile_user,
      connective_sm: dataRow.connective,
      one_time_payment_sm: dataRow.one_time_payment,
      on_premise_sm: dataRow.on_premise,
      start_date_sm: dataRow.start_date,
      service_pack_sm: dataRow.service_pack_id,
      terminate_date_sm: dataRow.terminate_date,
      billing_cycle_type_sm: dataRow.billing_cycle_type,
      billing_date: dataRow.billing_date,
      billing_cycle_sm: dataRow.billing_cycle,
    })
    let data = []
    let dataSoftwarePackage = []
    customers?.map(customer => {
      data.push({
        value: customer.id,
        label: customer.company_name,
      })
    })
    // Software packages
    softwarepackages?.map(softwarepackage => {
      dataSoftwarePackage.push({
        value: softwarepackage.id,
        label: softwarepackage.name,
      })
    })
    setListCustomers(data)
    setListSoftwarepackage(dataSoftwarePackage)
  }, [reset, dataRow, customers, softwarepackages])

  const handleOk = () => {
    onCloseModalEdit()
  }
  const handleCancel = () => {
    onCloseModalEdit()
  }

  const onSubmit = data => {
    if (validateInput()) {
      let newData = {
        id: dataRow.key,
        customer: data.customer_sm,
        domain: data.on_premise_sm == "No" ? data.domain_sm : "",
        service_pack: data.service_pack_sm,
        employee: Number(data.employee_sm),
        web_user: Number(data.web_user_sm),
        connective: Number(data.connective_sm),
        mobile_user: Number(data.mobile_user_sm),
        on_premise: data.on_premise_sm,
        one_time_payment: data.one_time_payment_sm,
        billing_cycle:
          data.one_time_payment_sm == "Yes"
            ? undefined
            : Number(data.billing_cycle_sm),
        billing_cycle_type:
          data.one_time_payment_sm == "Yes"
            ? undefined
            : data.billing_cycle_type_sm,
        status: data.status_sm,
        start_date: data.start_date_sm,
        billing_date:
          data.status_sm == "active" ? data.billing_date : undefined,
        terminate_date:
          data.status_sm == "terminate" ? data.terminate_date_sm : undefined,
      }
      let message = {
        action: "update",
        message: newData,
        type_message: "map",
      }
      onEditSoftwareService(message)
      // setShowMessage(true)
    }
  }
  const validateInput = () => {
    let isValid = true
    let errs = myError
    setMyErrors(errs)
    return isValid
  }

  const onChange = value => {
    setMyErrors(myError)
    fields.r_name = value
    setFields(fields)
  }
  const statusSM = watch("status_sm")
  const onPremiseSM = watch("on_premise_sm")
  const domainSM = watch("domain_sm")
  const oneTimepayment = watch("one_time_payment_sm")
  const closeAlert = () => {
    setShowMessage({ status: false, message: undefined })
  }
  if (showMessage) {
    setTimeout(closeAlert, 5000) // đóng thông báo sau 3 giây
  }
  const billingCycleType = [
    {
      value: "quarter",
      label: "Quý",
    },
    {
      value: "month",
      label: "Tháng",
    },
    {
      value: "year",
      label: "Năm",
    },
  ]
  return (
    <>
      <Modal
        isOpen={modalEdit.isOpenModal}
        toggle={handleCancel}
        fullscreen={"lg"}
        size={"lg"}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={handleCancel}>{modalEdit.title}</ModalHeader>
          <ModalBody>
            <Container>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Tên khách hàng<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Select
                      {...register("customer_sm")}
                      value={watch("customer_sm")}
                      onChange={value =>
                        setValue("customer_sm", value, { shouldValidate: true })
                      }
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn khách hàng..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={listCustomers}
                    />
                    {errors.customer_sm &&
                      errors.customer_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng chọn khách hàng
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              {/* {onPremiseSM === "No" && ( */}
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Tên miền <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      disabled={
                        onPremiseSM == "No"
                          ? dataRow.domain !== ""
                            ? true
                            : false
                          : true
                      }
                      type="text"
                      placeholder="Nhập tên miền..."
                      {...register("domain_sm", {
                        required: onPremiseSM == "No" ? true : false,
                        pattern:
                          onPremiseSM == "No"
                            ? /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
                            : false,
                      })}
                      value={watch("domain_sm")}
                      onChange={e =>
                        setValue("domain_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.domain_sm &&
                      errors.domain_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Tên miền không được để trống
                        </span>
                      )}

                    {errors.domain_sm &&
                      errors.domain_sm.type === "pattern" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Không đúng định dạng tên miền
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              {/* )} */}

              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Chọn gói dịch vụ phần mềm
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Select
                      {...register("service_pack_sm")}
                      value={watch("service_pack_sm")}
                      onChange={value =>
                        setValue("service_pack_sm", value, {
                          shouldValidate: true,
                        })
                      }
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn gói dịch vụ phần mềm..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={listSoftwarepackage}
                    />
                    {errors.service_pack_sm &&
                      errors.service_pack_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng chọn gói dịch vụ phần mềm
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Trạng thái<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="status_sm"
                      rules={{ required: "Vui lòng chọn trạng thái" }}
                      defaultValue="active"
                      value={watch("status_sm")}
                      render={({ field }) => (
                        <Radio.Group {...field}>
                          <Radio value={"active"}>Hoạt động</Radio>
                          <Radio value={"suspend"}>Tạm ngưng</Radio>
                          <Radio value={"terminate"}>Ngưng dịch vụ</Radio>
                          <Radio value={"experiment"}>Thử nghiệm</Radio>
                        </Radio.Group>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Số lượng nhân viên <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="number"
                      {...register("employee_sm", {
                        required: true,
                        pattern: /^\d*[0-9]\d*$/,
                      })}
                      placeholder="Nhập số lượng nhân viên..."
                      value={watch("employee_sm")}
                      onChange={e =>
                        setValue("employee_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.employee_sm &&
                      errors.employee_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập số lượng nhân viên
                        </span>
                      )}
                    {errors.employee_sm &&
                      errors.employee_sm.type === "pattern" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập vào 1 số dương
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Số lượng người dùng trên web
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="number"
                      {...register("web_user_sm", {
                        required: true,
                        pattern: /^\d*[0-9]\d*$/,
                      })}
                      value={watch("web_user_sm")}
                      onChange={e =>
                        setValue("web_user_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                      placeholder="Nhập số lượng người dùng trên web..."
                    />
                    {errors.web_user_sm &&
                      errors.web_user_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập số lượng người dùng trên web
                        </span>
                      )}
                    {errors.web_user_sm &&
                      errors.web_user_sm.type === "pattern" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập vào 1 số dương
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Số lượng người dùng trên ứng dụng di động
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="number"
                      {...register("mobile_user_sm", {
                        required: true,
                        pattern: /^\d*[0-9]\d*$/,
                      })}
                      value={watch("mobile_user_sm")}
                      onChange={e =>
                        setValue("mobile_user_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                      placeholder="Nhập số lượng người dùng trên ứng dụng di động..."
                    />
                    {errors.mobile_user_sm &&
                      errors.mobile_user_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập số lượng người dùng trên ứng dụng di
                          động
                        </span>
                      )}
                    {errors.mobile_user_sm &&
                      errors.mobile_user_sm.type === "pattern" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập vào 1 số dương
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Số lượng thiết bị kết nối
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="number"
                      {...register("connective_sm", {
                        required: true,
                        pattern: /^\d*[0-9]\d*$/,
                      })}
                      placeholder="Nhập số lượng thiết bị kết nối..."
                      value={watch("connective_sm")}
                      onChange={e =>
                        setValue("connective_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.connective_sm &&
                      errors.connective_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập số lượng thiết bị kết nối
                        </span>
                      )}
                    {errors.connective_sm &&
                      errors.connective_sm.type === "pattern" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập vào 1 số dương
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Premise<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="on_premise_sm"
                      rules={{ required: "Vui lòng chọn premise" }}
                      defaultValue="No"
                      value={watch("on_premise_sm")}
                      render={({ field }) => (
                        <Radio.Group
                          {...field}
                          disabled={domainSM !== "" ? true : false}
                        >
                          <Radio value={"Yes"}>Có</Radio>
                          <Radio value={"No"}>Không</Radio>
                        </Radio.Group>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Thanh toán 1 lần<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="one_time_payment_sm"
                      rules={{ required: "Vui lòng chọn premise" }}
                      defaultValue="No"
                      value={watch("one_time_payment_sm")}
                      render={({ field }) => (
                        <Radio.Group {...field}>
                          <Radio value={"Yes"}>Có</Radio>
                          <Radio value={"No"}>Không</Radio>
                        </Radio.Group>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Kỳ thanh toán<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <InputNumber
                    disabled={oneTimepayment == "Yes" ? true : false}
                    style={{
                      width: "100%",
                    }}
                    min={1}
                    addonAfter={
                      <Select
                        disabled={oneTimepayment == "Yes" ? true : false}
                        {...register("billing_cycle_type_sm", {
                          required:
                            oneTimepayment === "No"
                              ? "Vui lòng chọn kỳ thanh toán"
                              : false,
                        })}
                        onChange={value =>
                          setValue("billing_cycle_type_sm", value, {
                            shouldValidate: true,
                          })
                        }
                        // defaultValue="month"
                        value={watch("billing_cycle_type_sm")}
                      >
                        {billingCycleType.map(type => (
                          <Select.Option key={type.value} value={type.value}>
                            {type.label}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    {...register("billing_cycle_sm", {
                      required:
                        oneTimepayment === "No"
                          ? "Vui lòng nhập thời hạn chu kỳ thanh toán"
                          : false,
                      pattern: /^\d*[0-9]\d*$/,
                    })}
                    onChange={value =>
                      setValue("billing_cycle_sm", value, {
                        shouldValidate: true,
                      })
                    }
                    value={watch("billing_cycle_sm")}
                    // onChange={onChangeBilling("billing_cycle")}
                  />
                  {errors.billing_cycle_type_sm &&
                    errors.billing_cycle_type_sm.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        {errors.billing_cycle_type_sm.message}
                      </span>
                    )}
                  {errors.billing_cycle_sm &&
                    errors.billing_cycle_sm.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng nhập kỳ thanh toán
                      </span>
                    )}
                  {errors.billing_cycle_sm &&
                    errors.billing_cycle_sm.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng nhập vào 1 số dương
                      </span>
                    )}
                </Col>
              </Row>
              {/* <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Chu kỳ thanh toán<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Select
                      disabled={oneTimepayment == "Yes" ? true : false}
                      {...register("billing_cycle_type_sm", {
                        required:
                          oneTimepayment === "No"
                            ? "Vui lòng chọn gói dịch vụ phần mềm"
                            : false,
                      })}
                      value={watch("billing_cycle_type_sm")}
                      onChange={value =>
                        setValue("billing_cycle_type_sm", value, {
                          shouldValidate: true,
                        })
                      }
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn chu kỳ thanh toán"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "quarter",
                          label: "Quý",
                        },
                        {
                          value: "month",
                          label: "Tháng",
                        },
                        {
                          value: "year",
                          label: "Năm",
                        },
                      ]}
                    />
                    {errors.billing_cycle_type_sm &&
                      errors.billing_cycle_type_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          {errors.billing_cycle_type_sm.message}
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Thời hạn chu kỳ thanh toán
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <FormGroup>
                      <Input
                        disabled={oneTimepayment == "Yes" ? true : false}
                        placeholder="Vui lòng nhập số... "
                        type="number"
                        {...register("billing_cycle_sm", {
                          required:
                            oneTimepayment === "No"
                              ? "Vui lòng nhập thời hạn chu kỳ thanh toán"
                              : false,
                          pattern: /^\d*[0-9]\d*$/,
                        })}
                        value={watch("billing_cycle_sm")}
                        onChange={e =>
                          setValue("billing_cycle_sm", e.target.value, {
                            shouldValidate: true,
                          })
                        }
                      />
                      {errors.billing_cycle_sm &&
                        errors.billing_cycle_sm.type === "required" && (
                          <span
                            role="alert"
                            className="text-danger"
                            style={{ fontSize: "13px" }}
                          >
                            Vui lòng nhập thời hạn chu kỳ thanh toán
                          </span>
                        )}
                      {errors.billing_cycle_sm &&
                        errors.billing_cycle_sm.type === "pattern" && (
                          <span
                            role="alert"
                            className="text-danger"
                            style={{ fontSize: "13px" }}
                          >
                            Vui lòng nhập vào 1 số dương
                          </span>
                        )}
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row> */}
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Ngày sử dụng dịch vụ<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="date"
                      {...register("start_date_sm", {
                        required: true,
                      })}
                      value={watch("start_date_sm")}
                      onChange={e =>
                        setValue("start_date_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.start_date_sm &&
                      errors.start_date_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập ngày sử dụng
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Ngày bắt đầu tính cước<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      disabled={statusSM == "active" ? false : true}
                      type="date"
                      {...register("billing_date", {
                        required:
                          statusSM === "active"
                            ? "Vui lòng chọn ngày ngày tính cước"
                            : false,
                      })}
                      value={watch("billing_date")}
                      onChange={e =>
                        setValue("billing_date", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.billing_date &&
                      errors.billing_date.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          Vui lòng nhập ngày tính cước
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Ngày ngưng dịch vụ<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      disabled={statusSM == "terminate" ? false : true}
                      type="date"
                      {...register("terminate_date_sm", {
                        required:
                          statusSM === "terminate"
                            ? "Vui lòng chọn ngày ngưng dịch vụ"
                            : false,
                      })}
                      value={watch("terminate_date_sm")}
                      onChange={e =>
                        setValue("terminate_date_sm", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    {errors.terminate_date_sm &&
                      errors.terminate_date_sm.type === "required" && (
                        <span
                          role="alert"
                          className="text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          {errors.terminate_date_sm.message}
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <ButtonAnt
              htmlType="submit"
              className="text-white"
              style={{ backgroundColor: "#6ECCAF" }}
            >
              Cập nhật
            </ButtonAnt>{" "}
            <ButtonAnt onClick={handleCancel}>Đóng</ButtonAnt>
          </ModalFooter>
        </Form>
      </Modal>
      {showMessage && (
        <Alert
          message={showMessage.message}
          type={showMessage.color}
          onClose={closeAlert}
          style={{
            position: "fixed",
            fontWeight: "600",
            top: "100px",
            right: "20px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 50px",
          }}
        />
      )}
    </>
  )
}
Edit.propTypes = {
  t: PropTypes.any,
  modalEdit: PropTypes.object,
  onCloseModalEdit: PropTypes.func,
}

const mapStateToProps = ({
  softwareservice,
  helper,
  customer,
  softwarepackage,
}) => ({
  modalEdit: helper.modalEdit,
  customers: customer.customers,
  softwarepackages: softwarepackage.softwarepackages,
  // errorResponse: softwareservice.errorSub,
  // errorNotification: softwareservice.errorNotification,
  // successNotification: softwareservice.successNotification,
})

const mapDispatchToProps = dispatch => ({
  onCloseModalEdit: () => dispatch(closeModalEdit()),
  onEditSoftwareService: message => dispatch(onUpdateSoftwareService(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
