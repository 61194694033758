const packageGroups = [
    {
        id: 1,
        name: 'Dashboard',
        packages: [
            {
                id: 1,
                package_name: "Quản trị Dashboard-báo cáo"
            },
            {
                id: 2,
                package_name: "Sơ đồ tổ chức"
            }
        ]
    },
    {
        id: 2,
        name: 'Quản lý nhân sự',
        packages: [
            {
                id: 1,
                package_name: "Hồ sơ nhân sự"
            },
            {
                id: 2,
                package_name: "Quản lý cấp dưới"
            }
        ]
    },
    {
        id: 3,
        name: 'Quản lý chấm công',
        packages: [
            {
                id: 1,
                package_name: "Chấm công"
            },
            {
                id: 2,
                package_name: "Chấm công bù"
            }
        ]
    },
];

export { packageGroups }