import React, { useEffect, useState } from "react"
import { Table, Modal, Tooltip } from "antd"
import { queries } from "@testing-library/react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from "react-color"
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from "store/helper/actionTypes"
import {
  deleteCustomer,
  getDetailCusContacts,
  getDetailCusHardServices,
  getDetailCusSoftServices,
} from "../../store/actions"
import Permission from "permissions/Permission"
import { OPEN_DETAIL } from "store/customer/actionTypes"

import moment from "moment"

const CustomerTable = props => {
  const columns = [
    {
      title: "Tên viết tắt",
      width: 150,
      dataIndex: "reminiscent_name",
      key: "reminiscent_name",
    },
    {
      title: "Khách hàng",
      width: 200,
      dataIndex: "company_name",
      key: "company_name",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Email",
      dataIndex: "email_contact",
      key: "email_contact",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Ngày thành lập",
      dataIndex: "fouding_date",
      key: "fouding_date",
      width: 120,
      render: (text) => (
        <>
          {text && moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}
        </>
      )
    },

    {
      title: "Mã số thuế",
      dataIndex: "tax_id",
      key: "tax_id",
      width: 150,
    },
    {
      title: "Địa chỉ xuất hoá đơn",
      dataIndex: "tax_address",
      key: "tax_address",
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 200,
      render: (text) => (
        <>
          {moment(text, 'YYYY-MM-DD h:mm:s').format('DD-MM-YYYY H:mm:s')}
        </>
      )
    },
    {
      title: "Thao tác",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record, index) => (
        <>
          <Permission myRole={myRole} permission={{ label: 'customer', action: "view" }}>
            <button
              type="button"
              onClick={() => handleShow(record)}
              className="btn btn-outline-info btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-eye"></i>
            </button>
          </Permission>

          <Permission myRole={myRole} permission={{ label: 'customer', action: "update" }}>
            <button
              type="button"
              onClick={() => handleEdit(record)}
              className="btn btn-outline-success btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-edit"></i>
            </button>
          </Permission>

          <Permission myRole={myRole} permission={{ label: 'customer', action: "delete" }}>
            <button
              type="button"
              onClick={() => handleDelete(record)}
              className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"
            >
              <i className="fas fa-trash"></i>
            </button>
          </Permission>
        </>
      ),
    },
  ]

  const {
    customers,
    onOpenModalEdit,
    onDeleteCustomer,
    onOpenDetail,
    onGetDetailCusContact,
    onGetDetailCusHard,
    onGetDetailCusSoft,
    keyword,
    myRole
  } = props
  const [dataRow, setDataRow] = useState({})
  const [customersList, setCustomers] = useState([])

  const handleShow = row => {
    onOpenDetail({
      isOpenDetail: true,
      idContact: row,
    })
    onGetDetailCusContact(row.id)
    onGetDetailCusHard(row.id)
    onGetDetailCusSoft(row.id)
  }
  const handleEdit = row => {
    let modal = {
      isOpenModal: true,
      title: "Cập nhật khách hàng",
    }
    setDataRow(row)
    onOpenModalEdit(modal)
  }

  const handleDelete = row => {
    confirm(row)
  }

  useEffect(() => {
    if (keyword) {
      const filteredData = customersList.filter(entry =>
        (entry?.company_name).toLowerCase().includes(keyword.toLowerCase()) ||
        (entry?.reminiscent_name).toLowerCase().includes(keyword.toLowerCase())
      );
      setCustomers(filteredData);
    } else {
      let data = []
      customers?.map(customer => {
        data.push({
          key: customer.id,
          id: customer.id,
          reminiscent_name: customer.reminiscent_name,
          company_name: customer.company_name,
          address: customer.address,
          email_contact: customer.email_contact,
          fouding_date: customer.fouding_date,
          tax_address: customer.tax_address,
          tax_id: customer.tax_id,
          notes: customer.notes,
          updated_at: customer.updated_at,
        })
      })
      setCustomers(data)
    }
  }, [customers, keyword])

  const confirm = row => {
    Modal.confirm({
      title: "Bạn có chắc",
      icon: "",
      content: `Bạn có muốn xoá khách hàng "${row.company_name}"`,
      okText: "Đồng ý",
      cancelText: "Đóng",
      onOk: () => {
        let data = { id: row.id }
        let message = {
          action: "delete",
          message: data,
          type_message: "map",
        }
        // console.log(message);
        onDeleteCustomer(message)
      },
      okButtonProps: {
        style: {
          backgroundColor: "#ff4d4f",
        },
      },
      centered: true,
    })
  }

  return (
    <>
      <Edit dataRow={dataRow}></Edit>
      <Table
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
        columns={columns}
        size={'small'}
        dataSource={customersList}
        scroll={{
          x: 1500,
        }}
      />
    </>
  )
}

CustomerTable.propTypes = {
  t: PropTypes.any,
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onOpenModalEdit: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  keyword: PropTypes.string,
  myRole: PropTypes.array
}

const mapStateToProps = ({ customer, Login }) => ({
  customers: customer.customers,
  myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
  onOpenModalEdit: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
  onDeleteCustomer: message => dispatch(deleteCustomer(message)),
  onOpenDetail: detail => dispatch({ type: OPEN_DETAIL, payload: detail }),
  // Detail
  onGetDetailCusContact: id => dispatch(getDetailCusContacts(id)),
  onGetDetailCusSoft: id => dispatch(getDetailCusSoftServices(id)),
  onGetDetailCusHard: id => dispatch(getDetailCusHardServices(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTable)
