import {
  GET_DEVICE_SERVICES_SUCCESS,
  GET_DEVICE_SERVICES_FAIL,
  ADD_DEVICE_SERVICE_SUCCESS,
  ADD_DEVICE_SERVICE_FAIL,
  UPDATE_DEVICE_SERVICE_SUCCESS,
  UPDATE_DEVICE_SERVICE_FAIL,
  DELETE_DEVICE_SERVICE_SUCCESS,
  DELETE_DEVICE_SERVICE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  deviceServices: [],
  error: {},
}

const deviceService = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICE_SERVICES_SUCCESS:
      return {
        ...state,
        deviceServices: action.payload,
      }

    case GET_DEVICE_SERVICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DEVICE_SERVICE_SUCCESS:
      return {
        ...state,
        deviceServices: [...state.deviceServices, action.payload],
      }

    case ADD_DEVICE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_DEVICE_SERVICE_SUCCESS:
      return {
        ...state,
        deviceServices: state.deviceServices.map(deviceService =>
          deviceService.id.toString() === action.payload.id.toString()
            ? { deviceService, ...action.payload }
            : deviceService
        ),
      }

    case UPDATE_DEVICE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DEVICE_SERVICE_SUCCESS:
      return {
        ...state,
        deviceServices: state.deviceServices.filter(
          deviceService => deviceService.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_DEVICE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default deviceService
