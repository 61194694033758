import React, { useEffect, useState } from "react"
import { Button as ButtonAnt, Select as SelectAnt } from "antd"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { closeModalDetail } from "../../store/actions"

import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"

const Detail = props => {
  const { modalDetail, onCloseModalDetail, onEditSoftwareService, dataRow } =
    props
  const handleCancel = () => {
    onCloseModalDetail()
  }
  const columns = [
    {
      title: "Tên khách hàng",
      titleCol: 4,
      dataIndex: "customer_name",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Tên gói dịch vụ",
      titleCol: 4,
      dataIndex: "service_package",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Trạng thái",
      titleCol: 4,
      dataIndex: "status",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Nhân viên",
      titleCol: 4,
      dataIndex: "employee",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Người dùng trình duyệt",
      titleCol: 4,
      dataIndex: "web_user",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Người dùng ứng dụng di động",
      titleCol: 4,
      dataIndex: "mobile_user",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Dịch vụ triển khai phía khách hàng",
      titleCol: 4,
      dataIndex: "on_premise",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Thanh toán 1 lần",
      titleCol: 4,
      dataIndex: "one_time_payment",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Kỳ thanh toán",
      titleCol: 4,
      dataIndex: "billing_cycle_type",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Thời hạn chu kỳ thanh toán",
      titleCol: 4,
      dataIndex: "billing_cycle",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Ngày bắt đầu sử dụng",
      titleCol: 4,
      dataIndex: "start_date",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Ngày bắt đầu tính cước",
      titleCol: 4,
      dataIndex: "billing_date",
      dataCol: 8,
      unitType: "none",
    },
    {
      title: "Ngày ngưng sử dụng",
      titleCol: 4,
      dataIndex: "terminate_date",
      dataCol: 8,
      unitType: "none",
    },
  ]

  return (
    <>
      <Modal
        isOpen={modalDetail.isOpenModal}
        toggle={handleCancel}
        fullscreen={"lg"}
        size={"lg"}
      >
        <ModalHeader toggle={handleCancel}>{modalDetail.title}</ModalHeader>
        <ModalBody>
          <Container>
            <>
              {columns.map((key, i) => {
                if (key.unitType == "index") {
                  return (
                    <Row key={i}>
                      <Col md={key.titleCol}>
                        <Label>{key.title}</Label>
                      </Col>
                      <Col md={key.dataCol}>{`${dataRow[key.dataIndex]} ${key.unitIndex
                        }`}</Col>
                    </Row>
                  )
                }
                if (key.unitType == "referrer") {
                  return (
                    <Row key={i}>
                      <Col md={key.titleCol}>
                        <Label>{key.title}</Label>
                      </Col>
                      <Col md={key.dataCol}>{`${dataRow[key.dataIndex]} ${dataRow[key.unitIndex]
                        }`}</Col>
                    </Row>
                  )
                }
                if (key.unitType == "show-package") {
                  return (
                    <Row key={i}>
                      <Col md={key.titleCol}>
                        <Label>{key.title}</Label>
                      </Col>
                      <Col md={key.dataCol}>
                        <Table bordered hover>
                          <thead>
                            <tr>
                              <th>Tên nhóm dịch vụ</th>
                              <th>Chi tiết nhóm dịch vụ</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(dataRow[key.dataIndex]) &&
                              dataRow[key.dataIndex].length > 0 &&
                              dataRow[key.dataIndex].map((pack, i) => (
                                <tr key={i}>
                                  <td>{pack.name}</td>
                                  <td>
                                    {pack.packages.length > 0 &&
                                      pack.packages.map((sub, y) => (
                                        <div key={y}>
                                          - {sub.name}
                                          <br />
                                        </div>
                                      ))}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )
                }

                return (
                  <Row key={i}>
                    <Col
                      md={key.titleCol}
                      style={{
                        textTransform: "capitalize",
                        borderColor: "inherit",
                        // borderBottom: "1px solid",
                        padding: "4px",
                        borderCollapse: "separate",
                        borderSpacing: "0 4px",
                        borderRight: "1px solid",
                      }}
                    >
                      <Label>{key.title}</Label>
                    </Col>
                    <Col
                      md={key.dataCol}
                      style={{
                        // paddingLeft: "20px",
                        textTransform: "capitalize",
                        borderColor: "inherit",
                        padding: "4px 10px",
                        borderCollapse: "separate",
                        borderSpacing: "0 4px",
                        fontWeight: "bold",
                      }}
                    >
                      {dataRow[key.dataIndex]}
                    </Col>
                  </Row>
                )
              })}
            </>
          </Container>
        </ModalBody>
        <ModalFooter>
          <ButtonAnt onClick={handleCancel}>Đóng</ButtonAnt>
        </ModalFooter>
      </Modal>
    </>
  )
}
Detail.propTypes = {
  t: PropTypes.any,
  modalDetail: PropTypes.object,
  onCloseModalDetail: PropTypes.func,
}

const mapStateToProps = ({ softwareservice, helper }) => ({
  modalDetail: helper.modalDetail,
})

const mapDispatchToProps = dispatch => ({
  onCloseModalDetail: () => dispatch(closeModalDetail()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
