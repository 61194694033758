import React, { useEffect, useState } from "react"
import {
  Button as ButtonAnt,
  Steps,
  Select as SelectAnt,
  InputNumber,
  DatePicker,
  Alert,
} from "antd"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  closeModalAdd,
  addNewCustomer,
  addNewContact,
} from "../../../store/actions"
import {
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"

const Add = props => {
  const { customers, modalAdd, onCloseModalAdd, onAddNewContact } = props
  const [showMessage, setShowMessage] = useState(false)
  const [fields, setFields] = useState({})
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm()
  const [listCustomers, setListCustomers] = useState()
  useEffect(() => {
    const data = []
    customers?.map(customer => {
      data.push({
        value: customer.id,
        label: customer.company_name,
      })
    })
    setListCustomers(data)
  }, [customers])
  const handleCancel = () => {
    onCloseModalAdd()
  }

  const handleAdd = data => {
    console.log("dat", data)
    if (data) {
      let message = {
        action: "create",
        message: data,
        type_message: "map",
      }
      // console.log(message)
      onAddNewContact(message)
      // setShowMessage(true)
      reset()
    }
  }
  const closeAlert = () => {
    setShowMessage(false)
  }
  if (showMessage) {
    setTimeout(closeAlert, 3000) // đóng thông báo sau 3 giây
  }
  return (
    <>
      <Modal
        isOpen={modalAdd.isOpenModal}
        toggle={handleCancel}
        fullscreen={"lg"}
        size={"lg"}
      >
        <Form onSubmit={handleSubmit(handleAdd)}>
          <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
          <ModalBody>
            {" "}
            <Container>
              <Row className="mb-3">
                <Col md={3}>
                  <Label>
                    Khách hàng
                    <span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <SelectAnt
                      {...register("customer", { required: true })}
                      onChange={value =>
                        setValue("customer", value, {
                          shouldValidate: true,
                        })
                      }
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Chọn khách hàng"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={listCustomers}
                    />
                    {errors.customer && errors.customer.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng chọn khách hàng
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>
                    Tên người liên hệ
                    <span className="text-danger">*</span>{" "}
                  </label>
                </Col>
                <Col md={8}>
                  <input
                    name="name"
                    {...register("name", {
                      required: true,
                      // pattern: /^[a-zA-Z0-9 ]*$/,
                    })}
                    className="form-control"
                    placeholder="Tên người liên hệ..."
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span
                      role="alert"
                      className="text-danger"
                      style={{ fontSize: "13px" }}
                    >
                      Tên người liên hệ không được để trống
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>
                    Chức danh <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col md={8}>
                  <input
                    name="job_title"
                    {...register("job_title", {
                      required: true,
                      // pattern: /^[a-zA-Z0-9 ]*$/,
                    })}
                    className="form-control"
                    placeholder="Chức danh..."
                  />
                  {errors.job_title && errors.job_title.type === "required" && (
                    <span
                      role="alert"
                      className="text-danger"
                      style={{ fontSize: "13px" }}
                    >
                      Chức danh không được để trống
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>
                    Email liên hệ <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col md={8}>
                  <input
                    name="email_contact"
                    {...register("email_contact", {
                      required: true,
                      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    })}
                    className="form-control"
                    placeholder="Email liên hệ..."
                  />
                  {errors.email_contact &&
                    errors.email_contact.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Email liên hệ không được để trống
                      </span>
                    )}
                  {errors.email_contact &&
                    errors.email_contact.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Email không đúng định dạng
                      </span>
                    )}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={3}>
                  <label>
                    Số điện thoại liên hệ <span className="text-danger">*</span>
                  </label>
                </Col>
                <Col md={8}>
                  <input
                    name="phone_contact"
                    type="number"
                    {...register("phone_contact", {
                      required: true,
                      pattern: /^\d*[0-9]\d*$/,
                    })}
                    className="form-control"
                    placeholder="Số điện thoại..."
                  />
                  {errors.phone_contact &&
                    errors.phone_contact.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Số điện thoại liên hệ không được để trống
                      </span>
                    )}
                  {errors.phone_contact &&
                    errors.phone_contact.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Vui lòng nhập vào 1 số dương
                      </span>
                    )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>Ghi chú</label>
                </Col>
                <Col md={8}>
                  <textarea
                    name="notes"
                    {...register("notes")}
                    className="form-control"
                    placeholder="Ghi chú..."
                  ></textarea>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            {" "}
            <ButtonAnt htmlType="submit" type="primary">
              Thêm mới
            </ButtonAnt>
            <ButtonAnt onClick={handleCancel}>Đóng</ButtonAnt>
          </ModalFooter>
        </Form>
      </Modal>
      {showMessage && (
        <Alert
          message="Thêm thành công"
          type="success"
          onClose={closeAlert}
          style={{
            position: "fixed",
            fontWeight: "600",
            top: "100px",
            right: "20px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 50px",
          }}
        />
      )}
    </>
  )
}
Add.propTypes = {
  t: PropTypes.any,
  modalAdd: PropTypes.object,
  onCloseModalAdd: PropTypes.func,
}

const mapStateToProps = ({ customer, helper }) => ({
  modalAdd: helper.modalAdd,
  customers: customer.customers,
})

const mapDispatchToProps = dispatch => ({
  onCloseModalAdd: () => dispatch(closeModalAdd()),
  onAddNewContact: message => dispatch(addNewContact(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Add)
