import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_SERVICE_TYPE,
  DELETE_SERVICE_TYPE,
  GET_SERVICE_TYPES,
  UPDATE_SERVICE_TYPE,
} from "./actionTypes"
import {
  getServiceTypesSuccess,
  getServiceTypesFail,
  addServiceTypeFail,
  addServiceTypeSuccess,
  updateServiceTypeSuccess,
  updateServiceTypeFail,
  deleteServiceTypeSuccess,
  deleteServiceTypeFail,
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getServiceTypes,
  addNewServiceType,
  updateServiceType,
  deleteServiceType
} from "../../helpers/backend_api/serviceTypes"

function* fetchServiceTypes() {
  try {
    const response = yield call(getServiceTypes)
    if (response.code === 200) {
      yield put(getServiceTypesSuccess(response.message))
    }
    else {
      yield put(getServiceTypesFail(error))
    }
  } catch (error) {
    yield put(getServiceTypesFail(error))
  }
}

function* onAddNewServiceType({ payload: serviceType }) {
  try {
    const response = yield call(addNewServiceType, serviceType);
    if (response.code === 201) {
      const serviceTypes = yield call(getServiceTypes)
      if (serviceTypes.code === 200) {
        yield put(getServiceTypesSuccess(serviceTypes.message))
        yield put(closeModalAdd())
      }
      else {
        yield put(getServiceTypesFail(error))
      }
    }
  } catch (error) {
    yield put(addServiceTypeFail(error))
  }
}

function* onUpdateServiceType({ payload: serviceType }) {
  try {
    const response = yield call(updateServiceType, serviceType);
    if (response.code === 200) {
      const serviceTypesRes = yield call(getServiceTypes)
      if (serviceTypesRes.code === 200) {
        yield put(getServiceTypesSuccess(serviceTypesRes.message))
        yield put(closeModalEdit())
      }
      else {
        yield put(getServiceTypesFail(error))
      }
    }
  } catch (error) {
    yield put(updateServiceTypeFail(error))
  }
}

function* onDeleteServiceType({ payload: serviceType }) {
  try {
    const response = yield call(deleteServiceType, serviceType);
    if (response.code === 200) {
      const serviceTypesRes = yield call(getServiceTypes)
      if (serviceTypesRes.code === 200) {
        yield put(getServiceTypesSuccess(serviceTypesRes.message))
      }
      else {
        yield put(getServiceTypesFail(error))
      }
    }
  } catch (error) {
    yield put(deleteServiceTypeFail(error))
  }
}

function* serviceTypeSaga() {
  yield takeEvery(GET_SERVICE_TYPES, fetchServiceTypes)
  yield takeEvery(ADD_NEW_SERVICE_TYPE, onAddNewServiceType)
  yield takeEvery(UPDATE_SERVICE_TYPE, onUpdateServiceType)
  yield takeEvery(DELETE_SERVICE_TYPE, onDeleteServiceType)
}

export default serviceTypeSaga
