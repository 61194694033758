import React, { useEffect, useState } from "react"
import { Button as ButtonAnt, Select as SelectAnt } from "antd"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { closeModalEdit, onUpdateProductComponent } from "../../store/actions"

import {
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"

const Edit = props => {
  const { modalEdit, onCloseModalEdit, onEditProductComponent, dataRow } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [fields, setFields] = useState({})
  const [myError, setMyErrors] = useState({})

  useEffect(() => {
    reset({
      id: dataRow.key,
      name: dataRow.name,
      decriptions: dataRow.decriptions,
      group: dataRow.group,
    })
  }, [reset, dataRow])

  const handleOk = () => {
    onCloseModalEdit()
  }
  const handleCancel = () => {
    onCloseModalEdit()
  }

  const onSubmit = data => {
    if (validateInput()) {
      let newData = {
        id: data.id,
        name: data.name,
        decriptions: data.decriptions,
        group: data.group,
      }
      let message = {
        action: "update",
        message: newData,
        type_message: "map",
      }
      onEditProductComponent(message)
    }
  }

  const validateInput = () => {
    let isValid = true
    let errs = myError
    setMyErrors(errs)
    return isValid
  }

  const onChange = value => {
    console.log(`selected ${value}`)
    setMyErrors(myError)
    fields.r_name = value
    setFields(fields)
  }

  return (
    <>
      <Modal
        isOpen={modalEdit.isOpenModal}
        toggle={handleCancel}
        fullscreen={"lg"}
        size={"lg"}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={handleCancel}>{modalEdit.title}</ModalHeader>
          <ModalBody>
            <Container>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Tên tính năng<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <input
                      aria-invalid={errors.name ? "true" : "false"}
                      {...register("name", {
                        required: true,
                        pattern: /^[a-zA-Z ]*$/,
                      })}
                      className="form-control"
                      placeholder="Nhập tên tính năng..."
                    />
                    {errors.name && errors.name.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Tên tính năng không được để trống
                      </span>
                    )}
                    {errors.name && errors.name.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Tên tính năng không có ký tự đặc biệt
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>
                    Nhóm tính năng<span className="text-danger">*</span>
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <input
                      aria-invalid={errors.group ? "true" : "false"}
                      {...register("group", {
                        required: true,
                        pattern: /^[a-zA-Z ]*$/,
                      })}
                      className="form-control"
                      placeholder="Nhập vào nhóm tính năng..."
                    />
                    {errors.group && errors.group.type === "required" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Tên nhóm tính năng không được để trống
                      </span>
                    )}
                    {errors.group && errors.group.type === "pattern" && (
                      <span
                        role="alert"
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        Tên nhóm tính năng không có ký tự đặc biệt
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Label>Mô tả tính năng</Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <textarea
                      {...register("decriptions", {
                        required: false,
                      })}
                      className="form-control"
                      rows={4}
                      placeholder="Nhập vào mô tả tính năng..."
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <ButtonAnt
              htmlType="submit"
              className="text-white"
              style={{ backgroundColor: "#6ECCAF" }}
            >
              Cập nhật
            </ButtonAnt>{" "}
            <ButtonAnt onClick={handleCancel}>Đóng</ButtonAnt>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}
Edit.propTypes = {
  t: PropTypes.any,
  modalEdit: PropTypes.object,
  onCloseModalEdit: PropTypes.func,
}

const mapStateToProps = ({ productcomponent, helper }) => ({
  modalEdit: helper.modalEdit,
})

const mapDispatchToProps = dispatch => ({
  onCloseModalEdit: () => dispatch(closeModalEdit()),
  onEditProductComponent: message =>
    dispatch(onUpdateProductComponent(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
