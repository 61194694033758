import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import customerSaga from "./customer/saga"
import userSaga from "./user/saga"
import productcomponentSaga from "./productcomponent/saga"
import softwareserviceSaga from "./softwareservice/saga"
import softwarepackageSaga from "./softwarepackage/saga"

import roleSaga from "./role/saga"
import useRoleSaga from "./user_role/saga"
import helperSaga from "./helper/saga"
import serviceTypeSaga from "./serviceType/saga"
import packageGroupSaga from "./packageGroup/saga"
import serviceSaga from "./service/saga"
import serviceCustomerSaga from "./serviceCustomer/saga"
import deviceTypeSaga from "./hardwareType/saga"
import devicePackageSaga from "./hardwarePackage/saga"
import deviceServiceSaga from "./hardwareService/saga"
import systemLogSaga from "./systemLog/saga"


export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(customerSaga),
    fork(userSaga),
    fork(productcomponentSaga),
    fork(softwareserviceSaga),
    fork(softwarepackageSaga),
    fork(roleSaga),
    fork(useRoleSaga),
    fork(helperSaga),
    fork(serviceTypeSaga),
    fork(packageGroupSaga),
    fork(serviceCustomerSaga),
    fork(deviceTypeSaga),
    fork(devicePackageSaga),
    fork(deviceServiceSaga),
    fork(systemLogSaga),
  ])
}
