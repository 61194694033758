/* PRODUCTCOMPONENTS */
export const GET_PRODUCTCOMPONENTS = "GET_PRODUCTCOMPONENTS"
export const GET_PRODUCTCOMPONENTS_SUCCESS = "GET_PRODUCTCOMPONENTS_SUCCESS"
export const GET_PRODUCTCOMPONENTS_FAIL = "GET_PRODUCTCOMPONENTS_FAIL"

export const ADD_NEW_PRODUCTCOMPONENT = "ADD_NEW_PRODUCTCOMPONENT"
export const ADD_PRODUCTCOMPONENT_SUCCESS = "ADD_PRODUCTCOMPONENT_SUCCESS"
export const ADD_PRODUCTCOMPONENT_FAIL = "ADD_PRODUCTCOMPONENT_FAIL"

export const UPDATE_PRODUCTCOMPONENT = "UPDATE_PRODUCTCOMPONENT"
export const UPDATE_PRODUCTCOMPONENT_SUCCESS = "UPDATE_PRODUCTCOMPONENT_SUCCESS"
export const UPDATE_PRODUCTCOMPONENT_FAIL = "UPDATE_PRODUCTCOMPONENT_FAIL"

export const DELETE_PRODUCTCOMPONENT = "DELETE_PRODUCTCOMPONENT"
export const DELETE_PRODUCTCOMPONENT_SUCCESS = "DELETE_PRODUCTCOMPONENT_SUCCESS"
export const DELETE_PRODUCTCOMPONENT_FAIL = "DELETE_PRODUCTCOMPONENT_FAIL"
