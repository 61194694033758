import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_ROLE,
  DELETE_ROLE,
  GET_ROLES,
  UPDATE_ROLE,
  GET_CONSTRAINTS_ROLE
} from "./actionTypes"
import {
  getRolesSuccess,
  getRolesFail,
  addRoleFail,
  addRoleSuccess,
  updateRoleSuccess,
  updateRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
  getConstraintsSuccess,
  getConstraintsFail
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getRoles,
  addNewRole,
  updateRole,
  deleteRole,
  getConstraints
} from "../../helpers/backend_api/roles"

function* fetchRoles() {
  try {
    const response = yield call(getRoles)
    if (response.code === 200) {
      yield put(getRolesSuccess(response.message))
    }
    else {
      yield put(getRolesFail(response))
    }
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

function* onAddNewRole({ payload: role }) {
  try {
    const response = yield call(addNewRole, role)
    if (response.code === 201) {
      yield call(fetchRoles)
      yield put(closeModalAdd())
    } else {
      yield put(addRoleFail(response))
    }
  } catch (error) {
    yield put(addRoleFail(error))
  }
}

function* onUpdateRole({ payload: role }) {
  try {
    const response = yield call(updateRole, role)
    if (response.code === 200) {
      yield call(fetchRoles)
      yield put(closeModalEdit())
    } else {
      yield put(updateRoleFail(response))
    }
  } catch (error) {
    yield put(updateRoleFail(error))
  }
}

function* onDeleteRole({ payload: role }) {
  try {
    const response = yield call(deleteRole, role)
    if (response.code === 200) {
      yield call(fetchRoles)
    } else {
      yield put(deleteRoleFail(response))
    }
  } catch (error) {
    yield put(deleteRoleFail(error))
  }
}

function* fetchConstraint() {
  try {
    const response = yield call(getConstraints)
    if (response.code === 200) {
      yield put(getConstraintsSuccess(response.message))
    }
    else {
      yield put(getConstraintsFail(response))
    }
  } catch (error) {
    yield put(getConstraintsFail(error))
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES, fetchRoles)
  yield takeEvery(ADD_NEW_ROLE, onAddNewRole)
  yield takeEvery(UPDATE_ROLE, onUpdateRole)
  yield takeEvery(DELETE_ROLE, onDeleteRole)
  yield takeEvery(GET_CONSTRAINTS_ROLE, fetchConstraint)

}

export default roleSaga
