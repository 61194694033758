import {
  GET_PACKAGE_GROUPS,
  GET_PACKAGE_GROUPS_FAIL,
  GET_PACKAGE_GROUPS_SUCCESS,
  ADD_NEW_PACKAGE_GROUP,
  ADD_PACKAGE_GROUP_SUCCESS,
  ADD_PACKAGE_GROUP_FAIL,
  UPDATE_PACKAGE_GROUP,
  UPDATE_PACKAGE_GROUP_SUCCESS,
  UPDATE_PACKAGE_GROUP_FAIL,
  DELETE_PACKAGE_GROUP,
  DELETE_PACKAGE_GROUP_SUCCESS,
  DELETE_PACKAGE_GROUP_FAIL,
} from "./actionTypes"

export const getPackageGroups = () => ({
  type: GET_PACKAGE_GROUPS,
})

export const getPackageGroupsSuccess = packageGroups => ({
  type: GET_PACKAGE_GROUPS_SUCCESS,
  payload: packageGroups,
})

export const getPackageGroupsFail = error => ({
  type: GET_PACKAGE_GROUPS_FAIL,
  payload: error,
})

export const addNewPackageGroup = packageGroup => ({
  type: ADD_NEW_PACKAGE_GROUP,
  payload: packageGroup,
})

export const addPackageGroupSuccess = packageGroup => ({
  type: ADD_PACKAGE_GROUP_SUCCESS,
  payload: packageGroup,
})

export const addPackageGroupFail = error => ({
  type: ADD_PACKAGE_GROUP_FAIL,
  payload: error,
})

export const updatePackageGroup = packageGroup => ({
  type: UPDATE_PACKAGE_GROUP,
  payload: packageGroup,
})

export const updatePackageGroupSuccess = packageGroup => ({
  type: UPDATE_PACKAGE_GROUP_SUCCESS,
  payload: packageGroup,
})

export const updatePackageGroupFail = error => ({
  type: UPDATE_PACKAGE_GROUP_FAIL,
  payload: error,
})

export const deletePackageGroup = packageGroup => ({
  type: DELETE_PACKAGE_GROUP,
  payload: packageGroup,
})

export const deletePackageGroupSuccess = packageGroup => ({
  type: DELETE_PACKAGE_GROUP_SUCCESS,
  payload: packageGroup,
})

export const deletePackageGroupFail = error => ({
  type: DELETE_PACKAGE_GROUP_FAIL,
  payload: error,
})