import {
  GET_PRODUCTCOMPONENTS,
  GET_PRODUCTCOMPONENTS_FAIL,
  GET_PRODUCTCOMPONENTS_SUCCESS,
  ADD_NEW_PRODUCTCOMPONENT,
  ADD_PRODUCTCOMPONENT_SUCCESS,
  ADD_PRODUCTCOMPONENT_FAIL,
  UPDATE_PRODUCTCOMPONENT,
  UPDATE_PRODUCTCOMPONENT_SUCCESS,
  UPDATE_PRODUCTCOMPONENT_FAIL,
  DELETE_PRODUCTCOMPONENT,
  DELETE_PRODUCTCOMPONENT_SUCCESS,
  DELETE_PRODUCTCOMPONENT_FAIL,
} from "./actionTypes"

export const getProductComponents = () => ({
  type: GET_PRODUCTCOMPONENTS,
})

export const getProductComponentsSuccess = productcomponents => ({
  type: GET_PRODUCTCOMPONENTS_SUCCESS,
  payload: productcomponents,
})

export const getProductComponentsFail = error => ({
  type: GET_PRODUCTCOMPONENTS_FAIL,
  payload: error,
})

export const addNewProductComponent = productcomponent => ({
  type: ADD_NEW_PRODUCTCOMPONENT,
  payload: productcomponent,
})

export const addProductComponentSuccess = productcomponent => ({
  type: ADD_PRODUCTCOMPONENT_SUCCESS,
  payload: productcomponent,
})

export const addProductComponentFail = error => ({
  type: ADD_PRODUCTCOMPONENT_FAIL,
  payload: error,
})

export const onUpdateProductComponent = productcomponent => ({
  type: UPDATE_PRODUCTCOMPONENT,
  payload: productcomponent,
})

export const updateProductComponentSuccess = productcomponent => ({
  type: UPDATE_PRODUCTCOMPONENT_SUCCESS,
  payload: productcomponent,
})

export const updateProductComponentFail = error => ({
  type: UPDATE_PRODUCTCOMPONENT_FAIL,
  payload: error,
})

export const deleteProductComponent = productcomponent => ({
  type: DELETE_PRODUCTCOMPONENT,
  payload: productcomponent,
})

export const deleteProductComponentSuccess = productcomponent => ({
  type: DELETE_PRODUCTCOMPONENT_SUCCESS,
  payload: productcomponent,
})

export const deleteProductComponentFail = error => ({
  type: DELETE_PRODUCTCOMPONENT_FAIL,
  payload: error,
})