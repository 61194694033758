import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = "https://api-coredev.px1.vn/api/v1"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

let isRefreshing = false
let failedQueue = []
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

axiosApi.interceptors.response.use(
  res => res,
  err => {
    const oldReFreshToken = localStorage.getItem("refresh_token")
    const originalRequest = err.config
    if (err.response?.status === 401 && !originalRequest?._retry) {
      if (_.isEmpty(oldReFreshToken)) {
        window.location.href = "/login"
      }
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then(token => {
            originalRequest.headers["Authorization"] = "Bearer " + token
            return axiosApi(originalRequest)
          })
          .catch(err => {
            return Promise.reject(err)
          })
      }
      originalRequest._retry = true
      isRefreshing = true
      return new Promise(function (resolve, reject) {
        let api = `${API_URL}/refeshtoken?refresh_token=${localStorage.getItem(
          "refresh_token"
        )}`
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        }
        axiosApi
          .get(api, { headers: headers, dataType: "json" })
          .then(result => {
            if (result.status === 200) {
              localStorage.setItem(
                "authUser",
                result.data?.message?.access_token
              )
              localStorage.setItem(
                "refresh_token",
                result.data?.message?.refresh_token
              )
              axiosApi.defaults.headers.common["Authorization"] =
                "Bearer " + result.data?.message?.access_token
              originalRequest.headers["Authorization"] =
                "Bearer " + result.data?.message?.access_token
              processQueue(null, result.data?.message?.access_token)
              resolve(axiosApi(originalRequest))
            }
          })
          .catch(err => {
            processQueue(err, null)
            err.response.detailsError = {
              msg: "Hết phiên làm việc, vui lòng tải lại trang",
              key: Math.random(),
              type: "error",
            }
            reject(err)
            return err.response
          })
          .then(() => {
            isRefreshing = false
          })
      })
    }
    if (!err.status && err.message === "Network Error") {
      return err.message
    }
    if (err.response?.status === 400) {
      if (err.response?.data?.code === 400 && err.response?.data?.message === 'token invalid') {
        window.location.href = "/login"
      }
    }
    // if (err.response?.status === 400) {
    //   let arrErrors = []
    //   if (err.response?.data?.type_message === "string") {
    //     const string1 = JSON.stringify(err.response?.data?.message)
    //     const string2 = "employees were applied other rule"
    //     const string3 = "is overlapping with another leave"
    //     const string4 = "duplicate key error collection: qi_hrm.users"
    //     const string5 = "employees were applied other rule"
    //     const stringErrorTokenRefresh = "token invalid"
    //     if (string1.includes(string2)) {
    //       err.response.detailsError = {
    //         msg: "Có nhân viên đã được chọn ở chính sách khác, vui lòng kiểm tra lại",
    //         key: Math.random(),
    //         type: "error",
    //       }
    //       return err.response
    //     } else if (string1.includes(string3)) {
    //       err.response.detailsError = {
    //         msg: "Đã có ngày nghỉ phép được chọn bị trùng với yêu cầu nghỉ phép khác, vui lòng kiểm tra lại",
    //         key: Math.random(),
    //         type: "error",
    //       }
    //       return err.response
    //     } else if (string1.includes(string4)) {
    //       err.response.detailsError = {
    //         msg: "Tài khoản đang tạo đã tồn tại, vui lòng nhập vào tài khoản khác",
    //         key: Math.random(),
    //         type: "error",
    //       }
    //       return err.response
    //     } else if (string1.includes(string5)) {
    //       err.response.detailsError = {
    //         msg: "Có nhân viên đã tồn tại ở chính sách khác, vui lòng kiểm tra lại",
    //         key: Math.random(),
    //         type: "error",
    //       }
    //       return err.response
    //     } else if (string1.includes(stringErrorTokenRefresh)) {
    //       localStorage.clear()
    //       window.location.href = "/login"
    //     } else {
    //       let messageError = ""
    //       if (err.response?.data?.type_message === "string") {
    //         let tempError = arrErrors
    //           .filter(
    //             x =>
    //               JSON.stringify(x.id) ===
    //               JSON.stringify(err.response?.data?.message)
    //           )
    //           .map(x => x.labels)
    //         messageError = !_.isEmpty(tempError)
    //           ? tempError[0]
    //           : JSON.stringify(err.response?.data?.message)
    //       } else {
    //         messageError = JSON.stringify(err.response?.data?.message)
    //       }
    //       err.response.detailsError = {
    //         msg: messageError,
    //         key: Math.random(),
    //         type: "error",
    //       }
    //     }
    //   } else {
    //     err.response.detailsError = {
    //       msg: `${
    //         err.response?.data?.type_message === "string"
    //           ? arrErrors
    //               .filter(
    //                 x =>
    //                   JSON.stringify(x.id) ===
    //                   JSON.stringify(err.response?.data?.message)
    //               )
    //               .map(x => x.labels)
    //           : JSON.stringify(err.response?.data?.message)
    //       } `,
    //       key: Math.random(),
    //       type: "error",
    //     }
    //   }
    //   return err.response
    // }
    if (err.response?.status === 403) {
      err.response.detailsError = {
        msg: `Tài khoản của bạn không có quyền thực hiện thao tác này`,
        key: Math.random(),
        type: "error",
      }
      return err.response
    }
    if (err.response?.status === 404) {
      err.response.detailsError = {
        msg: "Kết nối đến Server thất bại",
        key: Math.random(),
        type: "error",
      }
      return err.response
    }
    if (err.response?.status === 502) {
      err.response.detailsError = {
        msg: "Bad Gateway 502",
        key: Math.random(),
        type: "error",
      }
      return err.response
    }
    if (_.isEmpty(oldReFreshToken)) {
      err.response.detailsError = {
        msg: "Hết session truy cập, vui lòng reload lại page",
        key: Math.random(),
        type: "error",
      }
      return err.response
    } else {
      err.response.detailsError = {
        msg: `Lỗi không rõ nguyên nhân: \n ${JSON.stringify(
          err.response?.data?.message
        )}`,
        key: Math.random(),
        type: "error",
      }
      return err.response
    }
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function getSystemLog(url, config = {}) {
  return await axios.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
