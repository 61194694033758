import React, { useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Switch, Input as InputAnt } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalDetail,
    changeUserPassword
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';

const ChangePassword = (props) => {
    const { modalDetail, onCloseModalDetail, onChangePassword, dataRow } = props;
    const [fields, setFields] = useState({});
    const [myError, setMyErrors] = useState({});

    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleCancel = () => {
        onCloseModalDetail();
    };

    const onSubmit = data => {
        if (validateInput()) {
            let merge = { ...fields, user_id: dataRow.key }
            // console.log(merge);
            onChangePassword(merge);
        }

    };

    const validateInput = () => {
        let isValid = true;
        let errs = myError;
        if (!fields.password) {
            errs.password = {
                type: 'required',
                message: 'Mật khẩu không được phép để trống'
            }
            isValid = false;
        }
        else {
            if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(fields.password)) {
                errs.password = {
                    type: 'pattern',
                    message: 'Mật khẩu phải có ít nhất 8 ký tự, ít nhất 1 ký tự thường, 1 ký tự hoa, 1 số và 1 ký tự đặc biệt'
                }
                isValid = false;
            }
        }
        if (!fields.replace_password) {
            errs.replace_password = {
                type: 'required',
                message: 'Mật khẩu xác nhận không được phép để trống'
            }
            isValid = false;
        }
        else if (fields.replace_password && fields.password) {
            if (fields.replace_password !== fields.password) {
                errs.replace_password = {
                    type: 'same',
                    message: 'Mật khẩu xác nhận không khớp'
                }
                isValid = false;
            }
        }
        setMyErrors(errs);
        return isValid;
    }

    const onChange = (e) => {
        delete myError[e.target.name];
        setMyErrors(myError);
        fields[e.target.name] = e.target.value;
        setFields(fields);
    };

    return (
        <>
            <Modal isOpen={modalDetail.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalDetail.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <Label>Mật khẩu mới <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <InputAnt.Password name="password" onChange={onChange} placeholder="Mật khẩu mới" />

                                        {myError.password && myError.password.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.password.message}</span>
                                        )}
                                        {myError.password && myError.password.type === "pattern" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.password.message}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Nhập lại mật khẩu<span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <InputAnt.Password name="replace_password" onChange={onChange} placeholder="Nhập lại mật khẩu" />
                                    {myError.replace_password && myError.replace_password.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.replace_password.message}</span>
                                    )}
                                    {myError.replace_password && myError.replace_password.type === "same" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.replace_password.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thay đổi
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
ChangePassword.propTypes = {
    t: PropTypes.any,
    modalDetail: PropTypes.object,
    onCloseModalDetail: PropTypes.func,
    onChangePassword: PropTypes.func,
    dataRow: PropTypes.object
}

const mapStateToProps = ({ user, helper }) => ({
    modalDetail: helper.modalDetail
})

const mapDispatchToProps = dispatch => ({
    onCloseModalDetail: () => dispatch(closeModalDetail()),
    onChangePassword: (user) => dispatch(changeUserPassword(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);