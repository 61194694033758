import {
  GET_DEVICE_TYPES_SUCCESS,
  GET_DEVICE_TYPES_FAIL,
  ADD_DEVICE_TYPE_SUCCESS,
  ADD_DEVICE_TYPE_FAIL,
  UPDATE_DEVICE_TYPE_SUCCESS,
  UPDATE_DEVICE_TYPE_FAIL,
  DELETE_DEVICE_TYPE_SUCCESS,
  DELETE_DEVICE_TYPE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  deviceTypes: [],
  error: {},
}

const deviceType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICE_TYPES_SUCCESS:
      return {
        ...state,
        deviceTypes: action.payload,
      }

    case GET_DEVICE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DEVICE_TYPE_SUCCESS:
      return {
        ...state,
        deviceTypes: [...state.deviceTypes, action.payload],
      }

    case ADD_DEVICE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_DEVICE_TYPE_SUCCESS:
      return {
        ...state,
        deviceTypes: state.deviceTypes.map(deviceType =>
          deviceType.id.toString() === action.payload.id.toString()
            ? { deviceType, ...action.payload }
            : deviceType
        ),
      }

    case UPDATE_DEVICE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DEVICE_TYPE_SUCCESS:
      return {
        ...state,
        deviceTypes: state.deviceTypes.filter(
          deviceType => deviceType.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_DEVICE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default deviceType
