import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalEdit,
    updateServiceType
} from "../../store/actions"

import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';

const Edit = (props) => {
    const { modalEdit, onCloseModalEdit, onEditServiceType, dataRow } = props;

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    useEffect(() => {
        reset({
            name: dataRow.name,
            description: dataRow.description,
        });
    }, [reset, dataRow])

    const handleCancel = () => {
        onCloseModalEdit();
    };

    const onSubmit = data => {
        data["id"] = dataRow.key
        let message = {
            'action': 'update',
            'message': data,
            "type_message": "map"
        }
        onEditServiceType(message);
    };

    return (
        <>
            <Modal isOpen={modalEdit.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalEdit.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Tên loại dịch vụ <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            aria-invalid={errors.name ? "true" : "false"}
                                            {...register('name', {
                                                required: true,
                                            })}
                                            className="form-control"
                                            placeholder='Tên loại dịch vụ...'
                                        />
                                        {errors.name && errors.name.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên tài khoản không được để trống</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Mô tả</label>
                                </Col>
                                <Col md={8}>
                                    <textarea
                                        aria-invalid={errors.description ? "true" : "false"}
                                        {...register('description')}
                                        className="form-control"
                                        placeholder='Mô tả...'
                                    ></textarea>
                                </Col>
                            </Row>
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" className='text-white' style={{ backgroundColor: "#6ECCAF" }}>
                            Cập nhật
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Edit.propTypes = {
    t: PropTypes.any,
    modalEdit: PropTypes.object,
    onCloseModalEdit: PropTypes.func,
    onEditServiceType: PropTypes.func,
}

const mapStateToProps = ({ helper }) => ({
    modalEdit: helper.modalEdit
})

const mapDispatchToProps = dispatch => ({
    onCloseModalEdit: () => dispatch(closeModalEdit()),
    onEditServiceType: (message) => dispatch(updateServiceType(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit);