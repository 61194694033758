import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalDetail,
} from "../../store/actions"

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table
} from "reactstrap"
import { useForm } from 'react-hook-form';

const ShowPackage = (props) => {
    const { modalDetail, onCloseModalDetail, onEditUser, packages } = props;
    const handleCancel = () => {
        onCloseModalDetail();
    };


    return (
        <>
            <Modal isOpen={modalDetail.isOpenModal} toggle={handleCancel} fullscreen={"md"} size={"md"}>
                <ModalHeader toggle={handleCancel}>{modalDetail.title}</ModalHeader>
                <ModalBody>
                    <Table bordered hover>
                        <tbody>
                            {packages.length > 0 && packages.map((pack, i) =>
                                <tr key={i}>
                                    <td>{pack.package_name}</td>
                                </tr>
                            )}


                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <ButtonAnt onClick={handleCancel}>
                        Đóng
                    </ButtonAnt>
                </ModalFooter>
            </Modal>
        </>
    );
};
ShowPackage.propTypes = {
    t: PropTypes.any,
    modalDetail: PropTypes.object,
    packages: PropTypes.array,
}

const mapStateToProps = ({ user, helper }) => ({
    modalDetail: helper.modalDetail
})

const mapDispatchToProps = dispatch => ({
    onCloseModalDetail: () => dispatch(closeModalDetail()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowPackage);