//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

export const POST_LOGIN = "/login"
export const GET_MY_ROLE = "/my_role"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

// CUSTOMERS
export const GET_CUSTOMERS = "/customer"
// CONTACT
export const GET_CONTACTS = "/customer_contact"
export const GET_CUSTOMER_CONTACT = "/customer/contact"
export const GET_CUSTOMER_SOFT_SERVICE = "/customer/software_service"
export const GET_CUSTOMER_HARD_SERVICE = "/customer/hardware_service"
// USERS
export const GET_USERS = "/user"
export const CHANGE_USER_PASSWORD = "/set_password"

// User role
export const GET_USER_ASSIGN = "/user_assign"
export const GET_USER_ASSIGN_ROLE = "/user_assign_role"
export const GET_USER_ASSIGN_FORM = "/user_role_form"

// ROLES
export const GET_ROLES = "/user_role"
export const GET_ROLE_TYPE = "/role_type"

// SERVICE TYPES
export const GET_SERVICE_TYPES = "/servicetype"

// SERVICE TYPES
export const GET_PACKAGE_GROUPS = "/packagegroup"
// SERVICES
export const GET_SERVICES = "/service"
// SERVICE CUSTOMER
export const GET_SERVICE_CUSTOMER = "/servicecustomer"

export const GET_PRODUCTCOMPONENTS = "/product_component"

export const GET_SOFTWARESERVICES = "/software_service"
export const GET_DEPLOY_SOFTWARESERVICES = "/deploy/software_service"
export const GET_RESET_PASSWORD_SOFTWARESERVICES = "setpwd/software_service"

export const GET_SOFTWAREPACKAGES = "/software_package"

export const GET_DEVICE_TYPES = "/hardware_type"

export const GET_DEVICE_PACKAGES = "/hardware_package"

export const GET_DEVICE_SERVICES = "/hardware_service"

export const GET_SYSTEM_LOGS = "https://api-coredev.px1.vn/api/v1/system_logs?order=asc"


