import React, { useEffect, useState } from 'react';
import { Table, Modal, Tooltip } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
import {
    deleteDeviceService
} from "../../store/actions"
import Permission from 'permissions/Permission';

// import { deviceServices } from "../../common/data/deviceServices"

const DeviceServiceTable = (props) => {

    const columns = [
        {
            title: 'Khách hàng',
            width: 200,
            dataIndex: 'customer_name',
            key: 'customer_name',
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: 'Gói dịch vụ',
            width: 150,
            dataIndex: 'service_pack_name',
            key: 'service_pack_name',
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: 'Số lượng',
            width: 200,
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Thanh toán 1 lần',
            width: 200,
            dataIndex: 'one_time_payment',
            key: 'one_time_payment',
        },
        {
            title: 'Chu kỳ thanh toán',
            width: 200,
            dataIndex: 'billing_period',
            key: 'billing_period',
        },
        {
            title: 'Thiết bị cho thuê',
            width: 200,
            dataIndex: 'rental_equipment',
            key: 'rental_equipment',
        },
        {
            title: 'Ngày bắt đầu sử dụng',
            width: 200,
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: 'Ngày bắt đầu tính cước',
            width: 200,
            dataIndex: 'billing_date',
            key: 'billing_date',
        },
        {
            title: 'Ngày kết thúc',
            width: 200,
            dataIndex: 'terminate_date',
            key: 'terminate_date',
        },
        {
            title: 'Trạng thái',
            width: 200,
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Thao tác',
            key: 'operation',
            width: 150,
            fixed: 'right',
            render: (text, record, index) => <>
                <Permission myRole={myRole} permission={{ label: 'hardware service', action: "update" }}>
                    <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                </Permission>
                <Permission myRole={myRole} permission={{ label: 'hardware service', action: "delete" }}>
                    <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
                </Permission>
            </>,
        },
    ]

    const { deviceServices, onOpenModalEdit, onDeleteDeviceService, keyword, myRole } = props;
    const [dataRow, setDataRow] = useState({});
    const [deviceServiceList, setDeviceServices] = useState([]);

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật dịch vụ thiết bị"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        if (keyword) {
            const filteredData = deviceServiceList.filter(entry =>
                (entry?.customer_name).toLowerCase().includes(keyword.toLowerCase())
            );
            setDeviceServices(filteredData);
        } else {
            let data = [];
            deviceServices?.map(deviceService => {
                data.push({
                    key: deviceService.id,
                    id: deviceService.id,
                    billing_period: `${deviceService.billing_cycle} ${deviceService.billing_cycle_type}`,
                    billing_cycle: deviceService.billing_cycle,
                    billing_cycle_type: deviceService.billing_cycle_type,
                    rental_equipment: deviceService.rental_equipment,
                    customer_name: deviceService.customer.company_name,
                    customer: deviceService.customer.id,
                    one_time_payment: deviceService.one_time_payment,
                    quantity: deviceService.quantity,
                    service_pack_name: deviceService.service_pack.name,
                    service_pack: deviceService.service_pack.id,
                    start_date: deviceService.start_date,
                    billing_date: deviceService.billing_date,
                    status: deviceService.status,
                    terminate_date: deviceService.terminate_date
                })
            });
            setDeviceServices(data)
        }

    }, [deviceServices, keyword])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá dịch vụ thiết bị "${row.service_pack_name}" ra khỏi khách hàng "${row.customer_name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                let data = { id: row.id };
                let message = {
                    'action': 'delete',
                    'message': data,
                    "type_message": "map"
                }
                // console.log(message);
                onDeleteDeviceService(message);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <Edit dataRow={dataRow}></Edit>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                columns={columns}
                size={'small'}
                dataSource={deviceServiceList}
                scroll={{
                    x: 1500,
                }}
            />
        </>

    );

};

DeviceServiceTable.propTypes = {
    t: PropTypes.any,
    deviceServices: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onOpenModalEdit: PropTypes.func,
    onDeleteDeviceService: PropTypes.func,
    keyword: PropTypes.string,
    myRole: PropTypes.array
}

const mapStateToProps = ({ deviceService, Login }) => ({
    deviceServices: deviceService.deviceServices,
    myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onDeleteDeviceService: (message) => dispatch(deleteDeviceService(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceServiceTable);