const services = [
    {
        id: 1,
        name: 'Standard',
        package_group_ids: [
            {
                id: 1,
                name: 'dashboard',
                packages: [
                    {
                        id: 1,
                        package_name: "Quản trị Dashboard-báo cáo"
                    },
                    {
                        id: 2,
                        package_name: "Sơ đồ tổ chức"
                    }
                ]
            },
            {
                id: 1,
                name: 'Quản lý nhân sự',
                packages: [
                    {
                        id: 1,
                        package_name: "Hồ sơ nhân sự"
                    },
                    {
                        id: 2,
                        package_name: "Quản lý cấp dưới"
                    }
                ]
            }
        ],
        training_online: 2,
        training_onsite: 3,
        initial_fees: 20.000,
        number_of_users: 50,
        storage_per_user: 200,
        service_type: {
            id: '1',
            name: 'Dịch vụ HRMS'
        }
    },
    {
        id: 2,
        name: 'Professional',
        package_group_ids: [
            {
                id: 1,
                name: 'dashboard',
                packages: [
                    {
                        id: 1,
                        package_name: "Quản trị Dashboard-báo cáo"
                    },
                    {
                        id: 2,
                        package_name: "Sơ đồ tổ chức"
                    }
                ]
            },
            {
                id: 1,
                name: 'Quản lý nhân sự',
                packages: [
                    {
                        id: 1,
                        package_name: "Hồ sơ nhân sự"
                    },
                    {
                        id: 2,
                        package_name: "Quản lý cấp dưới"
                    }
                ]
            }
        ],
        training_online: 2,
        training_onsite: 3,
        initial_fees: 20.000,
        number_of_users: 50,
        storage_per_user: 200,
        service_type: {
            id: '1',
            name: 'Dịch vụ HRMS'
        }
    },
    {
        id: 3,
        name: 'Plus',
        package_group_ids: [
            {
                id: 1,
                name: 'dashboard',
                packages: [
                    {
                        id: 1,
                        package_name: "Quản trị Dashboard-báo cáo"
                    },
                    {
                        id: 2,
                        package_name: "Sơ đồ tổ chức"
                    }
                ]
            },
            {
                id: 1,
                name: 'Quản lý nhân sự',
                packages: [
                    {
                        id: 1,
                        package_name: "Hồ sơ nhân sự"
                    },
                    {
                        id: 2,
                        package_name: "Quản lý cấp dưới"
                    }
                ]
            }
        ],
        training_online: 2,
        training_onsite: 3,
        initial_fees: 20.000,
        number_of_users: 50,
        storage_per_user: 200,
        service_type: {
            id: '1',
            name: 'Dịch vụ HRMS'
        }
    },
];

export { services }