import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
import {
    deleteServiceCustomer
} from "../../store/actions"

import { serviceCustomers as serviceCustTemp } from "../../common/data/serviceCustomers"

const ServiceCustomerTable = (props) => {

    const columns = [
        {
            title: 'Khách hàng',
            width: 100,
            dataIndex: 'customer_name',
            key: 'customer_name',
            fixed: 'left',
        },
        {
            title: 'Gói dịch vụ',
            width: 100,
            dataIndex: 'service',
            key: 'service',
        },
        {
            title: 'Ngày dùng thử',
            width: 100,
            dataIndex: 'try_date',
            key: 'try_date',
        },
        {
            title: 'Ngày ký',
            width: 100,
            dataIndex: 'sign_date',
            key: 'sign_date',
        },
        {
            title: 'Ngày huỷ',
            width: 100,
            dataIndex: 'terminate_date',
            key: 'terminate_date',
        },
        {
            title: 'Trạng thái',
            width: 100,
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Mô tả',
            width: 100,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 60,
            render: (text, record, index) => <>
                <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
            </>,
        },
    ]

    const { serviceCustomers, onOpenModalEdit, onDeleteServiceCustomer } = props;
    const [dataRow, setDataRow] = useState({});
    const [serviceCustomerList, setServiceCustomers] = useState([]);

    const handleEdit = (row) => {
        // let modal = {
        //     isOpenModal: true,
        //     title: "Cập nhật dịch vụ khách hàng"
        // }
        // setDataRow(row)
        // onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        let data = [];
        serviceCustTemp?.map(serviceCustomer => {
            data.push({
                key: serviceCustomer.id,
                id: serviceCustomer.id,
                customer_name: serviceCustomer.customer_name,
                service: serviceCustomer.service,
                try_date: serviceCustomer.try_date,
                sign_date: serviceCustomer.sign_date,
                termindate_date: serviceCustomer.termindate_date,
                status: serviceCustomer.status,
                description: serviceCustomer.description,
            })
        });
        setServiceCustomers(data)
    }, [serviceCustTemp])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá dịch vụ của khách hàng "${row.customer_name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                let data = { ...row };
                delete data["key"]
                let message = {
                    'action': 'delete',
                    'message': data,
                    "type_message": "map"
                }
                // console.log(message);
                onDeleteServiceCustomer(message);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <Edit dataRow={dataRow}></Edit>
            <Table
                columns={columns}
                dataSource={serviceCustomerList}
                scroll={{
                    x: 1500,
                }}
            />
        </>

    );

};

ServiceCustomerTable.propTypes = {
    t: PropTypes.any,
    serviceCustomers: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onOpenModalEdit: PropTypes.func,
    onDeleteServiceCustomer: PropTypes.func,
}

const mapStateToProps = ({ serviceCustomer }) => ({
    serviceCustomers: serviceCustomer.serviceCustomers,
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onDeleteServiceCustomer: (message) => dispatch(deleteServiceCustomer(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCustomerTable);