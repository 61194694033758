import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getServiceCustomers = () => get(url.GET_SERVICE_CUSTOMER)
const addNewServiceCustomer = (data) => post(url.GET_SERVICE_CUSTOMER, data)

export {
    getServiceCustomers,
    addNewServiceCustomer
}
