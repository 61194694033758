import {
  GET_USER_ROLE_SUCCESS,
  GET_USER_ROLE_FAIL,
  GET_USER_ASSIGN_FORM_SUCCESS,
  GET_USER_ASSIGN_FORM_FAIL,

  ADD_USER_ASSIGN_SUCCESS,
  ADD_USER_ASSIGN_FAIL,

  DELETE_USER_ASSIGN_SUCCESS,
  DELETE_USER_ASSIGN_FAIL,


  // OLD
  ADD_USER_ROLE_SUCCESS,
  ADD_USER_ROLE_FAIL,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAIL,
  DELETE_USER_ROLE_SUCCESS,
  DELETE_USER_ROLE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users_role: [],
  users_unassign: [],
  user_role: {},
  error: {},
  message: {}
}

const UserRole = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        users_role: action.payload,
      }

    case GET_USER_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_ASSIGN_FORM_SUCCESS:
      return {
        ...state,
        users_unassign: action.payload,
      }

    case GET_USER_ASSIGN_FORM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_USER_ASSIGN_SUCCESS:
      return {
        ...state,
        message: action.payload
      }

    case ADD_USER_ASSIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case DELETE_USER_ASSIGN_SUCCESS:
      return {
        ...state,
        message: action.payload
      }

    case DELETE_USER_ASSIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    // OLD
    case ADD_USER_ROLE_SUCCESS:
      return {
        ...state,
        user_role: [...state.user_role, action.payload],
      }

    case ADD_USER_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        user_role: state.user_role.map(user_role =>
          user_role.id.toString() === action.payload.id.toString()
            ? { user_role, ...action.payload }
            : user_role
        ),
      }

    case UPDATE_USER_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_ROLE_SUCCESS:
      return {
        ...state,
        user_role: state.user_role.filter(
          user_role => user_role.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_USER_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default UserRole
