import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_DEVICE_SERVICE,
  DELETE_DEVICE_SERVICE,
  GET_DEVICE_SERVICES,
  UPDATE_DEVICE_SERVICE,
} from "./actionTypes"
import {
  getDeviceServicesSuccess,
  getDeviceServicesFail,
  addDeviceServiceFail,
  addDeviceServiceSuccess,
  updateDeviceServiceSuccess,
  updateDeviceServiceFail,
  deleteDeviceServiceSuccess,
  deleteDeviceServiceFail,
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getDeviceServices,
  addNewDeviceService,
  updateDeviceService,
  deleteDeviceService
} from "../../helpers/backend_api/deviceServices"

function* fetchDeviceServices() {
  yield put(getDeviceServicesFail({}))

  try {
    const response = yield call(getDeviceServices)
    if (response.code === 200) {
      yield put(getDeviceServicesSuccess(response.message))
    }
    else {
      yield put(getDeviceServicesFail(error))
    }
  } catch (error) {
    yield put(getDeviceServicesFail(error))
  }
}

function* onAddNewDeviceService({ payload: deviceService }) {
  try {
    const response = yield call(addNewDeviceService, deviceService);
    if (response.code === 201) {
      yield call(fetchDeviceServices)
      yield put(closeModalAdd())
    } else {
      yield put(addDeviceServiceFail(response))
    }
  } catch (error) {
    yield put(addDeviceServiceFail(error))
  }
}

function* onUpdateDeviceService({ payload: deviceService }) {
  try {
    const response = yield call(updateDeviceService, deviceService);
    if (response.code === 200) {
      yield call(fetchDeviceServices)
      yield put(closeModalEdit())
    } else {
      yield put(updateDeviceServiceFail(response))
    }
  } catch (error) {
    yield put(updateDeviceServiceFail(error))
  }
}

function* onDeleteDeviceService({ payload: deviceService }) {
  try {
    const response = yield call(deleteDeviceService, deviceService);
    if (response.code === 200) {
      yield call(fetchDeviceServices)
    } else {
      yield put(deleteDeviceServiceFail(response))
    }
  } catch (error) {
    yield put(deleteDeviceServiceFail(error))
  }
}

function* deviceServiceSaga() {
  yield takeEvery(GET_DEVICE_SERVICES, fetchDeviceServices)
  yield takeEvery(ADD_NEW_DEVICE_SERVICE, onAddNewDeviceService)
  yield takeEvery(UPDATE_DEVICE_SERVICE, onUpdateDeviceService)
  yield takeEvery(DELETE_DEVICE_SERVICE, onDeleteDeviceService)
}

export default deviceServiceSaga
