import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardHeader,
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"

//i18n
import { withTranslation } from "react-i18next"
import SoftwarePackageTable from "./SoftwarePackageTable"
import { Input as InputAnt, Button as ButtonAnt } from "antd"

import { getSoftwarePackages, getProductComponents } from "../../store/actions"
import { connect } from "react-redux"
import { get } from "lodash"
import {
  CHANGE_OPEN_MODAL_ADD,
  OPEN_MODAL_DETAIL,
} from "store/helper/actionTypes"
import Add from "./Add"
import Permission from "permissions/Permission"

const SoftwarePackages = props => {
  const [keyword, setKeyword] = useState('');

  const {
    onGetSoftwarePackages,
    onOpenModalAdd,
    onGetProductComponents,
    productcomponents,
    myRole
  } = props

  useEffect(() => {
    onGetSoftwarePackages()
    onGetProductComponents()
  }, [onGetSoftwarePackages, onGetProductComponents])

  const handleAdd = () => {
    let modal = {
      isOpenModal: true,
      title: "Thêm mới gói dịch vụ phần mềm",
    }
    onOpenModalAdd(modal)
  }

  const onSearch = (e) => {
    setKeyword(e.target.value);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SoftwarePackages</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={12}>
                <h6 className="page-title">Phần mềm</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">Gói dịch vụ</li>
                  <li className="breadcrumb-item active">Phần mềm</li>
                </ol>
              </Col>
            </Row>
          </div>
          {/* Table here */}
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="h4">
                        Danh sách gói dịch vụ phần mềm
                      </CardTitle>
                      <p className="card-title-desc">
                        Quản lý danh sách gói dịch vụ của phần mềm
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <div className="mb-2">
                        <Permission myRole={myRole} permission={{ label: 'software service pack', action: "create" }}>
                          <ButtonAnt
                            type="primary"
                            className="me-1"
                            onClick={() => handleAdd()}
                          >
                            + Thêm mới
                          </ButtonAnt>
                          <Add></Add>
                        </Permission>
                        {/* <ButtonAnt className="me-1">
                          <i className="fas fa-filter text-primary"></i>
                        </ButtonAnt> */}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="float-end mb-2">
                        <InputAnt
                          placeholder="search..."
                          onChange={onSearch}
                          style={{
                            width: 200,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <SoftwarePackageTable keyword={keyword}></SoftwarePackageTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SoftwarePackages.propTypes = {
  t: PropTypes.any,
  softwarepackages: PropTypes.array,
  onGetSoftwarePackages: PropTypes.func,
  onOpenModalAdd: PropTypes.func,
  onGetProductComponents: PropTypes.func,
  productcomponents: PropTypes.array,
  myRole: PropTypes.array
}

const mapStateToProps = ({ softwarepackage, productcomponent, Login }) => ({
  softwarepackages: softwarepackage.softwarepackages,
  productcomponents: productcomponent.productcomponents,
  myRole: Login.myRole
})

const mapDispatchToProps = dispatch => ({
  onGetSoftwarePackages: () => dispatch(getSoftwarePackages()),
  onGetProductComponents: () => dispatch(getProductComponents()),
  onOpenModalAdd: modal =>
    dispatch({ type: CHANGE_OPEN_MODAL_ADD, payload: modal }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SoftwarePackages))
