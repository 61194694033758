import {
  GET_PACKAGE_GROUPS_SUCCESS,
  GET_PACKAGE_GROUPS_FAIL,
  ADD_PACKAGE_GROUP_SUCCESS,
  ADD_PACKAGE_GROUP_FAIL,
  UPDATE_PACKAGE_GROUP_SUCCESS,
  UPDATE_PACKAGE_GROUP_FAIL,
  DELETE_PACKAGE_GROUP_SUCCESS,
  DELETE_PACKAGE_GROUP_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  packageGroups: [],
  error: {},
}

const packageGroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PACKAGE_GROUPS_SUCCESS:
      return {
        ...state,
        packageGroups: action.payload,
      }

    case GET_PACKAGE_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PACKAGE_GROUP_SUCCESS:
      return {
        ...state,
        packageGroups: [...state.packageGroups, action.payload],
      }

    case ADD_PACKAGE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PACKAGE_GROUP_SUCCESS:
      return {
        ...state,
        packageGroups: state.packageGroups.map(packageGroups =>
          packageGroups.id.toString() === action.payload.id.toString()
            ? { packageGroups, ...action.payload }
            : packageGroups
        ),
      }

    case UPDATE_PACKAGE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PACKAGE_GROUP_SUCCESS:
      return {
        ...state,
        packageGroups: state.packageGroups.filter(
          packageGroups => packageGroups.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PACKAGE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default packageGroups
