import React, { useState, useEffect } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Switch } from 'antd';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalEdit,
    updateRole
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';
import RoleType from "./RoleType";
const Edit = (props) => {
    const { modalEdit, onCloseModalEdit, onUpdateRole, permissions, dataRow } = props;
    const [fields, setFields] = useState({});
    const [switchs, setSwitchs] = useState({});
    const [permissionChecked, setPermissionChecked] = useState([]);
    const [permissionAssign, setPermissionAssign] = useState([]);
    const [myError, setMyErrors] = useState({});

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    useEffect(() => {
        reset({
            name: dataRow.name,
            description: dataRow.description,
        });
        setPermissionAssign(dataRow.roles)
        if (!_.isEmpty(dataRow.roles)) {
            let result = dataRow.roles.map(perm => perm.id);
            setPermissionChecked(result);
        }
        setSwitchs({ is_assignable: dataRow.is_assignable === "Yes" ? true : false });
    }, [reset, dataRow])

    const handleCancel = () => {
        onCloseModalEdit();
    };

    const onSubmit = data => {
        let merge = { id: dataRow.key, ...data, ...{ roles: permissionChecked, is_predefined: "Yes", is_assignable: switchs.is_assignable === true ? "Yes" : "No" } }
        let message = {
            'action': 'update',
            'message': merge,
            "type_message": "map"
        }
        // console.log(message)
        onUpdateRole(message);
    };

    const validateInput = () => {
        let isValid = true;
        let errs = myError;
        if (!fields.default_language) {
            errs.default_language = {
                type: 'required',
                message: 'Ngôn ngữ không được phép để trống'
            }
            isValid = false;
        }
        setMyErrors(errs);
        return isValid;
    }

    // const onChangeInput = (event) => {
    //     let myFields = fields;
    //     let field = event.target.name;
    //     myFields[field] = event.target.value;
    //     setFields(myFields);
    // }

    const onChangeSwitch = name => (value) => {
        let mySwitchs = switchs;
        let field = name;
        mySwitchs[field] = value;
        setSwitchs(mySwitchs);
    }

    const callBackPermission = (permissions) => {
        let result = permissions.map(perm => perm.id);
        setPermissionChecked(result);
    }

    return (
        <>
            <Modal isOpen={modalEdit.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalEdit.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <div className="row mb-3">
                                <label htmlFor="input-name" className="col-sm-3 col-form-label">
                                    <span className="text-danger">*</span>
                                    Tên vai trò
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        aria-invalid={errors.name ? "true" : "false"}
                                        {...register('name', {
                                            required: true,
                                        })}
                                        defaultValue={dataRow.name}
                                        className="form-control"
                                        placeholder='Tên vai trò...'
                                    />
                                    {errors.name && errors.name.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên vai trò không được để trống</span>
                                    )}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label
                                    htmlFor="input-description"
                                    className="col-sm-3 col-form-label"
                                >
                                    <span className="text-danger">*</span>
                                    Mô tả chi tiết
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        aria-invalid={errors.description ? "true" : "false"}
                                        {...register('description', {
                                            required: true,
                                        })}
                                        defaultValue={dataRow.description}
                                        className="form-control"
                                        placeholder='Mô tả vai trò...'
                                    />
                                    {errors.description && errors.description.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Mô tả không được để trống</span>
                                    )}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label htmlFor="input-name" className="col-sm-3 col-form-label">
                                    Được chỉ định
                                </label>
                                <div className="col-sm-9">
                                    <Switch defaultChecked={switchs.is_assignable} onChange={onChangeSwitch('is_assignable')} />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label htmlFor="ck-permission" className="col-sm-3 col-form-label">
                                    Quyền
                                </label>
                                <div className="col-sm-9">
                                    <RoleType permissionAssign={permissionAssign} callBackPermission={callBackPermission}></RoleType>
                                </div>
                            </div>
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" className='text-white' style={{ backgroundColor: "#6ECCAF" }}>
                            Cập nhật
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Edit.propTypes = {
    t: PropTypes.any,
    modalEdit: PropTypes.object,
    onCloseModalEdit: PropTypes.func,
    permissions: PropTypes.array,
    dataRow: PropTypes.object
}

const mapStateToProps = ({ role, helper }) => ({
    modalEdit: helper.modalEdit,
    permissions: role.constraints
})

const mapDispatchToProps = dispatch => ({
    onCloseModalEdit: () => dispatch(closeModalEdit()),
    onUpdateRole: (message) => dispatch(updateRole(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit);