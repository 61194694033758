import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getProductComponents = () => get(url.GET_PRODUCTCOMPONENTS)
const addNewProductComponent = (data) => post(url.GET_PRODUCTCOMPONENTS, data)
const updateProductComponent = (data) => post(url.GET_PRODUCTCOMPONENTS, data)
const deleteProductComponent = (data) => post(url.GET_PRODUCTCOMPONENTS, data)

export {
    getProductComponents,
    addNewProductComponent,
	updateProductComponent,
	deleteProductComponent
}
