import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT, CHANGE_OPEN_MODAL_DETAIL } from 'store/helper/actionTypes';
import { packageGroups } from "../../common/data/packageGroups"
import ShowPackage from './ShowPackage';

const PackageGroupTable = (props) => {

    const columns = [
        {
            title: 'Tên nhóm dịch vụ',
            width: 30,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Chi tiết nhóm',
            width: 100,
            dataIndex: ['packages'],
            key: 'packages',
            render: (record) => <>
                <button type="button" onClick={() => handleShowPackage(record)} className="btn btn-outline-primary btn-sm waves-effect waves-light me-1"><i className="fas fa-cube"></i></button>
            </>,
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 20,
            render: (text, record, index) => <>
                <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
            </>,
        },
    ]

    const { customers, onOpenModalEdit, onOpenModalDetail } = props;
    const [dataRow, setDataRow] = useState({});
    const [packageGroupList, setPackageGroups] = useState([]);
    const [packages, setPackages] = useState([]);

    const handleShowPackage = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Chi tiết gói dịch vụ"
        }
        // console.log(row);
        setPackages(row);
        onOpenModalDetail(modal);
    }

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật nhóm dịch vụ"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        let data = [];
        packageGroups?.map(packageGroup => {
            data.push({
                key: packageGroup.id,
                name: packageGroup.name,
                packages: packageGroup.packages,
            })
        });
        setPackageGroups(data)
    }, [packageGroups])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá nhóm dịch vụ "${row.name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                console.log(row)
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <Edit dataRow={dataRow}></Edit>
            <ShowPackage packages={packages}></ShowPackage>
            <Table
                columns={columns}
                dataSource={packageGroupList}
                scroll={{
                    x: 1500,
                }}
            />
        </>

    );

};

PackageGroupTable.propTypes = {
    t: PropTypes.any,
    customers: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onOpenModalEdit: PropTypes.func,
    onOpenModalDetail: PropTypes.func,
}

const mapStateToProps = ({ customer }) => ({
    customers: customer.customers,
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onOpenModalDetail: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_DETAIL, payload: modal }),
})

export default connect(mapStateToProps, mapDispatchToProps)(PackageGroupTable);