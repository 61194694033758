import {
  GET_USER_ROLE,
  GET_USER_ROLE_FAIL,
  GET_USER_ROLE_SUCCESS,

  GET_USER_ASSIGN_FORM,
  GET_USER_ASSIGN_FORM_FAIL,
  GET_USER_ASSIGN_FORM_SUCCESS,

  ADD_USER_ASSIGN,
  ADD_USER_ASSIGN_SUCCESS,
  ADD_USER_ASSIGN_FAIL,

  DELETE_USER_ASSIGN,
  DELETE_USER_ASSIGN_SUCCESS,
  DELETE_USER_ASSIGN_FAIL,

  // OLD
  ADD_NEW_USER_ROLE,
  ADD_USER_ROLE_SUCCESS,
  ADD_USER_ROLE_FAIL,

  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAIL,

  DELETE_USER_ROLE,
  DELETE_USER_ROLE_SUCCESS,
  DELETE_USER_ROLE_FAIL,
} from "./actionTypes"

export const getUserRole = (role_id) => ({
  type: GET_USER_ROLE,
  payload: role_id
})

export const getUserRoleSuccess = user_role => ({
  type: GET_USER_ROLE_SUCCESS,
  payload: user_role,
})

export const getUserRoleFail = error => ({
  type: GET_USER_ROLE_FAIL,
  payload: error,
})

export const getUserAssignForm = (role_id) => ({
  type: GET_USER_ASSIGN_FORM,
  payload: role_id
})

export const getUserAssignFormSuccess = user_unassign => ({
  type: GET_USER_ASSIGN_FORM_SUCCESS,
  payload: user_unassign,
})

export const getUserAssignFormFail = error => ({
  type: GET_USER_ASSIGN_FORM_FAIL,
  payload: error,
})

export const addUserAssign = user_assgin => ({
  type: ADD_USER_ASSIGN,
  payload: user_assgin,
})

export const addUserAssignSuccess = user_assgin => ({
  type: ADD_USER_ASSIGN_SUCCESS,
  payload: user_assgin,
})

export const addUserAssignFail = error => ({
  type: ADD_USER_ASSIGN_FAIL,
  payload: error,
})

export const deleteUserAssign = user_assgin => ({
  type: DELETE_USER_ASSIGN,
  payload: user_assgin,
})

export const deleteUserAssignSuccess = user_assgin => ({
  type: DELETE_USER_ASSIGN_SUCCESS,
  payload: user_assgin,
})

export const deleteUserAssignFail = error => ({
  type: DELETE_USER_ASSIGN_FAIL,
  payload: error,
})

// OLD

export const addNewUserRole = user_role => ({
  type: ADD_NEW_USER_ROLE,
  payload: user_role,
})

export const addUserRoleSuccess = user_role => ({
  type: ADD_USER_ROLE_SUCCESS,
  payload: user_role,
})

export const addUserRoleFail = error => ({
  type: ADD_USER_ROLE_FAIL,
  payload: error,
})

export const updateUserRole = user_role => ({
  type: UPDATE_USER_ROLE,
  payload: user_role,
})

export const updateUserRoleSuccess = user_role => ({
  type: UPDATE_USER_ROLE_SUCCESS,
  payload: user_role,
})

export const updateUserRoleFail = error => ({
  type: UPDATE_USER_ROLE_FAIL,
  payload: error,
})

export const deleteUserRole = user_role => ({
  type: DELETE_USER_ROLE,
  payload: user_role,
})

export const deleteUserRoleSuccess = user_role => ({
  type: DELETE_USER_ROLE_SUCCESS,
  payload: user_role,
})

export const deleteUserRoleFail = error => ({
  type: DELETE_USER_ROLE_FAIL,
  payload: error,
})